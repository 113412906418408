$(".js-productgrid-selector").each(function(index, element) {
    $(element).val($(element).find('option').first().val());
});

$(".js-productgrid-selector").on('change', (function(event) {
    $('.product-feature.mobile-only div:first-of-type').hide();
    $('.product-feature.mobile-only div').hide();
    const text = $(event.currentTarget).val()
    const selector = `div.views-row-${text}`
    $(selector).show();
}));

$(function () {
    var postUrl = encodeURI(document.location.href);
    var postTitle = $(document).attr('title');
    const facebookBtn = document.querySelector(".afbshare");
    const twitterBtn = document.querySelector(".atwtshare");
    const linkedinBtn = document.querySelector(".alishare");
    if (facebookBtn != null) facebookBtn.setAttribute("data-url", "https://www.facebook.com/sharer.php?u=" + postUrl);
    if (twitterBtn != null) twitterBtn.setAttribute("data-url", "https://twitter.com/share?url=" + postUrl + "&text=" + postTitle);
    if (linkedinBtn != null) linkedinBtn.setAttribute("data-url", "https://www.linkedin.com/shareArticle?url=" + postUrl + "&text=" + postTitle);

    var RootUrl = '/api/watts/SpecSheetGenerate/SpecSheetGenerate';
    $(".specsheetgen").click(function () {
        var pid = $(this).attr('data-pid');
        window.open(RootUrl + '?ProductGuid=' + pid, "_blank");
    });

    $(".afbshare").click(function (e) {
        e.preventDefault();
        window.open($(this).attr('data-url'), 'FacebookShare', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;        
    });

    $(".atwtshare").click(function (e) {
        e.preventDefault();
        window.open($(this).attr('data-url'), 'Twitte Share', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
    });

    $(".alishare").click(function (e) {
        e.preventDefault();
        window.open($(this).attr('data-url'), 'LinkedInShare', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
    });
});

