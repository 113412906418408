import $ from 'jquery';
/*
  This is a polyfill for non standards compliant/legacy browsers.
  I'm looking at you I.E.
  See: https://www.zdnet.com/article/microsoft-security-chief-ie-is-not-a-browser-so-stop-using-it-as-your-default/
*/
import cssVars from 'css-vars-ponyfill';
cssVars(); // Required initial call must complete to load defaults

const bodyClass = $('body').attr('class');


switch (bodyClass) {
  case 'blucher':
    cssVars({
      variables: {
        '--primary': '#132261', 
        '--primary-trans': 'rgba(19, 34, 97, 0.7)', 
        '--light-primary': '#364379', 
        '--primary-pale': '#c4c8d7', 
        '--primary-extra-pale': '#dcdee7', 
        '--medium-primary': '#111f58', 
        '--dark-primary': '#0e1949', 
        '--dark-transparent': 'rgba(6, 10, 29, 0.9)', 
        '--off-white-cool': '#fafbfc'
      }
    });
  break;

  case 'aerco':
    cssVars({
      variables: {
        '--primary': '#62AD3D', 
        '--primary-trans': 'rgba(98, 173, 61, 0.7)', 
        '--light-primary': '#7ab95a', 
        '--primary-pale': '#d8ebcf', 
        '--primary-extra-pale': '#e7f3e2', 
        '--medium-primary': '#599d38', 
        '--dark-primary': '#4a822e', 
        '--dark-transparent': 'rgba(29, 52, 18, 0.9)', 
        '--off-white-cool': '#fcfdfb'
      }
    });
  break;

  case 'dormont':
    cssVars({
      variables: {
        '--primary': '#005493', 
        '--primary-trans': 'rgba(0, 84, 147, 0.7)', 
        '--light-primary': '#266ea3', 
        '--primary-pale': '#bfd4e4', 
        '--primary-extra-pale': '#d9e5ef', 
        '--medium-primary': '#004c86', 
        '--dark-primary': '#003f6e', 
        '--dark-transparent': 'rgba(0, 25, 44, 0.9)', 
        '--off-white-cool': '#fafcfd'
      }
    });
  break;

  case 'mueller':
    cssVars({
      variables: {
        '--primary': '#005DAA', 
        '--primary-trans': 'rgba(0, 93, 170, 0.7)', 
        '--light-primary': '#2675b7', 
        '--primary-pale': '#bfd7ea', 
        '--primary-extra-pale': '#d9e7f2', 
        '--medium-primary': '#00559b', 
        '--dark-primary': '#004680', 
        '--dark-transparent': 'rgba(0, 28, 51, 0.9)', 
        '--off-white-cool': '#fafcfd'
      }
    });
  break;

  case 'pvi':
    cssVars({
      variables: {
        '--primary': '#18375C', 
        '--primary-trans': 'rgba(24, 55, 92, 0.7)', 
        '--light-primary': '#3b5574', 
        '--primary-pale': '#c5cdd6', 
        '--primary-extra-pale': '#dce1e7', 
        '--medium-primary': '#163254', 
        '--dark-primary': '#122945', 
        '--dark-transparent': 'rgba(7, 17, 28, 0.9)', 
        '--off-white-cool': '#fafbfc'
      }
    });
  break;

  case 'sunTouch':
    cssVars({
      variables: {
        '--primary': '#F78D1E', 
        '--primary-trans': 'rgba(247, 141, 30, 0.7)', 
        '--light-primary': '#f89e40', 
        '--primary-pale': '#fde3c7', 
        '--primary-extra-pale': '#feeedd', 
        '--medium-primary': '#e1801b', 
        '--dark-primary': '#b96a17', 
        '--dark-transparent': 'rgba(70, 77, 89, 0.9)', 
        '--off-white-cool': '#fffdfb'
      }
    });
  break;

  case 'watts':
    cssVars({
      variables: {
        '--primary': '#005db9', 
        '--primary-trans': 'rgba(0, 93, 185, 0.7)', 
        '--light-primary': '#2675c4', 
        '--primary-pale': '#bfd7ee', 
        '--primary-extra-pale': '#d9e7f5', 
        '--medium-primary': '#0055a8', 
        '--dark-primary': '#00468b', 
        '--dark-transparent': 'rgba(0, 28, 56, 0.9)', 
        '--off-white-cool': '#fafcfe'
      }
    });
  break;
}