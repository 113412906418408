$(document).ready(function () {

    function toggleHoverClass() {
        $(".contacts-page-city-list .contacts-page-city").on("mouseover", function (e) {
            $(this).addClass("hover");
            $(".contacts-page-city-list .contacts-page-city").not(this).addClass("unhover");
        });
        $(".contacts-page-city-list .contacts-page-city").on("mouseout", function (e) {
            $(".contacts-page-city-list .contacts-page-city").removeClass("unhover hover");
        });
    }
    toggleHoverClass();

    $(document).on('click', ".contacts-page-city-list .contacts-page-city", function (event) {
        event.preventDefault();
        event.stopPropagation(); // Stop the event from propagating

        // Save the current scroll position
        var currentScroll = $(window).scrollTop();

        $(".city-info-holder .city-info-content a").attr("tabindex", "-1");

        var cityName = $(this).data("city");
        var contentName = $(".city-info-content[data-city='" + cityName + "']");
        var middleColumn = $(".contacts-page-column-middle.city-info");


        var firstLink = contentName.find('a[href^="mailto"], a[href^="http"]');

        var lastLink = firstLink.last();
        if (firstLink.length > 0) {
            firstLink.each(function (index) {
                $(this).on('focusout', function () {
                    if ($(this).is(lastLink)) {
                        setTimeout(function () {
                            $(".city-info-holder button").attr('tabindex', '0').focus();
                        }, 200);
                    }
                });
                $(this).attr('tabindex', '0');
            });
        }

        if (!middleColumn.hasClass('show')) {
            middleColumn.addClass('show');
        }

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(".contacts-page-city-list .contacts-page-city").removeClass("disabled");
            $(".city-info-holder button").get(0).focus(); // Focus on the actual DOM element
        } else {
            $(".contacts-page-city-list .contacts-page-city").removeClass("active");
            $(this).addClass("active");
            $(".contacts-page-city-list .contacts-page-city").not(this).addClass("disabled");
            $(".city-info-holder button").get(0).focus(); // Focus on the actual DOM element
        }

        if (middleColumn.hasClass('show')) {
            middleColumn.removeClass('show');
            setTimeout(function () {
                if (contentName.attr("data-city") === cityName) {
                    $(".city-info-content").not(contentName).removeClass('show');
                    contentName.addClass('show');
                    middleColumn.addClass('show');
                }
            }, 1000);
        } else {
            middleColumn.addClass('show');
        }
        // Restore the scroll position
        $(window).scrollTop(currentScroll);
    });

    $(".city-info-holder button").on("click", function (event) {
        event.preventDefault();
        // Save the current scroll position
        var currentScroll = $(window).scrollTop();

        $(".contacts-page-city-list .contacts-page-city").removeClass('hover unhover active disabled');
        $(".contacts-page-column-middle.city-info").removeClass('show');

        /*var activeCity = $(".contacts-page-city-list .contacts-page-city.active");
        activeCity.removeClass('hover unhover active disabled');
        $(".contacts-page-column-middle.city-info").removeClass('show');

        var cities = $(".contacts-page-city-list .contacts-page-city");
        var activeIndex = cities.index(activeCity);
        var nextCityIndex = (activeIndex + 1) % cities.length;

        var nextCity = cities.eq(nextCityIndex);
        nextCity.focus();*/

        // Restore the scroll position
        $(window).scrollTop(currentScroll);
    });

});
