function getOperatingTime(input) {
        let operatingTime;
        let floor = $(input).val();
        if (floor === 'Wood') {
            operatingTime = 4
        } else {
            operatingTime = 8
        }
        return operatingTime;
    }
export{getOperatingTime}