
/*Scripts for idroset configurator starts here*/

var inputLitersPerHour = document.querySelector('#litersPerHour');
var inputInnerDpKpa = document.querySelector('#Dp_kPa');

var DN15 = {
	qmin: 35,
	qmax: 700,
	dpKpa: 0,
	kv: 0,
	secondQmin: 300,
	secondQmax: 700,
	formula1: function (litersPerHour) {
		return -0.00000000000011745 * Math.pow(litersPerHour, 6) + 0.00000000010384 * Math.pow(litersPerHour, 5) - 0.00000002975 * Math.pow(litersPerHour, 4) + 0.0000021317 * Math.pow(litersPerHour, 3) + 0.00026806 * Math.pow(litersPerHour, 2) + 0.00023192 * litersPerHour + 0.012728;
	},

	formula2: function (litersPerHour) {
		return 0.0000000000000033273 * Math.pow(litersPerHour, 6) - 0.000000000010144 * Math.pow(litersPerHour, 5) + 0.000000012703 * Math.pow(litersPerHour, 4) - 0.0000083295 * Math.pow(litersPerHour, 3) + 0.003009 * Math.pow(litersPerHour, 2) - 0.55234 * litersPerHour + 46.515;
	},

	simulationQmin: 40
};

var DN20 = {
	qmin: 35,
	qmax: 700,
	dpKpa: 0,
	secondQmin: 300,
	secondQmax: 700,
	formula1: function (litersPerHour) {
		return -0.00000000000011745 * Math.pow(litersPerHour, 6) + 0.00000000010384 * Math.pow(litersPerHour, 5) - 0.00000002975 * Math.pow(litersPerHour, 4) + 0.0000021317 * Math.pow(litersPerHour, 3) + 0.00026806 * Math.pow(litersPerHour, 2) + 0.00023192 * litersPerHour + 0.012728;
	},

	formula2: function (litersPerHour) {
		return 0.0000000000000033273 * Math.pow(litersPerHour, 6) - 0.000000000010144 * Math.pow(litersPerHour, 5) + 0.000000012703 * Math.pow(litersPerHour, 4) - 0.0000083295 * Math.pow(litersPerHour, 3) + 0.003009 * Math.pow(litersPerHour, 2) - 0.55234 * litersPerHour + 46.515;
	},
	simulationQmin: 40
};

var DN25 = {
	qmin: 50,
	qmax: 1600,
	dpKpa: 0,
	kv: 0,
	secondQmin: 700,
	secondQmax: 1600,
	formula1: function (litersPerHour) {
		return -0.00000000000000082361 * Math.pow(litersPerHour, 6) + 0.0000000000016645 * Math.pow(litersPerHour, 5) - 0.0000000010899 * Math.pow(litersPerHour, 4) + 0.00000017851 * Math.pow(litersPerHour, 3) + 0.000051308 * Math.pow(litersPerHour, 2) + 0.00010146 * litersPerHour + 0.012728;
	},

	formula2: function (litersPerHour) {
		return 0.000000000000000056723 * Math.pow(litersPerHour, 6) - 0.0000000000003833 * Math.pow(litersPerHour, 5) + 0.0000000010625 * Math.pow(litersPerHour, 4) - 0.000001543 * Math.pow(litersPerHour, 3) + 0.0012364 * Math.pow(litersPerHour, 2) - 0.51137 * litersPerHour + 91.486;
	},
	simulationQmin: 60
};

var DN32 = {
	qmin: 250,
	qmax: 6000,
	dpKpa: 0,
	kv: 0,
	secondQmin: 2500,
	secondQmax: 6000,
	formula1: function (litersPerHour) {
		return -0.00000000000000000029616 * Math.pow(litersPerHour, 6) + 0.0000000000000022445 * Math.pow(litersPerHour, 5) - 0.0000000000055116 * Math.pow(litersPerHour, 4) + 0.0000000033851 * Math.pow(litersPerHour, 3) + 0.0000036486 * Math.pow(litersPerHour, 2) + 0.000027057 * litersPerHour + 0.012728;
	},
	formula2: function (litersPerHour) {
		return 0.000000000000000000020397 * Math.pow(litersPerHour, 6) - 0.00000000000000051687 * Math.pow(litersPerHour, 5) + 0.0000000000053728 * Math.pow(litersPerHour, 4) - 0.000000029259 * Math.pow(litersPerHour, 3) + 0.000087921 * Math.pow(litersPerHour, 2) - 0.13636 * litersPerHour + 91.486;
	},
	simulationQmin: 300
};

var DN40 = {
	qmin: 250,
	qmax: 6000,
	dpKpa: 0,
	kv: 0,
	secondQmin: 2500,
	secondQmax: 6000,
	formula1: function (litersPerHour) {
		return -0.00000000000000000029616 * Math.pow(litersPerHour, 6) + 0.0000000000000022445 * Math.pow(litersPerHour, 5) - 0.0000000000055116 * Math.pow(litersPerHour, 4) + 0.0000000033851 * Math.pow(litersPerHour, 3) + 0.0000036486 * Math.pow(litersPerHour, 2) + 0.000027057 * litersPerHour + 0.012728;
	},
	formula2: function (litersPerHour) {
		return 0.000000000000000000020397 * Math.pow(litersPerHour, 6) - 0.00000000000000051687 * Math.pow(litersPerHour, 5) + 0.0000000000053728 * Math.pow(litersPerHour, 4) - 0.000000029259 * Math.pow(litersPerHour, 3) + 0.000087921 * Math.pow(litersPerHour, 2) - 0.13636 * litersPerHour + 91.486;
	},
	simulationQmin: 300
};

var DN50 = {
	qmin: 400,
	qmax: 10000,
	dpKpa: 0,
	kv: 0,
	secondQmin: 4500,
	secondQmax: 10000,
	formula1: function (litersPerHour) {
		return -0.000000000000000000013818 * Math.pow(litersPerHour, 6) + 0.00000000000000017453 * Math.pow(litersPerHour, 5) - 0.00000000000071431 * Math.pow(litersPerHour, 4) + 0.00000000073118 * Math.pow(litersPerHour, 3) + 0.0000013135 * Math.pow(litersPerHour, 2) + 0.000016234 * litersPerHour + 0.012728;
	},
	formula2: function (litersPerHour) {
		return 0.00000000000000000000095166 * Math.pow(litersPerHour, 6) - 0.000000000000000040192 * Math.pow(litersPerHour, 5) + 0.00000000000069631 * Math.pow(litersPerHour, 4) - 0.00000000632 * Math.pow(litersPerHour, 3) + 0.000031652 * Math.pow(litersPerHour, 2) - 0.081818 * litersPerHour + 91.486;
	},
	simulationQmin: 500
};

DN15.dpKpa = document.getElementById("DN15valueDp");
DN15.kv = document.getElementById("DN15valueKv");

DN20.dpKpa = document.getElementById("DN20valueDp");
DN20.kv = document.getElementById("DN20valueKv");

DN25.dpKpa = document.getElementById("DN25valueDp");
DN25.kv = document.getElementById("DN25valueKv");

DN32.dpKpa = document.getElementById("DN32valueDp");
DN32.kv = document.getElementById("DN32valueKv");

DN40.dpKpa = document.getElementById("DN40valueDp");
DN40.kv = document.getElementById("DN40valueKv");

DN50.dpKpa = document.getElementById("DN50valueDp");
DN50.kv = document.getElementById("DN50valueKv");

var idrosetModel = {
	DN15: DN15,
	DN20: DN20,
	DN25: DN25,
	DN32: DN32,
	DN40: DN40,
	DN50: DN50,
};

function setInfoDpKpa(model, info) {
	return model.dpKpa.innerHTML = info;
}

function setInfoKv(model, info) {
	return model.kv.innerHTML = info;
}

function getLowOrHight(litersPerHour, model) {
	var modelDP, modelKv, total;

	if (litersPerHour < model.qmin) {
		total = [
			modelDP = '<span style="color: red">low flow</span>',
			modelKv = ''
		];

		return total;
	} else {
		total = [
			modelDP = '<span style="color: red">high flow</span>',
			modelKv = ''
		];

		return total;
	}
}

function getKvModel(litersPerHour, modelDP) {
	var modelKv = (litersPerHour / 1000) / Math.pow((modelDP / 100), 0.5);
	return modelKv.toFixed(1);
}

function getCalculateModel(model, litersPerHour, inputInnerDpKpa) {
	if (litersPerHour >= model.qmin && litersPerHour <= model.qmax) {
		var modelDP = 0;
		var modelKv = 0;

		if (litersPerHour <= model.secondQmin) {
			modelDP = model.formula1(litersPerHour);
		} else {
			if (litersPerHour == model.secondQmax) {
				modelDP = 14;
			} else {
				modelDP = model.formula2(litersPerHour);
			}
		}

		if (modelDP < inputInnerDpKpa || inputInnerDpKpa == 0) {
			modelDP = modelDP.toFixed(1);
			modelKv = getKvModel(litersPerHour, modelDP);

			var total = [
				modelDP,
				modelKv
			];

			return total;
		} else {
			var total = [
				modelDP = '<span style="color: red">ΔP high</span>',
				modelKv = ''
			];

			return total;
		}
	} else {
		var total = getLowOrHight(litersPerHour, model);

		return total;
	}
}

(function () {
	var inputData = document.querySelectorAll('#litersPerHour, #Dp_kPa');

	[].forEach.call(inputData, function (oneData) {
		oneData.addEventListener('keyup', function (e) {
			var litersPerHour = inputLitersPerHour.value;
			var innerDpKpa = inputInnerDpKpa.value;
			var model;
			for (model in idrosetModel) {
				model = idrosetModel[model];

				getCalculateModel(model, litersPerHour, innerDpKpa);

				setInfoDpKpa(model, getCalculateModel(model, litersPerHour, innerDpKpa)[0]);
				setInfoKv(model, getCalculateModel(model, litersPerHour, innerDpKpa)[1]);
			}
		});
	});
})();
/*Scripts for idroset configurator ends here*/


/*Scripts for idroset Simulator starts here*/

var DNFromSelect = document.querySelector('#select-idroset');
var changeLitersPerHour = document.querySelector('#changeLitersPerhour');

function getSimulation(DNFromSelect, testedLiters) {
	var DNFromSelectValue = DNFromSelect.value;
	var litersForSimulation;
	var simulationModel = idrosetModel[DNFromSelectValue];

	simulationModel.dpKpa = document.getElementById("outputDP");
	simulationModel.kv = document.getElementById("outputKV");

	if (changeLitersPerhour.value == '') {
		litersForSimulation = 0;
	} else {
		litersForSimulation = parseInt(changeLitersPerhour.value);
	}

	var simTotal = 0;

	if (testedLiters) {
		litersForSimulation = simulationModel.simulationQmin;
	}

	simTotal = getCalculateModel(simulationModel, litersForSimulation, 100);

	changeLitersPerHour.value = litersForSimulation;

	if (isNaN(simTotal[0])) {
		simulationModel.dpKpa.style.color = 'red';
		simTotal[0] = simTotal[0].match(/>(.+)</);
		simTotal[0] = simTotal[0][1];
	} else {
		simulationModel.dpKpa.removeAttribute('style');
	}

	simulationModel.dpKpa.value = simTotal[0];
	simulationModel.kv.value = simTotal[1];

	return simTotal;
}

(function () {
	if (DNFromSelect) {
		DNFromSelect.addEventListener('change', function (e) {
			getSimulation(DNFromSelect, true);
		});
	}
})();

var changeLitersPlus = document.querySelector("#changeLitterPlus");
var changeLitersMinus = document.querySelector("#changeLitterMinus");
var innerLitersPerHour;


(function () {
	if (changeLitersPlus) {
		changeLitersPlus.addEventListener('click', function (e) {
			innerLitersPerHour = parseInt(changeLitersPerHour.value);
			innerLitersPerHour = innerLitersPerHour + 5;

			changeLitersPerHour.value = innerLitersPerHour;
			getSimulation(DNFromSelect, false);

		});
	}
	}) ();


(function () {
	if (changeLitersMinus) {
		changeLitersMinus.addEventListener('click', function (e) {
			innerLitersPerHour = parseInt(changeLitersPerHour.value);
			innerLitersPerHour = innerLitersPerHour - 5;

			changeLitersPerHour.value = innerLitersPerHour;

			getSimulation(DNFromSelect, false);
		});
	}
})();

(function () {
	if (changeLitersPerHour) {
		changeLitersPerHour.addEventListener('keyup', function (e) {
			getSimulation(DNFromSelect, false);
		});
	}
})();

/*Scripts for idroset Simulator ends here*/