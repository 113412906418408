/*function getUrlParameter(name) {
    var urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}*/

var items = $(".eu-pagination-item");
var numItems = items.length;

var container = $('.pagination-container');
var perPageAttribute = container.data('perpageitem');
var perPage = perPageAttribute ? parseInt(perPageAttribute, 10) : 12;

var totalPages = Math.ceil(numItems / perPage);
var currentPage = 1;
let loadMore = false;
var paginationText = $('#pagination-count').text();

function showItems(start, end) {
    items.hide().slice(start, end).show();
}

function generatePageNumbers() {
    var pageNumbers = '';
    var startPage = Math.max(currentPage - 1, 1);
    var endPage = Math.min(startPage + 2, totalPages);
    //if ($(window).width() <= 567) {
    for (var i = startPage; i <= endPage; i++) {
        var activeClass = i === currentPage ? 'active-page-num' : '';
        pageNumbers += '<span class="page-number ' + activeClass + '">' + i + '</span>';
    }
    //} else {
    //    for (var i = 1; i <= totalPages; i++) {
    //        var activeClass = i === 1 ? 'active-page-num' : '';
    //        pageNumbers += '<span class="page-number ' + activeClass + '">' + i + '</span>';
    //    }
    //}

    $('#eu-pagination-controls .page-numbers').html(pageNumbers);
    $('#eu-pagination-controls .page-number').on('click', function (e) {
        if (!$(this).hasClass('active-page-num')) {
            var pageNumber = parseInt($(this).text());
            if (!isNaN(pageNumber)) {
                loadMore = false;
                goToPage(pageNumber, loadMore);
                /*if (pageNumber > 1) {
                    updateUrlParameter('PAGEN_1', pageNumber);
                } else {
                     Remove ?PAGEN_1=1 from URL
                    history.replaceState({}, document.title, window.location.pathname);
                }*/
                $('html, body').animate({
                    scrollTop: $("#content").offset().top
                });
            }
        }
    });

    $('#eu-pagination-controls .active-page-num').trigger('click');
}

function updatePaginationCount(loadMore) {
    var showFrom = perPage * (currentPage - 1) + 1;
    var showTo = Math.min(showFrom + perPage - 1, numItems);
    // var paginationText = $('#pagination-count').text();
    var updatePaginationText = paginationText;
    if (updatePaginationText) {
        if (loadMore == true) {

            updatePaginationText = updatePaginationText.replace('{0}', '1').replace('{1}', showTo).replace('{2}', numItems);
            $('#pagination-count').text(updatePaginationText);
            //$('#pagination-count').text('1' + ' - ' + showTo + ' of ' + numItems + ' products');
        } else {
            updatePaginationText = updatePaginationText.replace('{0}', showFrom).replace('{1}', showTo).replace('{2}', numItems);
            $('#pagination-count').text(updatePaginationText);
            //$('#pagination-count').text(showFrom + ' - ' + showTo + ' of ' + numItems + ' products');
        }
    }
    else {
        if (loadMore == true) {
            $('#pagination-count').text('1' + ' - ' + showTo + ' of ' + numItems + ' products');
        } else {

            $('#pagination-count').text(showFrom + ' - ' + showTo + ' of ' + numItems + ' products');
        }

    }
}

function goToPage(pageNumber, loadMore) {
    currentPage = pageNumber;
    var start = perPage * (pageNumber - 1);
    var end = start + perPage;
    if (loadMore != true) {
        showItems(start, end);
    } else {
        showItems(0, end);
    }
    updatePaginationCount(loadMore);
    $('#eu-pagination-controls .page-number').removeClass('active-page-num');
    $('#eu-pagination-controls .page-number').eq(currentPage - 1).addClass('active-page-num');

    if (numItems == end) {
        $('.products-more').hide();
    } else {
        $('.products-more').show();
    }
    if (numItems <= perPage) {
        $('.pagination-container').hide();
        $('#eu-pagination-controls .next').hide();
        $('.products-more').hide();
    } else {
        $('.pagination-container').show().css('display', 'flex');
        if (end >= numItems) {
            end = numItems;
            $('#eu-pagination-controls .next').hide();
        } else {
            $('#eu-pagination-controls .next').show();
        }
        //  $('.products-more').show();
    }
    if (currentPage > 1) {
        $('#eu-pagination-controls .prev').show();
    } else {
        $('#eu-pagination-controls .prev').hide();
    }
}

/*function updateUrlParameter(key, value) {
    var url = new URL(window.location.href);
    url.searchParams.set(key, value);
    history.pushState({}, '', url.href);
}*/

// Check if the PAGEN_1 parameter is present in the URL
//var initialPage = parseInt(getUrlParameter('PAGEN_1')) || 1;

// Remove ?PAGEN_1=1 from URL on load
/*if (initialPage === 1) {
   history.replaceState({}, document.title, window.location.pathname);
}*/

// Initialize the currentPage based on the URL parameter
//currentPage = Math.min(Math.max(initialPage, 1), totalPages);

// Update the initial page in the URL and load the page on document ready
$(document).ready(function () {
    /* if (currentPage > 1) {
         updateUrlParameter('PAGEN_1', currentPage);
     }*/
    generatePageNumbers();
    loadMore = false;
    goToPage(currentPage, loadMore);
});

$('#eu-pagination-controls .prev').hide();

$('#eu-pagination-controls .prev').on('click', function (e) {
    if (currentPage > 1) {
        //if (currentPage - 1 == 1) {
        currentPage--;
        generatePageNumbers();
        // Remove ?PAGEN_1=1 from URL
        //history.replaceState({}, document.title, window.location.pathname);
        loadMore = false;
        goToPage(currentPage, loadMore);
        //} else {
        //    loadMore = false;
        //    goToPage(currentPage - 1, loadMore);
        //   // updateUrlParameter('PAGEN_1', currentPage);
        //}
        $('html, body').animate({
            scrollTop: $("#content").offset().top
        });
    }
});

$('#eu-pagination-controls .next').on('click', function (e) {
    if (currentPage < totalPages) {
        currentPage++;
        generatePageNumbers();
        loadMore = false;
        goToPage(currentPage, loadMore);
        // updateUrlParameter('PAGEN_1', currentPage);
        $('html, body').animate({
            scrollTop: $("#content").offset().top
        });
    }
});

$('.products-more').on('click', function (e) {
    e.stopPropagation();
    currentPage++;
    totalPages = Math.ceil(items.length / perPage);
    loadMore = true;
    var loadMoreScrollPosition = $(window).scrollTop();
    goToPage(currentPage, loadMore);
    // updateUrlParameter('PAGEN_1', currentPage);
    generatePageNumbers();
    $(window).scrollTop(loadMoreScrollPosition);
});

// Handle browser back and forward button clicks
//window.addEventListener('popstate', function (event) {
//    // Reload the page to reflect the URL changes
//    location.reload();
//});
$(document).on('click', '.products-more , .wattseu .pagination-container .pagination-block .page-number , .wattseu .pagination-container .pagination-block .next , .wattseu .pagination-container .pagination-block .prev', function () {
    if ($('a.grid-item:last-of-type').css('display') == 'none') {
        $('.products-more').show();
    }
    else {
        $('.products-more').hide();
    }
});