import $ from 'jquery';


/* receives a data-datasource-id and resets the dropdown */
function resetItem(itemID) {

    const parent = `[data-cascaded-source-id*="${itemID}"]`;

    // deselect this item
    $(parent).prop('selectedIndex', 0);

    // if this item has children items, call the function recursively and
    // unselect those.
    if ($(parent).data('cascaded-children-ids')) {
        resetItem($(parent).data('cascaded-children-ids'));
    }
}

$('.cascaded-dropdown').each(function(index, e) {
    const $element = $(e);
    const sourceId = $element.data('cascaded-source-id');

    const datasource = $(`[data-datasource-id='${sourceId}']`);

    /* we need to create the Parent -> Child relationship
     * So we will loop through al the items and add the child
     * referent to the parent
     */

    if (datasource.data('cascaded-children-ids')) {
        // If the reference already exists, append to it creating a list of ids
        const currentChildList = `| ${datasource.data('cascaded-children-ids')}`;

        datasource.attr('data-cascaded-children-ids', currentChildList);
    } else {
        // if it doesn't exist, create it.
        let childrenIDs = sourceId.replace("{", "");
        childrenIDs = childrenIDs.replace("}", "");
        datasource.attr('data-cascaded-children-ids', childrenIDs);
    }


    datasource.change(function() {
        const $selected = datasource.find('option:selected[data-item-id]');

        $element.find('option:selected').prop('selected', false);

        $element.find('option[data-item-id]').each(function(index, e) {
            const $option = $(e);

            if ($selected.length) {
                const showIfSelected = $option.data('show-if-selected');
                const selectedItemId = $selected.data('item-id');

                if (showIfSelected.indexOf(selectedItemId) === -1) {
                    $option.hide();
                    if( !($option.parent().is('span')) ) $option.wrap('<span>');
                } else {
                    $option.show();
                    if( ($option.parent().is('span')) ) $option.unwrap();
                }
            } else {
                $option.show();
                if( ($option.parent().is('span')) ) $option.unwrap();
            }
        });
    });

    $element.change(function(e) {
        // lets get the id of the child element and pass it
        // to our function that will recursively get all child elements.
        resetItem($(e.currentTarget).data('cascaded-children-ids'));

    });


    $element.change(function() {

        const $selected = $element.find('option:selected[data-item-id]');
        const elementId = $element.attr('id');
        const $message = $(`.note[data-select-id='${elementId}']`);

        if ($selected.length) {
            const messageText = $selected.data('message');
            if (messageText.length) {
                $message.html(messageText);
                $message.removeClass('is-hidden');
            } else {
                $message.html('');
                $message.addClass('is-hidden');
            }
        } else {
            $message.html('');
            $message.addClass('is-hidden');
        }
    });


});