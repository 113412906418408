import {updateLabel} from './how-to-buy-update-label';
import {constrainOptions} from './how-to-buy-constrain-options';

$.each($('.js-how-to-buy'), function(index, buyContainer) {
    updateLabel(buyContainer);
    $(buyContainer).find('.js-how-to-buy__selector').on('reset', function(event) {
        const $form = $(event.currentTarget);
        $form.find('option').removeClass('is-hidden').attr('disabled', false);
        $form.find('.js-how-to-buy__subtype').addClass('is-hidden');
        $form.find('.js-form-required').addClass('is-hidden');
        setTimeout(function() {
            updateLabel(buyContainer);
        });
    });
    $(buyContainer).find('select').on('change', function(event) {
        constrainOptions($(buyContainer), $(event.currentTarget));
        updateLabel(buyContainer);

        if ($(event.currentTarget).attr('name') === "Country")  {
            return;
        }
        const subtype = $(event.currentTarget).find('option:selected').data('subtypes');
        if (subtype) {
            const subtypes = subtype.split(',');

            $('.js-how-to-buy__subtype').addClass('is-hidden');
            $('.js-how-to-buy__subtype').find('input').prop('checked', false);

            $.each(subtypes, function(k, v) {
                const target = `input[name='SubType'][value='${v}']`;
                $(target).parents('.js-how-to-buy__subtype').removeClass('is-hidden');
            });
        } else {
            $('.js-how-to-buy__subtype').addClass('is-hidden');
            $('.js-how-to-buy__subtype').find('input').prop('checked', false);
        }
    });
});
