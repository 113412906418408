import * as hb from "handlebars/dist/handlebars";

(function() {
  const $jumpLinks = $(".js-jump-links");
  if ($jumpLinks.length <= 0) {
    return;
  }

  const jumpLinksData = [];
  $(".js-jump-links-target").each(function(index, target) {
    const id = "jl_" + generateUUID();
    target.id = id;
    const defaultLabel = target.className.indexOf("js-jump-links-default-label") > 0;
    jumpLinksData.push({
      title: target.innerText,
      href: id,
      defaultLabel: defaultLabel
    });
  });

  $jumpLinks.each(function(index, jumpLink) {
      const templateSource = jumpLink.getElementsByClassName("js-jump-link-items-template")[0];
      if (typeof templateSource !== "undefined") {
          const template = hb.compile(templateSource.innerHTML);
          const markup = template(jumpLinksData);
          jumpLink.innerHTML = markup;
      }
  });

  // https://gist.github.com/jed/982883
  function generateUUID(a) {
    return a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, generateUUID);
  }
})();
