function getKwh(initialNum) {
    let outputNum = initialNum;
    const newNum = $('.js-kwh-calc').find('input').val() || 13;

    if (newNum !== initialNum) {
        outputNum = newNum;
    }

    return outputNum;
}
export {getKwh};