$(document).ready(function () {
    if ($(".banner-image-slider").length) {
        // slick slider 
        var bannerImgSlider = $(".banner-image-slider .slider-img").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true,
            autoplaySpeed: 5000,
            dots: true,
            arrows: true
        });

        $(".banner-image-slider .slide-prev").click(function () {
            $(".slide-next").removeClass("active");
            $(".slider-img").slick("slickPrev");
            $(this).addClass("active");
        });

        $(".banner-image-slider .slide-next").click(function () {
            $(".slide-prev").removeClass("active");
            $(".slider-img").slick("slickNext");
            $(this).addClass("active");
        });

        bannerImgSlider.on('afterChange', function (event, slick, currentSlide, nextSlide) {
            if (currentSlide === 0) {
                $(".slide-next").removeClass("active");
                $(".slide-prev").addClass("active");
            } else {
                $(".slide-prev").removeClass("active");
                $(".slide-next").addClass("active");
            }
        });
        $(document).on('click', 'button[data-fancybox-fullscreen]', function () {
            $(".fancybox-slide").addClass("bg-black");
        });
        $(document).on('click', 'button[data-fancybox-close]', function () {
            $(".fancybox-slide").removeClass("bg-black");
        });
    }
});