$(document).ready(function () {
    //DownloadPage--PDFPage
    $(".related-products-link").on('click', function () {
        $('html, body').animate({
            'scrollTop': $(".related-products-item").position().top
        });
    });

    //searchfunctionality

    $("#docsSearch").on("input", function () {
        var searchTerm = $(this).val().toLowerCase();
        if (searchTerm.length >= 2) {
            $(".eu-docs-list-items .eu-accordion-list").each(function () {
                var section = $(this);
                var hasMatchingLink = false;
                section.find(".eu-acc-body ul li").each(function () {
                    var listItem = $(this);
                    var link = listItem.find("a");
                    var linkText = link.text().toLowerCase();

                    if (linkText.includes(searchTerm)) {
                        hasMatchingLink = true;
                        listItem.show();
                        section.find(".eu-acc-header").hide();
                        section.find(".eu-acc-body").show();
                        section.addClass("rm");
                        section.find("ul").addClass("rm");
                        listItem.addClass("rm");
                    } else {
                        listItem.hide();
                    }
                });

                if (!hasMatchingLink) {
                    section.find(".eu-acc-header").hide();
                    section.find(".eu-acc-body").hide();
                    section.addClass("rm");
                }
            });
        } else {
            resetSearch();
        }

        // If the search input is empty, show all headers and bodies
        if (searchTerm === "") {
            resetSearch();
        }

        function resetSearch() {
            $(".eu-docs-list-items .eu-acc-header").show();
            $(".eu-docs-list-items .eu-acc-header").removeClass("active");
            $(".eu-docs-list-items .eu-acc-body").hide();
            $(".eu-docs-list-items .eu-acc-body ul li").show();
            $(".eu-docs-list-items .eu-accordion-list").removeClass("rm");
            $(".eu-docs-list-items .eu-acc-body ul").removeClass("rm");
            $(".eu-docs-list-items .eu-acc-body ul li").removeClass("rm");
        }
    });


    //DownloadPDF
    $("#downloadPdfDoc").click(function (e) {
        e.preventDefault();

        var pdfFilePath = $(this).attr("href");

        var a = document.createElement('a');
        a.href = pdfFilePath;
        a.download = pdfFilePath;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    });

    //ImageHoverFunctionality
    $('.eu-product-card .grid-item__media').each(function () {
        var $secondImg = $(this).find('img:nth-child(2)');
        if ($secondImg.length) {
            $(this).hover(function () {
                $(this).find('img:nth-child(1)').toggle();
                $secondImg.toggle();
            });
        }
    });

});
