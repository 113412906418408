const newLoc = {
    "getLatLng": function(callback) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(callback);
        }
    },
    "gotPos": function(position) {
        const myPosition = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude)
        };
        return myPosition;
    },

    /* Return Zip code from geolocation-----------------------------------------//
    //--------------------------------------------------------------------------*/
    "mapToZip": function(position) {
        let postalCode;
        let country;

        /* -------------------------------------------(Test for google maps api)*/
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {

            const geocoder = new google.maps.Geocoder();

            const mylat = position.coords.latitude;
            const mylng = position.coords.longitude;

            const latlng = new google.maps.LatLng(mylat, mylng);

            geocoder.geocode({'latLng': latlng}, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK && results[0]) {
                    for (let j = 0; j < results[0].address_components.length; j++) {
                        const geoValue = results[0].address_components[j];

                        if ($.inArray("country", geoValue.types) !== -1) {
                            country = geoValue.long_name;
                            const selector = `.js-how-to-buy__country option[value='${country}']`

                            if ($(selector).length > 0) {
                                $(selector).prop('selected', true).parent('select').trigger('change');
                                
                                if ($.inArray("postal_code", geoValue.types) !== -1) {
                                    postalCode = geoValue.short_name;
                                    $('.how-to-buy__location-input').val(postalCode);
                                }
                            }
                        }
                    }
                }
            });
        }
    },

    /* Return coordinates from Zip code-----------------------------------------//
    //--------------------------------------------------------------------------*/
    "zipCoord": function(thisZip) {
        return new Promise(function(resolve, reject) {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({'address': thisZip}, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK) {

                    const lat = results[0].geometry.location.lat();
                    const lng = results[0].geometry.location.lng();
                    const position = {
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    };

                    if (position !== 'undefined') {
                        resolve(position);
                    } else {
                        const reason = new Error('Could not resolve position via zipCoord function.');
                        reject(reason);
                    }
                } else {
                    reject(new Error('Cannot find address'));
                }
            });// Geocoder
        });// Promise
    }, // zipCoord

    /* Get Coordinates from IP--------------------------------------------------//
    //--------------------------------------------------------------------------*/
    "ipCoord": function(ipEndpoint) {
        return new Promise(function(resolve, reject) {
            let lat;
            let lng;
            let position;

            $.ajax({
                type: "GET",
                url: ipEndpoint
            }).done(function(data) {
                if (data && data.GeoIpData) {
                    lat = data.GeoIpData.Latitude;
                    lng = data.GeoIpData.Longitude;
                    position = {
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    };
                }
            });
            if (position) {
                resolve(position);
            } else {
                const reason = new Error('Could not resolve position via ipCoord function.');
                reject(reason);
            }
        });
    },
    "validateLocation": function(address) {
        return new Promise(function(resolve, reject) {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({'address': address}, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    resolve(JSON.stringify(results[0].address_components));
                } else {
                    reject(new Error(''));
                }
            });// Geocoder
        });
    }
};// newLoc

export {newLoc};