const stringInject = (str = '', obj = {}) => {
    if (str) {
        let newStr = str;
        Object.keys(obj).forEach((key) => {
            let placeHolder = `{${key}}`;
            if (newStr.indexOf(placeHolder) > -1) {
                newStr = newStr.replace(placeHolder, obj[key] || " ");
            }
        });
        return newStr;
    }
    return str
}

export { stringInject };