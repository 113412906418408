    function getSearchHistory(storageItemName) {
        var searchHistory = localStorage.getItem(storageItemName);
        return searchHistory ? JSON.parse(searchHistory) : [];
}

function setSearchHistory(searchTerm, searchDestination, storageItemName, queryParamName) {
    /*Adding into searchhistory*/
    var searchHistory = getSearchHistory(storageItemName);
    var existingIndex = searchHistory.findIndex(entry => entry.term === searchTerm);

    if (existingIndex !== -1) {
        searchHistory.splice(existingIndex, 1);
    }

    searchHistory.unshift({ term: searchTerm, link: searchDestination + "/?" + queryParamName + "=" + encodeURIComponent(searchTerm) });
    localStorage.setItem(storageItemName, JSON.stringify(searchHistory));
}

function getSearchHistoryHTML(searchTerm, isLoadOnEmpty, searchDestination, storageItemName, queryParamName, deviceLevelRestrictionNeeded, historyHeading) {

    var searchHistory = getSearchHistory(storageItemName);

    if (isLoadOnEmpty == false) {
        searchHistory = searchHistory.filter(function (o) {
            return o.term.indexOf(searchTerm) !== -1;
        });
    }

        if (searchHistory.length) {
            if (isLoadOnEmpty == false) {
                var existingIndex = searchHistory.findIndex(entry => entry.term === searchTerm);

                if (existingIndex !== -1) {
                    searchHistory.splice(existingIndex, 1);
                    searchHistory.unshift({ term: searchTerm, link: searchDestination + "/?" + queryParamName + "=" + encodeURIComponent(searchTerm) });
                }
            }

            if (deviceLevelRestrictionNeeded) {
                //Take only 3 for mobile and 5 for other devices
                if (window.matchMedia("(max-width: 767px)").matches) {
                    searchHistory = searchHistory.slice(0, 3);
                }
                else {
                    searchHistory = searchHistory.slice(0, 5);
                }
            }
            else {
                searchHistory = searchHistory.slice(0, 5);
            }

            var historyHtml = '';
            if (historyHeading == '') {
                historyHtml = '<ul id="listHistory">';
            }
            else {
                historyHtml = '<p class="ps-headings">' + historyHeading + '</p><ul id="listHistory">';
            }
            for (var i = 0; i < searchHistory.length; i++) {
                historyHtml += '<li><a href="' + searchHistory[i].link + '">' + searchHistory[i].term + '</a> <span class="remove" data-index="' + i + '"></span></li>';
            }
            historyHtml += '</ul>';

            return historyHtml;
        }
}

function getSearchHistoryAfterRemoval(searchTerm, isLoadOnEmpty, searchDestination, storageItemName, queryParamName, indexPlace) {

    var searchHistoryAR = getSearchHistory(storageItemName);

    if (isLoadOnEmpty == false) {
        searchHistoryAR = searchHistoryAR.filter(function (o) {
            return o.term.indexOf(searchTerm) !== -1;
        });
    }

    if (searchHistoryAR.length) {
        if (isLoadOnEmpty == false) {
            var existingIndex = searchHistoryAR.findIndex(entry => entry.term === searchTerm);

            if (existingIndex !== -1) {
                searchHistoryAR.splice(existingIndex, 1);
                searchHistoryAR.unshift({ term: searchTerm, link: searchDestination + "/?" + queryParamName + "=" + encodeURIComponent(searchTerm) });
            }
        }

        var historyHtmlAR = '';
        if (searchHistoryAR.length) {
            indexPlace += 1;
            var singleHistoryItem = searchHistoryAR.at(indexPlace);
            if ((singleHistoryItem != undefined) && (singleHistoryItem != null)) {
                historyHtmlAR = '<li><a href="' + singleHistoryItem.link + '">' + singleHistoryItem.term + '</a> <span class="remove" data-index="' + indexPlace + '"></span></li>';
            }
        }
        return historyHtmlAR;
    }
}
export { getSearchHistory };
export { setSearchHistory };
export { getSearchHistoryHTML };
export { getSearchHistoryAfterRemoval };