const discontinued = {
    "writeFilter": function(container, group, filter) {
        $(container).append(
            `<div class="js-filter filter">
                <button class="js-filter__label filter__label">${group}</button>
                <div class="js-filter__inputs filter__inputs">
                    <div class="js-filter__item filter__item">
                        <label>
                            <input type="checkbox" class="js-filter__input filter__input" value="${filter}" data-facetname="${group}">
                            ${filter}
                        </label>
                    </div>
                </div>
            </div>`
        );
    },

    "getTruth": function(label, array){
        let discontinued;
        const newArray = [];
        for (let i = 0; i < array.length; i++) {
            newArray.push(array[i].Values);
        }
        let strArray = JSON.stringify(newArray);
        strArray = strArray.replace(/\[/g,"").replace(/\]/g,"");
        const valuesArray = strArray.split(",");
        label = `"${label}"`;
        if ($.inArray(label, valuesArray) === -1) {
            discontinued = false;
        } else {
            discontinued = true;
        }
        return discontinued;
    }
}

export {discontinued};