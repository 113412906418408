const updateLabel = function(buyContainer) {
    const $locationInput = $(buyContainer).find('.js-how-to-buy__location-input');
    switch ($(buyContainer).find('.js-how-to-buy__country').val()) {
    case "United States":
        $locationInput.attr('placeholder', $locationInput.data('us-label'));
        $locationInput.next('label').text($locationInput.data('us-label'));
        break;
    case "Canada":
        $locationInput.attr('placeholder', $locationInput.data('ca-label'));
        $locationInput.next('label').text($locationInput.data('ca-label'));
        break;
    default:
        $locationInput.attr('placeholder', $locationInput.data('default-label'));
        $locationInput.next('label').text($locationInput.data('default-label'));
    }
};
export {updateLabel};