//$(".case-studies-eu .js-filter .track_filter").on('click', function () {
//    console.log('click');
//    $('html, body').animate({
//        'scrollTop': $(".js-results-target.results-target").position().top
//    });
//});

if ($("body").hasClass("wattseu")) {
    if ($(".js-filter__input").length) {
        $(document).on("click", ".js-filter__input", function (e) {
            e.preventDefault();
            $('html, body').animate({
                'scrollTop': $(".js-results-target.results-target").position().top
            });
        });
    }

    //FloatingBlockFunctionality
    $(document).ready(function () {
        floatingVideoBlock();

        //WatchVideoClick
        $("#videoModalYT").on("click", function (e) {
            e.preventDefault();
            $(".videomodal-container").addClass("show");
        });
        $(".videomodal-close").on("click", function () {
            $(".videomodal-container").removeClass("show");
            $(".videomodal-container iframe").attr("src", $(".videomodal-container iframe").attr("src"));
        });

        if ($(".slick-slide").length) {
            $(".slick-slide, .slider-slide button, .slider-slide a").removeAttr('tabindex');
            $('.slick-dots').attr("aria-hidden", "true");
            $(".hero-carousel .buttons.new-buttons").attr("role", "group").attr("aria-label", "slide controls");
            $(".hero-carousel").attr("role", "region").attr("aria-roledescription", "carousel").attr("aria-label", "Promoted Content");
        }
    });

    $(window).scroll(function () {
        floatingVideoBlock();
    });

    function floatingVideoBlock() {
        if ($(window).width() >= '992') {
            if ($('.heat-pumps__video-block').length && $('.wattseu .site-footer').length) {
                var footer = $('.wattseu .site-footer');
                var heatPump = $('.heat-pumps__video-block');
                var windowHeight = $(window).height();
                var footerTop = footer.offset().top;
                var scrollTop = $(window).scrollTop();

                if (footerTop < (scrollTop + windowHeight) && scrollTop < (footerTop + footer.height())) {
                    heatPump.addClass('float-active');
                } else {
                    heatPump.removeClass('float-active');
                }
            }
        }

    }
}

$(window).on('load', function() {
    // Check if the scroll position is stored for the current page
    var pageIdentifier = window.location.href;
    var scrollPosition = sessionStorage.getItem('scrollPosition_' + pageIdentifier);

    // Restore scroll position if available
    if (scrollPosition) {
        $(window).scrollTop(scrollPosition);
    }

    // Save scroll position on scroll
    $(window).on('scroll', function () {
        var currentPosition = $(window).scrollTop();
        sessionStorage.setItem('scrollPosition_' + pageIdentifier, currentPosition);
    });

    // Clear scroll position when navigating to a new page
    $(window).on('beforeunload', function () {
        sessionStorage.removeItem('scrollPosition_' + pageIdentifier);
    });
  

    window.onbeforeunload = function () {
        sessionStorage.removeItem('scrollPosition_' + pageIdentifier);
        return;
    };


    if ($(window).width() <= 767) {
         $(".new-related-products-cmp .card .card-img-link").click(function () {
             if (scrollPosition) {
                 sessionStorage.setItem('scrollPosition_' + pageIdentifier, 0);
             }
             $(window).scrollTop(0);
        });

    }
    // Rest of your existing code


    //$(window).scroll(function () {
    //    if (window.pageYOffset > 200) {
    //        $('.scrollToTop').fadeIn();
    //    } else {
    //        $('.scrollToTop').fadeOut();
    //    }
    //});

    //var $element = $('.scrollToTop');
    //var $clone = $element.clone();

    //$element.replaceWith($clone);

    //$clone.on('click', function () {
    //    $('html, body').animate({ scrollTop: 0 }, 100);
    //    return false;
    //});

    //$(window).on('wheel', function (e) {
    /*e.preventDefault();*/
    //var delta = e.originalEvent.deltaY;
    //$(window).scrollTop($(window).scrollTop() + delta);
    //});

    //setTimeout(function () {
    //    $('html, body').css('scroll-behavior', 'smooth');
    //}, 2000);
});

$(function () {
    $('.scrollToTop').click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, 1000);
        return false;
    });

    if ($('body').hasClass('wattseu')) {
        $(':root').css('scroll-behavior', 'auto');
    }

});