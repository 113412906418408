/* eslint-disable no-unused-vars */
import * as hBars from 'handlebars/dist/handlebars';
import throttle from 'lodash-es/throttle';
import { sanitize } from './inc/_sanitize.js';
import { getSearchHistory } from './inc/search-history.js';
import { getSearchHistoryHTML } from './inc/search-history.js';
import { getSearchHistoryAfterRemoval } from './inc/search-history.js';
const $searchBar = $('.header__container.regular .js-search-bar');

/*Brand specific header - search bar*/
const $bsSearchBar = $('.header__container-bs .js-search-bar');
const $wattsSearchHistoryStorageItemName = ($('body').attr('data-website') + "-" + $('.header-serve-history').attr('data-header-name')).replace(/\s+/g, '-').toLowerCase();
const $wattsSearchDestination = ($('.js-search-bar__form').length) ? $('.js-search-bar__form').attr('action') : "/search";

function refreshHistoryEvents(count, sTerm, isLoadOnEmpty) {
    if (count === 1 || count === 2) {
        // Remove handler from existing elements
        $("ul#listHistory .remove").off();

        // Re-add event handler for all matching elements
        $("ul#listHistory .remove").on("click", function () {
            var searchHistoryHTMLAfterRemoval = '';
            var index = $(this).data("index");
            $(this).parent().remove();
            removeSearchHistory(index);

            var lastindex = 0;
            if ($('#listHistory').length > 0) {
                if ($('ul#listHistory li').length >= 1) {
                    lastindex = $('#listHistory li:last-child').index();
                }
            }

            if (lastindex > 0) {
                searchHistoryHTMLAfterRemoval = getSearchHistoryAfterRemoval(sTerm, isLoadOnEmpty, $wattsSearchDestination, $wattsSearchHistoryStorageItemName, "searchterm", lastindex);
            }

            if (searchHistoryHTMLAfterRemoval != '') {
                $("ul#listHistory").append(searchHistoryHTMLAfterRemoval);
                refreshHistoryEvents(2, sTerm, isLoadOnEmpty);
                count = 0;
            }
        });
    }
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function getSearchHistoryAndRender(sTerm, isLoadOnEmpty) {
    var pssearchHistoryContainer = $(".search-histories");
    var searchHistoryHTML = getSearchHistoryHTML(sTerm, isLoadOnEmpty, $wattsSearchDestination, $wattsSearchHistoryStorageItemName, "searchterm", true, pssearchHistoryContainer.attr('data-title'));
    pssearchHistoryContainer.html("");
    
    if (searchHistoryHTML != null && searchHistoryHTML.length) {
        pssearchHistoryContainer.show();
        pssearchHistoryContainer.html(searchHistoryHTML);

        refreshHistoryEvents(1, sTerm, isLoadOnEmpty);
    }
    
}


function removeSearchHistory(index) {
    var searchHistory = getSearchHistory($wattsSearchHistoryStorageItemName);
    searchHistory.splice(index, 1);
    localStorage.setItem($wattsSearchHistoryStorageItemName, JSON.stringify(searchHistory));
}

$searchBar.each(function (index, value) {

    const $searchInput = $(value).find('.js-search-bar__input');
    const $suggestWrapper = $(value).find('.js-predictive-search__wrapper');
    const $searchButton = $(value).parent('.header__container').find('.js-search-button');
    const $searchButtonLink = $searchButton.find('.js-search-button__link');
    const $suggestTarget = $suggestWrapper.children('.js-predictive-search__target');

    /* Get suggest results------------------------------------------------------*/
    const getSuggestions = throttle(function (typing) {
        const $suggestPath = $suggestWrapper.attr('data-endpoint');
        const $resultsLimit = $suggestWrapper.attr('data-resultslimit');
        const $htmlSource = $suggestWrapper.find(".predictive-search__template").html();

        let dataRequest = { searchterm: encodeURIComponent(typing), ResultsLimit: encodeURIComponent($resultsLimit) };

        $.ajax({
            type: "GET",
            data: dataRequest,
            url: $suggestPath,
            async: true

        }).done(function (data) {
            if (data.TotalResults > 0) {
                const suggestion = data;
                const template = hBars.compile($htmlSource);
                if (suggestion) {
                    const suggested = template(suggestion);
                    $suggestTarget.html(suggested);

                    if ($(value).hasClass('is-open')) {
                        $suggestWrapper.addClass('is-visible');
                        getSearchHistoryAndRender(typing, false);
                    }

                } else {
                    $suggestWrapper.removeClass('is-visible');
                }
            } else {
                $suggestWrapper.removeClass('is-visible');
            }

        }).fail(function () {
            $suggestWrapper.removeClass('is-visible');
        });
    }, 1000); // Get suggestions


    /* Make the call on keyup---------------------------------------------------*/
    $searchInput.keyup(function (event) {

        const newTyping = $(event.currentTarget).val();
        const strLen = newTyping.length;

        /* ----------------------------------------------------(Submit on Enter)*/
        if (event.which === 13 || event.keyCode === 13) {

            $(value).children('.js-search-bar__form').submit();

            /* ------------------------------------------------------(any other key)*/
        } else if (strLen > 2) {

            getSuggestions(sanitize(newTyping));

        } else {

            $suggestTarget.html('');
            $suggestWrapper.removeClass('is-visible');

        }
    });// On keyUp

    $('.js-search-bar__input').click(function (e) {
        if ($(this).val() == "") {
            getSearchHistoryAndRender("", true);
            $suggestWrapper.addClass('is-visible');
        }
        else {
            if ($(this).val().length > 2) {
                getSearchHistoryAndRender($(this).val(), false);
                if (!$suggestWrapper.hasClass('is-visible')) {
                    $suggestWrapper.addClass('is-visible');
                }
            }
        }
    });

    /* Hiding the auto-suggestions if the user clicks elsewher on the page------*/
    $(document).mouseup(function (e) {
        const $searchWrapperChildren = $suggestWrapper.children();
        if (
            !$suggestWrapper.is(e.target)
            && $searchWrapperChildren.has(e.target).length === 0
            && !$searchButton.is(e.target)
            && !$searchButtonLink.is(e.target)
            && !$searchInput.is(e.target)

        ) {
            $suggestWrapper.removeClass('is-visible');
            $(value).removeClass('is-open');

            if (!$('.js-search-bar').hasClass('is-open')) {
                $searchButtonLink.removeClass('is-open');
            }
        }
    }); // Hide auto-suggest

});


$bsSearchBar.each(function (index, value) {
    console.log("value", $(value));

    const $bssearchInput = $(value).find('.js-search-bar__input');
    const $bssuggestWrapper = $(value).find('.js-predictive-search__wrapper');
    const $bssearchButton = $(value).parent('.header__container-bs').find('.js-search-button');
    const $bssearchButtonLink = $bssearchButton.find('.js-search-button__link');
    const $bssuggestTarget = $bssuggestWrapper.children('.js-predictive-search__target');
    const $bssearchSelect = $(value).find('.brand-search-dropdown');
    let brandCookieVal = getSearchBrandCookie("searchBrand");

    if ($bssearchSelect.length > 0) {
        $bssearchSelect.val(brandCookieVal);
    }

    /* Get suggest results------------------------------------------------------*/
    const getbsSuggestions = throttle(function (typing, brandValue) {
        const $bssuggestPath = $bssuggestWrapper.attr('data-endpoint');
        const $bsResultsLimit = $bssuggestWrapper.attr('data-resultslimit');
        const $bshtmlSource = $bssuggestWrapper.find(".predictive-search__template").html();

        let dataRequested = { searchterm: encodeURIComponent(typing), ResultsLimit: encodeURIComponent($bsResultsLimit) };
        if (brandValue != '') {
            dataRequested = { searchterm: encodeURIComponent(typing), brand: encodeURIComponent(brandValue), ResultsLimit: encodeURIComponent($bsResultsLimit) };
        }

        $.ajax({
            type: "GET",
            data: dataRequested,
            url: $bssuggestPath,
            async: true

        }).done(function (data) {
            if (data.TotalResults > 0) {
                const suggestion = data;
                const template = hBars.compile($bshtmlSource);
                if (suggestion) {
                    const suggested = template(suggestion);
                    $bssuggestTarget.html(suggested);
                    $bssuggestWrapper.addClass('is-visible');
                    getSearchHistoryAndRender(typing, false);
                } else {
                    $bssuggestWrapper.removeClass('is-visible');
                }
            } else {
                $bssuggestWrapper.removeClass('is-visible');
            }

        }).fail(function () {
            $bssuggestWrapper.removeClass('is-visible');
        });
    }, 1000); // Get suggestions

    $('.js-search-bar__input').click(function (e) {
        if ($(this).val() == "") {
            getSearchHistoryAndRender("", true);
            $bssuggestWrapper.addClass('is-visible');
        }
        else {
            if ($(this).val().length > 2) {
                getSearchHistoryAndRender($(this).val(), false);
                if (!$bssuggestWrapper.hasClass('is-visible')) {
                    $bssuggestWrapper.addClass('is-visible');
                }
            }
        }
    });

    /* Make the call on keyup---------------------------------------------------*/
    $bssearchInput.keyup(function (event) {

        const newTyping = $(event.currentTarget).val();
        const strLen = newTyping.length;
        let brandval = $(event.target).parent().parent().find('.brand-search-dropdown').val();

        /* ----------------------------------------------------(Submit on Enter)*/
        if (event.which === 13 || event.keyCode === 13) {
            const newSAction = $('.js-search-bar__form').attr('action');
            const plainUrl = "https://" + window.location.hostname + newSAction + "/";

            let finUrl = "";
            let searchQuery = $(event.target).parent().parent().find('.js-search-bar__input').val();;
            if (searchQuery) {
                searchQuery = sanitize(searchQuery);
            }
            finUrl = `${plainUrl}?searchterm=${searchQuery}`;
            setSearchBrandCookie(brandval);
            if (brandval != '') {
                let tempBsArray = [];
                tempBsArray.push({ 'Name': "Brand", 'Values': [] });
                tempBsArray[0]['Values'].push(brandval);
                let filtrs = encodeURIComponent(JSON.stringify(tempBsArray));

                finUrl = `${finUrl}&Filters=${filtrs}&Page=0`;
            }

            window.location.href = finUrl;

            /* ------------------------------------------------------(any other key)*/
        } else if (strLen > 2) {

            getbsSuggestions(sanitize(newTyping), sanitize(brandval));

        } else {

            $bssuggestTarget.html('');
            $bssuggestWrapper.removeClass('is-visible');

        }
    });// On keyUp

    /* Hiding the auto-suggestions if the user clicks elsewher on the page------*/
    $(document).mouseup(function (e) {
        const $bssearchWrapperChildren = $bssuggestWrapper.children();
        if (
            !$bssuggestWrapper.is(e.target)
            && $bssearchWrapperChildren.has(e.target).length === 0
            && !$bssearchButton.is(e.target)
            && !$bssearchButtonLink.is(e.target)
            && !$bssearchInput.is(e.target)
            && !$bssearchSelect.is(e.target)
        ) {
            $bssuggestWrapper.removeClass('is-visible');
            $(value).removeClass('is-open');

            if (!$('.js-search-bar').hasClass('is-open')) {
                $bssearchButtonLink.removeClass('is-open');
            }
        }
    }); // Hide auto-suggest    

    $('.bs-search-bar__button .submit-button').click(function (e) {
        e.preventDefault();
        let finUrl = "";
        const newSAction = $('.js-search-bar__form').attr('action');
        const plainUrl = "https://" + window.location.hostname + newSAction + "/";

        let brandval = $(this).parent().parent().find('.brand-search-dropdown').val();
        let searchQuery = $(this).parent().parent().find('.js-search-bar__input').val();
        if (searchQuery) {
            searchQuery = sanitize(searchQuery);
        }
        finUrl = `${plainUrl}?searchterm=${searchQuery}`;
        setSearchBrandCookie(brandval);
        if (brandval != '') {
            let tempBsArray = [];
            tempBsArray.push({ 'Name': "Brand", 'Values': [] });
            tempBsArray[0]['Values'].push(brandval);
            let filtrs = encodeURIComponent(JSON.stringify(tempBsArray));

            finUrl = `${finUrl}&Filters=${filtrs}&Page=0`;
        }
        window.location.href = finUrl;
    });

    $('.bs-search-bar__button .submit-button').keyup(function (e) {
        e.preventDefault();

        if (e.which === 13 || e.keyCode === 13) {
            let finUrl = "";
            const newSAction = $('.js-search-bar__form').attr('action');
            const plainUrl = "https://" + window.location.hostname + newSAction + "/";

            let brandval = $(e.target).parent().parent().find('.brand-search-dropdown').val();
            let searchQuery = $(e.target).parent().parent().find('.js-search-bar__input').val();
            if (searchQuery) {
                searchQuery = sanitize(searchQuery);
            }
            finUrl = `${plainUrl}?searchterm=${searchQuery}`;
            setSearchBrandCookie(brandval);
            if (brandval != '') {
                let tempBsArray = [];
                tempBsArray.push({ 'Name': "Brand", 'Values': [] });
                tempBsArray[0]['Values'].push(brandval);
                let filtrs = encodeURIComponent(JSON.stringify(tempBsArray));

                finUrl = `${finUrl}&Filters=${filtrs}&Page=0`;
            }
            window.location.href = finUrl;
        }
    });

    function setSearchBrandCookie(brandValue) {
        setCookie("searchBrand", brandValue, 7);
    }

    function getSearchBrandCookie(cname) {
        return getCookie(cname);
    }

});

$(window).on('load', function () {

    function highlightCommonCompCom(searchword) {
        if (!$('body').hasClass('wattseu')) {
            if (window.location.href.includes('?searchterm=') || window.location.href.includes('?qh=') || window.location.href.includes('?q=')) {
                if (searchword != "" && $('.main-container').length) {
                    $('.main-container').highlight(searchword.toLowerCase());
                } else {
                    console.log("Higligt not found");
                }
            }
        }
    }
    function highlightSearchTerm() {
        const urlHdrParam = new URLSearchParams(window.location.search);
        let paramHdrValue = urlHdrParam.get('searchterm');

        if (paramHdrValue) {
            paramHdrValue = decodeURIComponent(paramHdrValue);
        }

        setTimeout(function () {
            highlightCommonCompCom(paramHdrValue);
        }, 2000);

    }

    function highlightSearchTermInDetail() {
        const urlHdrParam = new URLSearchParams(window.location.search);
        let paramHdrValue = urlHdrParam.get('qh');

        if (paramHdrValue) {
            paramHdrValue = decodeURIComponent(paramHdrValue);
        }
        setTimeout(function () {
            highlightCommonCompCom(paramHdrValue);
        }, 2000);
    }

    let url = window.location.href;

    if (url.includes("search")) {
        highlightSearchTerm();
    } else {
        highlightSearchTermInDetail();
    }
});