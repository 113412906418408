$(document).ready(function () {
    $(".eu-accordion-list > .eu-acc-body").hide();

    $(".eu-accordion-list .eu-acc-header").click(function (event) { 
        if ($(event.target).closest('.eu-certificate-cmp').length === 0) {
            if ($(this).hasClass("active")) {
                $(this).removeClass("active").siblings(".eu-acc-body").slideUp();
                $(this).attr("aria-expanded", "false");
            } else {
                $(this).addClass("active").siblings(".eu-acc-body").slideDown();
                $(this).attr("aria-expanded", "true");
            }
            return false;
        }
    });

    $(".eu-accordion-list .eu-acc-header").keypress(function (event) {
        if ($(event.target).closest('.eu-certificate-cmp').length === 0) {
            if (event.keyCode === 13) {
                if ($(this).hasClass("active")) {
                    $(this).removeClass("active").siblings(".eu-acc-body").slideUp();
                    $(this).attr("aria-expanded", "false");
                } else {
                    $(this).addClass("active").siblings(".eu-acc-body").slideDown();
                    $(this).attr("aria-expanded", "true");
                }
                return false;
            }
        }
    });

    //ExpandAllCollapseAll
    $(".eu-expand-button").click(function () {
        $(this).siblings(".eu-expand-text").removeClass("expanded");
        $(this).siblings(".eu-expand-text").css("max-height", "100%");
        $(this).hide();
        $(this).siblings(".eu-collapse-button").show().focus();
        $(this).siblings(".eu-expand-text").attr("aria-expanded", "true");
    });
    $(".eu-collapse-button").click(function () {
        $(this).siblings(".eu-expand-text").addClass("expanded");
        $(this).siblings(".eu-expand-text").css("max-height", "120px");
        $(this).hide();
        $(this).siblings(".eu-expand-button").show().focus();
        $(this).siblings(".eu-expand-text").attr("aria-expanded", "false");
    });

});



