if (typeof MktoForms2 !== 'undefined') {
    MktoForms2.whenReady(function() {
        $('.js-selection-tool-connector').each(function(index, e) {
            const $element = $(e);
            const selector = `input[name="${$element.data('fieldName')}"]`;
            const $field = $(selector);

            $field.val($element.val());
        });
    });
}
