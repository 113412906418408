$(document).ready(function () {
    $(".oem-content-slider .slider-wrapper").each(function () {
        var $slider = $(this);
        var $prevButton = $slider.closest(".oem-slider").find(".slider-button-prev");
        var $nextButton = $slider.closest(".oem-slider").find(".slider-button-next");
        var $counter = $slider.closest(".oem-slider").find('.slider-counter');

        $slider.on("init", function (event, slick) {
            disableButtons(slick.currentSlide + 1, slick.slideCount, $prevButton, $nextButton);
            $slider.append($counter);
            updateSliderCounter(slick, $counter);
        });

        $slider.on("beforeChange", function (event, slick, currentSlide, nextSlide) {
            disableButtons(nextSlide + 1, slick.slideCount, $prevButton, $nextButton);
        });

        $slider.on('afterChange', function (event, slick, currentSlide) {
            updateSliderCounter(slick, $counter);
        });

        $prevButton.on("click", function () {
            $slider.slick("slickPrev");
        });

        $nextButton.on("click", function () {
            $slider.slick("slickNext");
        });

        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 400,
            infinite: false,
            arrows: false,
            initialSlide: 0
        });
    });

    function disableButtons(currentSlide, slideCount, $prevButton, $nextButton) {
        if (currentSlide === 1) {
            $prevButton.addClass("slider-button-disabled");
            $nextButton.removeClass("slider-button-disabled");
        } else if (currentSlide === slideCount) {
            $prevButton.removeClass("slider-button-disabled");
            $nextButton.addClass("slider-button-disabled");
        } else {
            $prevButton.removeClass("slider-button-disabled");
            $nextButton.removeClass("slider-button-disabled");
        }
    }

    function updateSliderCounter(slick, $counter) {
        var currentSlide = slick.slickCurrentSlide() + 1;
        var slidesCount = slick.slideCount;
        $counter.text('0' + currentSlide + '/' + '0' + slidesCount);
    }

    $('.oem-image-content-container:last .content-width').addClass('remove-border-bottom');
});

