$(function () {
    if ($(".wattseu .primary-nav__header").length) {
        fnStickyHeader();
    }
    $(".represent-select-field select option:first").attr('hidden', 'hidden');
});

$(window).on("resize", function (event) {
    if ($(".wattseu .primary-nav__header").length) {
        fnStickyHeader();
    }
});

$(window).on("scroll", function (event) {
    if ($(".wattseu .primary-nav__header").length) {
        fnStickyHeader();
    }
});

$(window).on("resize", function (event) {
    if ($(".wattseu .primary-nav__header").length) {
        fnStickyHeader();
    }
});

$(document).mouseup(function (e) {
    if ($(".menu-navigation").length) {
        var mobileMenu = $(".menu-navigation");
        var modalPopup = $(".modal");

        if ($(window).width() <= "1200") {
            if ((!mobileMenu.is(e.target) && mobileMenu.has(e.target).length === 0) && (!modalPopup.is(e.target) && modalPopup.has(e.target).length === 0)) {
                mobileMenu.removeClass("show").css("display", "none");
                $(".mobile-menu-overlay").css("display", "none");
                $("body").css("position", "unset");
            }
        }
    }
});

function floatingLables() {
    $(".feedback-input").each(function () {
        // Check if the input has a value
        if ($(this).val().trim() !== "") {
            // Add class active to the previous label of the input
            $(this).prev(".feedback-label").addClass("active");
        }
    });
}

$(document).on("click", ".modal-backdrop", function (e) {
    if ($(".modal-backdrop").length) {
        $("#languageModal, #dynamicModal,#heatPumpModal,#socialShareModal, #FeedbackThanksyouModal, #searchModal").modal('hide');
    }
});

// sub menu links hover and blur text color effect
$(".menu-navigation li.dropdown .nav-link-inside").on("mouseover", function (e) {
    $(this).addClass("link-hover");
    $(".menu-navigation li.dropdown .nav-link-inside").not(this).addClass("link-no-hover");
});
$(".menu-navigation li.dropdown .nav-link-inside").on("mouseout", function (e) {
    $(".menu-navigation li.dropdown .nav-link-inside").removeClass("link-no-hover link-hover");
});


// hamberger menu open functionality
$(".hamberger-menu").on("click", function (e) {
    $(this).closest(".right-nav-menu").siblings(".menu-navigation").css("display", "block").addClass("show");
    $(this).closest(".right-nav-menu").siblings(".mobile-menu-overlay").css("display", "block");
    if ($(window).width() <= 1199) {
        $("body").css("position", "fixed");
        $("body").css("overflow-y", "hidden");
    }
    $(".clear-icon").addClass("header-apply-pos-z-index");
    $(".nav-item.dropdown").removeClass("active");
    $(".dropdown-menu").removeClass("show");
});

// hamberger menu close functionality
$(".mobile-menu-close").on("click", function (e) {
    $(this).closest(".menu-navigation").css("display", "none").removeClass("show");
    $(this).closest(".menu-navigation").siblings(".mobile-menu-overlay").css("display", "none");
    if ($(window).width() <= 1199) {
        $("body").css("position", "unset");
        $("body").css("overflow", "auto");
    }

    $(".clear-icon").removeClass("header-apply-pos-z-index");
});

//FeedbackModal--selectboxToggleClass
$(".represent-select-field select").on('click', function () {
    $(this).siblings(".arrow-cta").toggleClass("active");
});

// countryInputClick
$(document).on("click keydown", ".countries-input", function (e) {
    if ($('.countries-input').length) {
        e.preventDefault();
        $("#feedbackModal").addClass("hide-modal");
        $("#countryFeedbackModal").modal("show");
        if (e.keyCode === 13) {
            // Open the modal
            $("#countryFeedbackModal").modal("show");
        }
        $("#countryFeedbackModal").find(".countries-tabs-item:first-child").addClass("active");
        $(".feedback-label").removeClass("active");
        $(".modal-backdrop.show").css("opcaity", "0.1");
        $('#search').val("");
        countrysearch("");
    }
});

$('#countryFeedbackModal').on('hidden.bs.modal', function () {
    $("#feedbackModal").removeClass("hide-modal");
    floatingLables();
});

// countryInputClick
$(document).on('click', '.countries-list-section-item', function (e) {
    if ($('.countries-list-section-item').length) {
        var valueInput = e.target.innerText;
        $("#countryFeedbackModal").modal("hide");
        $("#feedbackModal .countries-input").val(valueInput);
        $("input[data-sc-field-name='toemail']").val($(this).attr("data-email"));
        $("#feedbackModal .countries-input").prev(".feedback-label").addClass("active");
        $("#feedbackModal").find(".countries-input").focus();
    }
    floatingLables();
});

$(".feedback-btn").click(function () {
    $('#feedbackModal').on('shown.bs.modal', function () {
        $("#feedbackModal .interested-product-field").addClass("d-none");
    });
});

//$(".feedback-btn").click(function () {
//    $('#feedbackModal').on('shown.bs.modal', function () {
//        $("#feedbackModal .interested-product-field").addClass("d-none");
//    });
//});

$('#feedbackModal').on('hidden.bs.modal', function () {
    $(this).find('form').trigger('reset');
    
    $(".feedback-input").each(function () {
        // Check if the input has a value
        if ($(".feedback-input").val() === "") {
            $(this).siblings(".feedback-label").removeClass("active");
        } else {
            $(this).siblings(".feedback-label").addClass("active");
        }
    });
    //$(this).find('form').children(".feedback-label").removeClass("active");
    $("select[data-sc-field-name='whoyourepresent']").addClass('who-u-rep');
});

$('#feedbackModal').on('shown.bs.modal', function () {
    $('#feedbackModal .btn-close').focus();
    $(this).find('form').trigger('reset');
    $(".feedback-input").removeClass("input-validation-error");
    $(".field-validation-valid").hide();
});

$('#countryFeedbackModal').on('shown.bs.modal', function () {
    $("#countryFeedbackModal").find(".countries-tabs-item:first-child").addClass("active");
});

$(".heat-pumps__video-block").on("click", function (e) {
    e.preventDefault();
});

function countrysearch(searchtext) {
    var searchkey = searchtext;
    var url = $('#countryFeedbackModal').attr('data-endpoint');
    var data = { "searchkey": searchkey };
    $.ajax({
        type: "POST",
        url: url,
        data: data,
        success: function (response) {
            $(".countries-search").empty().append(response);
        },
        error: function (response) {
            $(".countries-search").empty().append(response);
        }
    });
}

$(".wattseu #search").on("input", function () {
    countrysearch(this.value);
    var inputText = $(this).val();

    if (inputText.length > 0) {
        $(this).siblings(".close-country-search").show();
    } else {
        $(this).siblings(".close-country-search").hide();
    }
});

$(".close-country-search").on("click", function () {
    $('#search').val('');
    $(this).hide();
    countrysearch("");
});

//$(".close-country-search").on("click", function () {
//    $('#search').val('');
//    $(this).hide();
//    countrysearch("");
//});

document.addEventListener("sc-forms-submit-success", function (e) {
    if ($("#FeedbackThanksyouModal").length > 0) {
        $("#feedbackModal").modal("hide");
        $("#FeedbackThanksyouModal").modal("show");
    }
});


// Feedback form input fields focus
$(document).on('focus', ".feedback-input", function () {
    if ($(".feedback-input").length) {
        $(".feedback-input").each(function () {
            if ($(this).val().trim() !== "") {
                $(this).prev(".feedback-label").addClass("active");
            }
        });
    }
});

// Feedback form input fields blur
$(document).on('blur', ".feedback-input", function () {
    if ($(".feedback-input").length) {
        if (!$(this).val()) {
            $(this).prev(".feedback-label").removeClass("active");
        }
    }
});

$(document).on('click', ".feedback-input", function () {
    if ($(".feedback-input").length) {
        $(this).attr('autocomplete', 'off');
        $(this).prev(".feedback-label").addClass("active");
    }
});

$(document).on('input', '.feedback-input', function () {
    if ($(".feedback-input").length) {
        if ($(this).val()) {
            $(this).prev(".feedback-label").addClass("active");
        } else {
            $(this).prev(".feedback-label").removeClass("active");
        }
    }
});

/*sub-menu-links */
$(document).ready(function () {
    var dropdowns = $(".nav-item.sub-menu-links");
    var header = $(".primary-nav__header");

    dropdowns.on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        var dropdown = $(this);
        var menuContent = dropdown.find('.dropdown-menu');

        if (menuContent.hasClass("show")) {
            menuContent.removeClass("show");
            dropdown.removeClass("active");
            dropdowns.find(".nav-link").attr("aria-expanded", "false");
        } else {
            menuContent.addClass("show");
            dropdown.addClass("active");
            dropdowns.not(dropdown).find('.dropdown-menu').removeClass("show");
            dropdowns.not(dropdown).removeClass("active");
            dropdowns.find(".nav-link").attr("aria-expanded", "true");
        }
    });

    dropdowns.find('.dropdown-menu').on("click", function (e) {
        e.stopPropagation();
    });

    header.on("click", function (e) {
        e.stopPropagation();
    });

    $(document).on("click", function (e) {
        if (dropdowns.hasClass("active")) {
            dropdowns.each(function () {
                var dropdown = $(this);
                var menuContent = dropdown.find('.dropdown-menu');
                if (!dropdown.is(e.target) && dropdown.has(e.target).length === 0 && !menuContent.is(e.target) && menuContent.has(e.target).length === 0) {
                    dropdown.removeClass("active");
                    menuContent.removeClass("show");
                }
            });
        }
    });

});
// function for sticky header
var fnStickyHeader = function () {
    //if ($(".wattseu .primary-nav__header").length) {
    //    var header = document.querySelector(".wattseu .primary-nav__header");
    //    var stickyClass = "sticky-nav";
    //    var currentScroll = window.pageYOffset;
    //    if (currentScroll > 150) {
    //        header.classList.add(stickyClass);
    //    } else {
    //        header.classList.remove(stickyClass);
    //    }
    //}
    if ($(".wattseu .primary-nav__header").length) {
        var headerHeight = $(".primary-nav__header").outerHeight();
        var currentScroll = $(window).scrollTop();

        if (currentScroll > headerHeight) {
            $(".wattseu .primary-nav__header").addClass('sticky-nav');
            if (!$(".placeholder-block").length) {
                $('<div class="placeholder-block"></div>').insertBefore(".main-container");
                $('.placeholder-block').css('height', headerHeight + 'px');
            }
        } else {
            $(".wattseu .primary-nav__header").removeClass('sticky-nav');
            if ($(".placeholder-block").length) {
                $(".placeholder-block").remove();
            }

        }
    }
}

//Code to make tabbing clear on search modal window
$('#searchModal').on('shown.bs.modal', function () {
    $('html, body').css('overflow', 'hidden');
    $("#searchtermeu").focus();
});
$('#searchModal').on('hidden.bs.modal', function () {
    $('html, body').css('overflow', 'unset');
});
