$(document).ready(function () {
	var images = $(".image-container .slide-image-item").length;
	var currentIndex = 0;
	var autoChangeInterval, timePharse;

	$('.slide-image-item').hide().eq(currentIndex).show();

	updateCarousel(currentIndex);
	startAutoChange();

	function updateCarousel(index) {
		timePharse = setTimeout(function () {
			$('.slide-image-item').hide().eq(index).show().addClass('active');
			$('.icon-desc-item').removeClass('active');
			$(`.icon-desc-item[data-index="${index}"]`).addClass('active');
			$('.next-btn').addClass('border-rotate');
			$('.icon-desc-item .icon-img-active').hide();
			$('.icon-desc-item .icon-img').show();
			$(`.icon-desc-item[data-index="${index}"] .icon-img`).hide();
			$(`.icon-desc-item[data-index="${index}"] .icon-img-active`).show();
		}, 300);
	}

	function changeToNextImage() {
		currentIndex = (currentIndex + 1) % images;
		updateCarousel(currentIndex);
	}

	$('#slider-prevBtn').click(function () {
		$('.next-btn').removeClass('border-rotate');
		currentIndex = (currentIndex - 1 + images) % images;
		updateCarousel(currentIndex);
	});

	$('#slider-nextBtn').click(function () {
		$('.next-btn').removeClass('border-rotate');
		changeToNextImage();
	});


	$('.icon-desc-item').click(function () {
		$('.next-btn').removeClass('border-rotate');
		var newIndex = $(this).data('index');
		currentIndex = newIndex;
		updateCarousel(currentIndex);
	});


	function startAutoChange() {
		$('.next-btn').addClass('border-rotate');
		autoChangeInterval = setInterval(function () {
			$('.next-btn').removeClass('border-rotate');
			changeToNextImage();
		}, 6000);
	}


	$('.image-container, .dc-slider-right, .button-section-div').hover(
		function () {
			clearInterval(autoChangeInterval);
			$('.next-btn').removeClass('border-rotate');
		},
		function () {
			startAutoChange();
		}
	);
});