require('slick-carousel');

$(window).on('load', function () {
    if ($(".spin-360-image").find("img").length > 0 && $("div.Sirv").find("img").length > 0) {
        $(".spin-360-image").find("img")[0].src = $("div.Sirv").find("img")[0].src.replace('width=100', 'width=500');
        $(".button-360").attr('style', 'position:absolute;');
    }
});
$(window).on('click', function (event) {
    if (event.target.className === "modal-dialog") {
        $("#myModal")[0].style.display = "none";
    }
});
$('.js-gallery--media').slick({
    arrows: false,
    dots: false,
    swipe: false
});

$('.js-gallery--thumbnails').each(function(index, e) {
    const $element = $(e);
    const previousText = $element.data('previous-text');
    const SpinClick = $element.find("#myBtns");//added for sirv implementation of 360 degree
    const nextText = $element.data('next-text');

    $("#myBtn").on('click', function () {
        $("#myModal").css('display', 'block');
        $("#myModal .close").on('click', function () {
            $("#myModal").css('display', 'none');
        });
    });
    //added for sirv implementation of 360 degree
    SpinClick.on('click', function () {
        $("#myModal").css('display', 'block');
        $("#myModal .close").on('click', function () {
            $("#myModal").css('display', 'none');
        });
        if ($(".spin-360-image").find("img").length > 0 && $("div.Sirv").find("img").length > 0) {
            $(".spin-360-image").find("img").each(function () {
                if ($(this)[0].className === "") {
                    $(this)[0].src = $("div.Sirv").find("img")[0].src.replace('width=100', 'width=500');
                    $(".button-360").attr('style', 'position:absolute;');
                }
            });
        }
    });
    //End of added for sirv implementation of 360 degree

    $element.slick({
        asNavFor: '.js-gallery--media',
        focusOnSelect: true,
        infinite: false,
        prevArrow: `<button class="slick-prev" aria-label="previous slide" type="button"></button>`,
        nextArrow: `<button class="slick-next" aria-label="next slide" type="button"></button>`,
        row: 0,
        variableWidth: true
        
    });
    //added for sirv implementation of 360 degree
    $(".slick-next.slick-arrow").on('click', function () {
        if ($(".slick-slide.slick-current.slick-active").find("button").length > -1) {
            if ($(".spin-360-image").find("img").length > 0 && $("div.Sirv").find("img").length > 0) {
                $(".spin-360-image").find("img").each(function () {
                    if ($(this)[0].className === "") {
                        $(this)[0].src = $("div.Sirv").find("img")[0].src.replace('width=100', 'width=500');
                        $(".button-360").attr('style', 'position:absolute;');
                    }
                });
            }
        }
    });
    //End of added for sirv implementation of 360 degree
});

// system selection tool carousel/gallery
$('.js-gallery--sst').each(function(index, e) {
    const $element = $(e);
    const $synced = $element.siblings('.js-thumbnails__container').find('.js-thumbnails--sst');

    $element.slick({
        adaptiveHeight: true,
        arrows: false,
        asNavFor: $synced,
        dots: false,
        rows: 0
    });
});

$('.js-thumbnails__container').each(function(index, e) {
    const $container = $(e);
    const $element = $container.find('.js-thumbnails--sst');
    const $fake = $element.siblings('.js-sst__fake--thumbnail').addClass('is-active');
    const $synced = $container.siblings('.js-gallery--sst');
    const previousText = $element.data('previous-text');
    const nextText = $element.data('next-text');

    $fake.on('click', function() {
        $element.slick('slickGoTo', 0);
    });

    $element.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        if (nextSlide === 0) {
            $fake.addClass('is-active');
        } else {
            $fake.removeClass('is-active');
        }
    });
    $element.slick({
        appendArrows: $container,
        asNavFor: $synced,
        focusOnSelect: true,
        infinite: false,
        prevArrow: `<a class="slick-prev" aria-label="${previousText}" type="button"></a>`,
        nextArrow: `<a class="slick-next" aria-label="${nextText}" type="button"></a>`,
        rows: 0,
        useCSS: true,
        useTransform: true,
        variableWidth: true
    });
});



var currentImageSrc;
$(".slide-media .galllery__slide--image").on("click", function () {
    currentImageSrc = $(this).attr("src");
    $('.galleryModel .modal-Image').attr("src", currentImageSrc);

    $('.galleryModel').css("display", "block");
    //$('#zoomItem').zoom();

    //$('#zoomItem').zoom({ on: 'click' });

    //$('#zoomItem').zoom({ on: 'toggle' });

    //$('#zoomItem').imageZoom({ zoom: 300 });


    if ($(window).width() < 960) {

        var hammerIt = document.getElementById('#pinchzoom')
        function hammerIt(elm) {
            hammertime = new Hammer(elm, {});
            hammertime.get('pinch').set({
                enable: true
            });
            var posX = 0,
                posY = 0,
                scale = 1,
                last_scale = 1,
                last_posX = 0,
                last_posY = 0,
                max_pos_x = 0,
                max_pos_y = 0,
                transform = "",
                el = elm;

            hammertime.on('doubletap pan pinch panend pinchend', function (ev) {
                if (ev.type == "doubletap") {
                    transform =
                        "translate3d(0, 0, 0) " +
                        "scale3d(2, 2, 1) ";
                    scale = 2;
                    last_scale = 2;
                    try {
                        if (window.getComputedStyle(el, null).getPropertyValue('-webkit-transform').toString() != "matrix(1, 0, 0, 1, 0, 0)") {
                            transform =
                                "translate3d(0, 0, 0) " +
                                "scale3d(1, 1, 1) ";
                            scale = 1;
                            last_scale = 1;
                        }
                    } catch (err) { }
                    el.style.webkitTransform = transform;
                    transform = "";
                }

                //pan    
                if (scale != 1) {
                    posX = last_posX + ev.deltaX;
                    posY = last_posY + ev.deltaY;
                    max_pos_x = Math.ceil((scale - 1) * el.clientWidth / 2);
                    max_pos_y = Math.ceil((scale - 1) * el.clientHeight / 2);
                    if (posX > max_pos_x) {
                        posX = max_pos_x;
                    }
                    if (posX < -max_pos_x) {
                        posX = -max_pos_x;
                    }
                    if (posY > max_pos_y) {
                        posY = max_pos_y;
                    }
                    if (posY < -max_pos_y) {
                        posY = -max_pos_y;
                    }
                }


                //pinch
                if (ev.type == "pinch") {
                    scale = Math.max(.999, Math.min(last_scale * (ev.scale), 4));
                }
                if (ev.type == "pinchend") { last_scale = scale; }

                //panend
                if (ev.type == "panend") {
                    last_posX = posX < max_pos_x ? posX : max_pos_x;
                    last_posY = posY < max_pos_y ? posY : max_pos_y;
                }

                if (scale != 1) {
                    transform =
                        "translate3d(" + posX + "px," + posY + "px, 0) " +
                        "scale3d(" + scale + ", " + scale + ", 1)";
                }

                if (transform) {
                    el.style.webkitTransform = transform;
                }
            });
        }
    }
    else {
        var zoomImages = $('.zoom-images');

        zoomImages.each(function () {
            $(this).imageZoom();
        });
    }

    
});

$(".galleryModel .close").on("click", function () {
    $('.galleryModel').css("display", "none");
    if ($(window).width() < 960) {

    }
    else {
        $('.containerZoom').remove();
        var newImage = $("<img>");
        newImage.addClass("modal-Image zoom-images");

        newImage.attr("src", "");
        $(".galleryModel span.zoom").append(newImage);
    }
});

