/* eslint-disable spaced-comment */
/* eslint-disable no-var */
$(document).ready(function () {
    //if ($("body").hasClass("wattseu") && $(".eu-series-gallery").length > 0) {
    //    var galleryMediaElement = $('.js-gallery--media');

    //    if (galleryMediaElement && galleryMediaElement.length > 0) {
    //        console.log("gallery exists");
    //        if (!$('.js-gallery--media').hasClass('slick-initialized')) {
    //            $('.js-gallery--media').slick('unslick');
    //        }

    //        $('.js-gallery--media').slick({
    //            dots: false,
    //            arrows: false,
    //            responsive: [
    //                {
    //                    breakpoint: 768,
    //                    settings: {
    //                        dots: true,
    //                        arrows: false
    //                    }
    //                }

    //            ]
    //        });
    //    }
    //}

    initializeSlider();
    //ImageHoverFunctionality
    hideShowProducts();
    $('.eu-product-card .grid-item__media').each(function () {
        var $secondImg = $(this).find('img:nth-child(2)');
        if ($secondImg.length) {
            $(this).hover(function () {
                $(this).find('img:nth-child(1)').toggle();
                $secondImg.toggle();
            });
        }
    });

    var categoryList = $(".eu-category-list");


    if ($(".unfiltered-grid").length > 10) {
        $(".pagination-container").css("display", "block");
        $(".products-more").css("display", "block");
    }


    $(".eu-category-list ul li a").on("click", function () {
        $("ul li a").removeClass("active");
        $(this).addClass("active");
        //e.preventDefault();
        if ($(window).width <= 991) {
            var selectedMenu = $(this).text();
            $(".eu-selected-product").html(selectedMenu);
            $("ul").hide("100");
        }
    });

    var navElement = $('nav.eu-series-hdr');
    if (navElement.length > 0) {
        navElement.removeClass('eu-series-hdr');
        var headerElement = navElement.closest('header.primary-nav__header');
        if (headerElement.length > 0) {
            headerElement.addClass('eu-series-hdr');
        }
    }

    stickyInpageNav();

    //$(".eu-techincal-feature").hide();
    //$(".technical-data").prepend('<h2 class="eu-techincal-feature-inner">Technical Feature</h2>');

    //add full-width class if there is no content/image in 2nd column div
   /* if ($(".wattseu .grid-container").length) {
        if ($(".wattseu .grid-container.description-details .grid-container__one-half:last-child").is(':empty') || $(".wattseu .grid-container.description-details .grid-container__one-half:last-child .general-content").is(':empty') || $(".wattseu .grid-container.description-details .grid-container__one-half:last-child .general-content").html().trim() === '' || $(".wattseu .grid-container.description-details .grid-container__one-half:last-child").html().trim() === '') {
            $(".wattseu .grid-container.description-details").addClass("eu-full-width-column");
        } else {
            $(".wattseu .grid-container.description-details").removeClass("eu-full-width-column");
        }
    }*/
    if ($(".wattseu .grid-container.description-details").length) {
        if ($(".wattseu .grid-container.description-details .grid-container__one-half:last-child").is(':empty') ||
            $(".wattseu .grid-container.description-details .grid-container__one-half:last-child").html().trim() === '' ||
            !$(".wattseu .grid-container.description-details .grid-container__one-half:last-child .general-content").length ||
            $(".wattseu .grid-container.description-details .grid-container__one-half:last-child .general-content").is(':empty') ||
            $(".wattseu .grid-container.description-details .grid-container__one-half:last-child .general-content").html().trim() === '') {

            $(".wattseu .grid-container.description-details").addClass("eu-full-width-column");
        } else {
            $(".wattseu .grid-container.description-details").removeClass("eu-full-width-column");
        }
    }


    $(".eu-selected-product").click(function () {
        var ulSelectedList = $(".eu-category-list ul");
        if (ulSelectedList.css('display') === 'none') {
            ulSelectedList.css('display', 'block');
        } else {
            ulSelectedList.css('display', 'none');
        }
    });


    //StickyLeftNav-CategoriesPage
    //var sidebar = $(".eu-category-list.eu-catalog");
    //if (sidebar.length > 0) {
    //    var content = $(".unfiltered-grid.eu-product-card");
    //    var sidebarOffset = sidebar.offset().top;
    //    $(window).scroll(function () {
    //        var scrollPos = $(window).scrollTop();
    //        var contentHeight = content.height();
    //        var windowHeight = $(window).height();
    //        if (scrollPos >= sidebarOffset && scrollPos + windowHeight < sidebarOffset + contentHeight) {
    //            sidebar.addClass("sticky");
    //        } else {
    //            sidebar.removeClass("sticky");
    //        }
    //    });
    //}



});
//$('.eu-mobile-seriesUsedConjuction .eu-acc-header').on('click', function () {
//    if ($(this).hasClass('active')) {
//        updateSliderSettings();
//    }
//});
//function stickyInpageNav() {
//    if ($(".eu-inpage-sticky-navigation").length) {
//        var inpageContainer = document.querySelector(".eu-inpage-sticky-navigation");
//        var inpageContainerMobile = document.querySelector(".eu-inpage-mobile-navigation");
//        var stickyInpageClass = "sticky-inpage-nav";
//        var stickyInpageClassMobile = "sticky-nav-mobile";
//        var currentScroll = window.pageYOffset;
//        if (currentScroll > 500) {
//            if ($(".eu-inpage-sticky-navigation").length) {
//                inpageContainer.classList.add(stickyInpageClass);
//                if (inpageContainerMobile != null) {
//                    inpageContainerMobile.classList.add(stickyInpageClassMobile);
//                }

//                if ($(window).width() > 1199) {
//                    $(".primary-nav__header").hide();
//                }
//            }
//        } else {
//            if ($(".eu-inpage-sticky-navigation").length) {
//                inpageContainer.classList.remove(stickyInpageClass);
//                if (inpageContainerMobile != null) {
//                    inpageContainerMobile.classList.add(stickyInpageClassMobile);
//                }
//            }
//            $(".primary-nav__header").show();
//        }

//        if ($(window).width() < 991) {
//            //$(".wattseu .primary-nav__header").addClass("sticky-nav");
//            if (currentScroll > 150) {
//                if (inpageContainerMobile != null) {
//                    inpageContainerMobile.classList.add(stickyInpageClassMobile);
//                }
//            } else {
//                if (inpageContainerMobile != null) {
//                    inpageContainerMobile.classList.add(stickyInpageClassMobile);
//                }
//            }
//        }
//    }
//}

function stickyInpageNav() {
    if ($(".eu-inpage-sticky-navigation").length) {
        var inpageContainer = document.querySelector(".eu-inpage-sticky-navigation");
        var inpageContainerMobile = document.querySelector(".eu-inpage-mobile-navigation");
        var stickyInpageClass = "sticky-inpage-nav";
        var stickyInpageClassMobile = "sticky-nav-mobile";
        var currentScroll = $(window).scrollTop();
        var eTop = $('.eu-inpage-sticky-navigation').offset().top;
        var elemPosition = eTop - $(window).scrollTop();
        var headerHeight = $(".primary-nav__header").outerHeight();

        if ($(window).width() > 991) {

            if (inpageContainerMobile != null) {
                inpageContainerMobile.classList.add(stickyInpageClassMobile);
            }

            if (elemPosition < headerHeight) {
                inpageContainer.classList.add(stickyInpageClass);
                //$('.eu-inpage-sticky-navigation').before($('<div class="placeholder-block"></div>'));
            } else {
                inpageContainer.classList.remove(stickyInpageClass);
                //$(".placeholder-block").remove();
            }
            if (currentScroll < 200) {
                $(".eu-inpage-sticky-navigation").removeClass('sticky-inpage-nav');
            }

        } else {
            if (inpageContainerMobile != null) {
                inpageContainerMobile.classList.add(stickyInpageClassMobile);
            }
        }

        if ($(window).width() > 1199) {
            if (elemPosition < 90) {
                //$(".primary-nav__header").hide();
                $(".primary-nav__header").css("visibility", "hidden");
            } else {
                //$(".primary-nav__header").show();
                $(".primary-nav__header").css("visibility", "visible");
            }
        }
    }
}
$(window).on("resize", function () {
    stickyInpageNav();
});

$(window).on("scroll", function () {
    stickyInpageNav();
});

$(".eu-mobile-inpage-nav-link").on("click", function () {
    $(this).attr("tabindex", "0");
    $(this).siblings(".plus-icon").attr("tabindex", "0");
    $(".eu-inpage-sticky-navigation").addClass("sticky-inpage-nav");
    $(this).siblings(".eu-inpage-detail-tabs").toggle();
    $(this).siblings(".plus-icon").toggleClass("active");
    updateAriaLabel($(this).siblings(".plus-icon"));
});

$(".plus-icon").on("click", function () {
    $(this).attr("tabindex", "0");
    $(".eu-inpage-sticky-navigation").addClass("sticky-inpage-nav");
    $(this).siblings(".eu-inpage-detail-tabs").toggle();
    $(this).toggleClass("active");
    updateAriaLabel($(this));
});

function updateAriaLabel(element) {
    if (element.hasClass("active")) {
        element.attr("aria-label", "close jump menu");
    } else {
        element.attr("aria-label", "open jump menu");
    }
}


$(".inpage-nav-link").click(function (e) {
    e.preventDefault();
    var targetSection = $(this).attr("href");
    var targetOffsetTop;
    if ($('.eu-inpage-sticky-navigation').hasClass('sticky-inpage-nav')) {
        targetOffsetTop = $(targetSection).offset().top - $('.eu-inpage-sticky-navigation').outerHeight(true);
    } else {
        targetOffsetTop = $(targetSection).offset().top - $('.eu-inpage-sticky-navigation').outerHeight(true) - 144;
    }
       
    $("html, body").animate({
        scrollTop: targetOffsetTop
    }, 400);

    if ($(window).width() < 991) {
        $(this).parent(".eu-inpage-detail-tabs").hide();
    }
});

$(".eu-ask-question-link").click(function () {
    $('#feedbackModal').on('shown.bs.modal', function () {
        var interestFieldTxt = $(".short-description--series").text();

        $("#feedbackModal .interested-product-field").removeClass("d-none");
        $("#feedbackModal .interested-product-field .feedback-input").val(interestFieldTxt);
        $("#feedbackModal .interested-product-field .feedback-label").addClass("active");
    });
});

$(window).on("load", function () {
    var currentUrl = window.location.href;

    if ($("body").hasClass("wattseu")) {
        if (!currentUrl.endsWith('/eu')) {
            $(".unfiltered-grid").removeClass("eu-product-main-card");
        }
        else {
            $(".unfiltered-grid").addClass("eu-product-main-card");
        }
    }

    var selectedCategoryItem = $(".eu-category-link.active").text();
    $(".eu-selected-product").text(selectedCategoryItem);


});


//GalleryMobileScript
function initializeSlider() {
    
    if ($("body").hasClass("wattseu") && $(".eu-series-gallery").length) {
        if ($(window).width() <= 768) {

            $('.horizontal-separator-small').css('display', 'none');
            $('.js-gallery--thumbnails').css('display', 'none');
            var galleryMediaElement = $('.js-gallery--media');

            if (galleryMediaElement && galleryMediaElement.length > 0) {
                //galleryMediaElement.slick('unslick'); // Unslick if already initialized

                //if ($('.js-gallery--media').hasClass('slick-initialized')) {
                //    $('.js-gallery--media').slick('unslick');
                //}

                //$('.js-gallery--media').slick({
                //    arrows: false,
                //    dots: true
                //});

                // Create and append custom arrows dynamically
                var customArrowsContainer = $('<div class="arrows-container"></div>');
                var prevArrow = $('<div class="slider-button-prev"></div>');
                var nextArrow = $('<div class="slider-button-next"></div>');

                customArrowsContainer.append(prevArrow).append(nextArrow);
                galleryMediaElement.after(customArrowsContainer);

                prevArrow.on("click", function () {
                    galleryMediaElement.slick("slickPrev");
                });

                nextArrow.on("click", function () {
                    galleryMediaElement.slick("slickNext");
                });

                galleryMediaElement.on("beforeChange", function (event, slick, currentSlide, nextSlide) {
                    if (nextSlide == slick.slideCount - 1) {
                        prevArrow.removeClass("slider-button-disabled");
                        nextArrow.addClass("slider-button-disabled");
                    } else if (nextSlide === 0) {
                        prevArrow.addClass("slider-button-disabled");
                        nextArrow.removeClass("slider-button-disabled");
                    } else {
                        // Enable both buttons if not on the first or last slide
                        prevArrow.removeClass("slider-button-disabled");
                        nextArrow.removeClass("slider-button-disabled");
                    }
                });

                galleryMediaElement.on("init", function (event, slick) {
                    if (slick.currentSlide == 0) {
                        prevArrow.addClass("slider-button-disabled");
                        nextArrow.removeClass("slider-button-disabled");
                    }
                });



                // Initialize Slick with custom arrows and dots
                //galleryMediaElement.slick({
                //    dots: false,
                //    arrows: false,
                //});

                // Create custom dots dynamically
                var customDotsContainer = $('<ul class="custom-dots"></ul>');

                if (!$('ul.custom-dots').length) {
                    
                    galleryMediaElement.find('.slick-slide:not(.slick-cloned)').each(function (index) {
                        customDotsContainer.append('<li class="custom-dots"></li>');
                    });

                    // Append custom dots to the specified container
                    $('.js-gallery--media').append(customDotsContainer);

                    // Function to update active dot based on current slide
                    function updateActiveDot(slick, index) {
                        customDotsContainer.find('.custom-dots').removeClass('slick-active');
                        customDotsContainer.find('.custom-dots:eq(' + index + ')').addClass('slick-active');
                    }

                    // Handle beforeChange event to update active dot
                    galleryMediaElement.on("beforeChange", function (event, slick, currentSlide, nextSlide) {
                        updateActiveDot(slick, nextSlide);
                    });

                    // Set the first dot as active initially
                    updateActiveDot(galleryMediaElement.slick("getSlick"), 0);


                    // Prevent click on dots
                    galleryMediaElement.on("click", ".custom-dots", function (event) {
                        event.preventDefault();
                    });
                } else {
                    $('.custom-dots').css('display', 'flex');
                }
               
            }
        } else {

            $('.horizontal-separator-small').css('display', 'block');
            $('.js-gallery--thumbnails').css('display', 'block');
            $('.custom-dots').css('display', 'none');
            $('.arrows-container').css('display', 'none');
        }
    }
}

// Attach the function to the resize event
$(window).on('resize', function () {
    //console.log("window resized");
    initializeSlider();
    hideShowProducts();
});


function hideShowProducts() {
    // Description
    var paragraphs = $('.seriesDescription .product__additional-details p');
    var hasText = false;
    paragraphs.each(function () {
        if ($(this).text().trim() !== '') {
            hasText = true;
            return false;
        }
    });

    if (hasText) {
        $('.series-desc-title h2').show();
        $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".series-desc-title"]').show();
    } else {
        $('.series-desc-title h2').hide();
        $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".series-desc-title"]').hide();
    }

    //Technical Features
    let $technicalFeatureLabel = $('.technical-feature-label');
    if ($technicalFeatureLabel.length === 0) {
        $('.eu-techincal-feature-inner').hide();
        let getTechnicalLabel = $('.eu-techincal-feature-inner').text();
        $(".technical-data").before('<h2 class="technical-feature-label" style="margin-top: 30px;">' + getTechnicalLabel + '</h2>');
    }
    if ($(".seriesDescription .technical-data").text().trim() === '') {
        $('.technical-feature-label').hide();
        $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".technical-feature-label"]').hide();
    } else {
        $('.technical-feature-label').show();
        $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".technical-feature-label"]').show();
    }

    //Part No
    if ($(".seriesPartno .content-width").find("table").length === 0) {
        $('.series-partno-title h2').hide();
        $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".series-partno-title"]').hide();
    } else {
        $('.series-partno-title h2').show();
        $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".series-partno-title"]').show();
    }

    //Case Studies
    if ($(window).width() <= 767) {
        $(".eu-inpage-detail-tabs a.inpage-nav-link[href='.seriesCaseStudies']").attr('href', '.eu-mobile-casestudies');
        if ($(".eu-mobile-casestudies .eu-docs-list-items .eu-accordion-list .eu-acc-body").text().trim().length === 0) {
            $(".eu-mobile-casestudies").hide();
            $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".eu-mobile-casestudies"]').hide();
        } else {
            $(".eu-mobile-casestudies").show();
            $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".eu-mobile-casestudies"]').show();
        }
    } else {
        $(".eu-inpage-detail-tabs a.inpage-nav-link[href='.eu-mobile-casestudies']").attr('href', '.seriesCaseStudies');
        let isCaseStudiesEmpty = true;
        $(".seriesCaseStudies .featured-news-feed .featured-news-feed__item").each(function () {
            let content = $(this).text().trim();
            if (content.length > 0) {
                isCaseStudiesEmpty = false;
                return false;
            }
        });
        if (isCaseStudiesEmpty) {
            $(".seriesCaseStudies .featured-news-feed__headline").hide();
            $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".seriesCaseStudies"]').hide();
        } else {
            $(".seriesCaseStudies .featured-news-feed__headline").show();
            $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".seriesCaseStudies"]').show();
        }
    }

    //Downloads
    if ($(".eu-technical-docs .eu-docs-list-items .eu-accordion-list .eu-acc-body").text().trim().length === 0) {
        $(".download-pdf-search").hide();
        $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".download-pdf-search"]').hide();
    } else {
        $(".download-pdf-search").show();
        $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".download-pdf-search"]').show();
    }

    // Related Products
    if ($(window).width() <= 767) {
        if ($('.eu-mobile-seriesSimilarAlt .new-related-products-cmp .slick-slide').text().trim().length === 0 &&
            $('.eu-mobile-seriesUsedConjuction .new-related-products-cmp .slick-slide').text().trim().length === 0) {
            $(".series-related-products-title").hide();
            $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".series-related-products-title"]').hide();
        } else {
            $(".series-related-products-title").show();
            $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".series-related-products-title"]').show();
        }
    } else {
        if ($('.seriesSimilarAlt .new-related-products-cmp .slick-slide').text().trim().length === 0 &&
            $('.seriesUsedConjuction .new-related-products-cmp .slick-slide').text().trim().length === 0) {
            $(".series-related-products-title").hide();
            $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".series-related-products-title"]').hide();
        } else {
            $(".series-related-products-title").show();
            $('.eu-inpage-detail-tabs a.inpage-nav-link[href=".series-related-products-title"]').show();
        }
    }
}