function fullScreen(fsButton) {
    fsButton.on('click', function(e) {
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {

            if ($(e.currentTarget).parents()[3].requestFullscreen) {
                $(e.currentTarget).parents()[3].requestFullscreen();
            } else if ($(e.currentTarget).parents()[3].mozRequestFullScreen) {
                $(e.currentTarget).parents()[3].mozRequestFullScreen();
            } else if ($(e.currentTarget).parents()[3].webkitRequestFullscreen) {
                $(e.currentTarget).parents()[3].webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if ($(e.currentTarget).parents()[3].msRequestFullscreen) {
                $(e.currentTarget).parents()[3].msRequestFullscreen();
            }

            fsButton.addClass('active');

        } else {

            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }

            fsButton.removeClass('active');
        }
    }); // FULLSCREEN
}
export {fullScreen};