import * as hBars from 'handlebars/dist/handlebars';
import {calcChooser} from './_calc-chooser.js';
import {getKwh} from './_get-kws.js';
import {getSqFeet} from './_get-sq-feet.js';
import {getOperatingTime} from './_get-operating-time.js';
import {getEDOC} from './_get-edoc.js';
import {each} from 'lodash-es';
import {orderBy} from 'lodash-es';

$.each($('.js-suntouch-calculator'), function(index, container) {
    const $container = $(container);

    /* Object to store initial values-----------------------------------------------*/
    const initialValues = {
        kwh: 13,
        chosenProduct: '',
        sqFeetInput: $container.find('.js-calculator-square-feet'),
        sqFeet: 0,
        EDOC: 0,
        subFloor: 'concrete',
        operatingTimeInput: $container.find('.js-subfloor'),
        operatingTime: 8
    };
    
    initialValues.sqFeetInput.on('keypress', function(event){
        if (event.key.length === 1 && isNaN(event.key) && !event.ctrlKey) {
            event.preventDefault();
        }
    });

    /* Inital Calculator------------------------------------------------------------//
                Here, we figure out which product we are going to quote             //
    --------------------------------------------------------------------------------*/
    $container.delegate('.js-calculator-form-one', 'submit', function(event) {
        event.preventDefault();
        $('.js-select-result').addClass('is-hidden');
        initialValues.chosenProduct = calcChooser(event.currentTarget).toLowerCase();
        $('.' + initialValues.chosenProduct).removeClass('is-hidden');
    });

    /* Calculator Step Two----------------------------------------------------------//
                            Here we update everything                               //
    --------------------------------------------------------------------------------*/
    const $cents = $container.find('.js-calculated-value');
    if ($cents.length > 0) {
        $cents.html($('.js-calculated-value').html().replace(/{(.*?)}/, '<span class="cents"></span>'))        
    }
    
    const $resultsBar = $container.find('.js-calculator-results-bar');
    const $productResults = $container.find('.js-product-results');

    $container.find('.js-calculator-form-two').on('focus', 'input, select', function(e) {
        $resultsBar.addClass('is-hidden');
        $productResults.addClass('is-hidden');        
    });
    
    $container.delegate('.js-calculator-form-two', 'submit', function(e) {
        e.preventDefault();
        $('[class*=js-calculator-error]').addClass('is-hidden');
        $resultsBar.addClass('is-hidden');
        $productResults.addClass('is-hidden');
        initialValues.sqFeet = getSqFeet(initialValues.sqFeetInput);
        const ft2 = initialValues.sqFeetInput.val();

        if (ft2 < 12) {
            $('.js-calculator-error--areatoosmall').removeClass('is-hidden');
        } else if (ft2 > 3600) {
            $('.js-calculator-error--areatoolarge').removeClass('is-hidden');            
        } else {
            initialValues.productList = $.extend(true, {}, calculatorProducts); // do not change the "real" list of products...
            initialValues.kwh = getKwh(initialValues.kwh);
            initialValues.operatingTime = getOperatingTime(initialValues.operatingTimeInput);
            initialValues.EDOC = getEDOC(initialValues);
            $resultsBar.find('span.cents').text(initialValues.kwh);
            $resultsBar.find('.js-square-footage-total').text(initialValues.sqFeet);
            $resultsBar.find('.js-operating-cost-total').text(`$${initialValues.EDOC}`);

            if ($container.find('[name="quoteKit"]').val() === "Yes") {
                getKits();                
            } else {
                $container.find('.js-calculator__results--kits, .calculator__heading--kits').addClass('is-hidden');
            }
            
            getProducts();
            getControls();
            getAccessories();            

            $resultsBar.removeClass('is-hidden');
            $productResults.removeClass('is-hidden');
            $('html, body').animate({
                scrollTop: $('.js-calculator-results-bar').offset().top
            }, 1000);
        }
    });


    /* KWH Calculator---------------------------------------------------------------//
                  Here we are going to update the inital value for kwh,             //
                            and show / hide remaining forms                         //
    --------------------------------------------------------------------------------*/

    /* -------------------------------------------------------Show hide the KWH form*/
    $container.delegate('.js-change-kwh', 'click', function(e) {
        e.preventDefault();
        $(e.currentTarget).parents('.js-operating-cost').addClass('is-hidden').siblings('.js-kwh-form-container').removeClass('is-hidden');
    });


    /* -----------------------------------------------------Update the KWH on submit*/
    $container.delegate('.js-kwh-calc', 'submit', function(e) {
        e.preventDefault();
        const $container = $(e.currentTarget).parents('.js-calculator-results-bar');
        initialValues.kwh = getKwh(initialValues.kwh);
        initialValues.sqFeet = getSqFeet(initialValues.sqFeetInput);
        initialValues.EDOC = getEDOC(initialValues);

        $container.find('span.cents').text(initialValues.kwh);
        $container.find('.js-operating-cost-total').text(`$ ${initialValues.EDOC}`);
        $container.find('.js-square-footage-total').text(initialValues.sqFeet);

        $(e.currentTarget).parents('.js-kwh-form-container').addClass('is-hidden');
        $(e.currentTarget).parents('.js-kwh-form-container').siblings('.js-operating-cost').removeClass('is-hidden');
    });

    /* Kits checkbox------------------------------------------------//
        --------------------------------------------------------------------------------*/
    $container.on('click', '.js-kits-checkbox', function() {
        if ($(event.target).prop('checked') === true) {
            $('input:checkbox').not('.js-kits-checkbox').prop('checked', false);
            $('input:radio').prop('checked', false);
            setPrintClasses();
        }
    });

    /* Print list------------------------------------------------//
        --------------------------------------------------------------------------------*/
    const setPrintClasses = function() {
        $container.find('input:checkbox, input:radio').each(function(index, element) {
            if ($(element).prop('checked')) {
                $(element).parents('.calculator-product-row').removeClass('calculator-print-hidden');
            } else {
                $(element).parents('.calculator-product-row').addClass('calculator-print-hidden');
            }
        });
    };
    $('.print-shoppinglist').on('click', function(event) {
        event.preventDefault();
        window.print();
    });
    $container.on('click', 'input:checkbox, input:radio', function() {
        setPrintClasses();
    });


    setPrintClasses();

    /* Display items------------------------------------------------//
        --------------------------------------------------------------------------------*/
    const trimProducts = function(context) {
        $.each(context, function(index, object) {
            if (typeof object !== "undefined" && object.Quantity === 0) {
                delete context[index];
            }
        });
    };
    const displayItems = function(context, $section) {
        $section.empty();
        const $htmlSource = $container.find('.js-results-template').html();
        const template = hBars.compile($htmlSource);
        $section.append(template(context));
    };
    /* Determine products to display------------------------------------------------//
        --------------------------------------------------------------------------------*/
    const calculateItems = function(area, item, coverage) {
        const remainder = area % coverage;
        if (area !== remainder) {
            item.Quantity = Math.floor(area / coverage);
        } else {
            item.Quantity = 0;
        }
        return remainder;
    };

    const calculateHeatMatrix = function() {
        let area = initialValues.sqFeetInput.val() * 1.2;
        const products = initialValues.productList.HeatMatrix;
        
        each(products, item => item.Coverage = parseInt(item.Coverage, 10));
        const orderedList = orderBy(products, 'Coverage', 'desc');

        $.each(orderedList, function(index, item) {
            if (typeof item !== "undefined") {
                if(item.Coverage) {
                    area = calculateItems(area, item, item.Coverage);                    
                } else {
                    item.Quantity = 0;
                }
            }
        });
    };
    const calculateRelay = function() {
        let needRelay = false;
        const voltage = $container.find('[name="voltages"]').val();
        const spacing = $container.find('[name="spacing"]').val();
        const area = initialValues.sqFeet;

        switch (voltage) {
            case "120":
                if (area > 180 || (spacing === "3" && area > 150) || (spacing === "2.5" && area > 126)) {
                    needRelay = true;
                }
                break
            case "240":
                if (area > 360 || (spacing === "3" && area > 300) || (spacing === "2.5" && area > 253)) {
                    needRelay = true;
                }
        };

        if (needRelay) {
            initialValues.productList.RelayIII.Quantity = Math.ceil(initialValues.sqFeet / 300);
        } else {
            initialValues.productList.RelayIII.Quantity = 0;
        }
    };

    const getKits = function() {
        const spacing = $('[name="spacing"]').val();
        const voltage = $container.find('[name="voltages"]').val();
        const area = initialValues.sqFeet;
        const errorSelector = `.js-calculator-error--nokit${voltage}`;
        let volterror = false;

        switch (voltage) {
            case "120":
                if (area > 172) {
                    volterror = true;
                }
                break
            case "240":
                if (area > 501) {
                    volterror = true;
                }
        };
        if (volterror) {
            $('.calculator__heading--kits, .calculator__results--kits, .js-kits-heading').addClass('is-hidden');
            $('.js-nokits-heading').removeClass('is-hidden');
            $(errorSelector).removeClass('is-hidden');
        } else {
            let kits = false;
            let lower = 0;
            let upper = area;
            let category = '';

            $container.find('.calculator-form-two').data('tapemat') ? category = "TapeMat" : category = "WarmWire";
            
            $.each(initialValues.productList.Kits, function(index, item){
                let coverage = '';
                let cleanSpacing;
                
                if (spacing) {
                    cleanSpacing = spacing.replace(/\./g,'point');
                }

                if (category === "WarmWire") {
                    const selector = `TotalSquareFootage${ cleanSpacing}inOnCenter`;
                    coverage = item[selector];                    
                } else {
                    coverage = item.Coverage
                }

                if(!(coverage >= lower && coverage <= upper && item.Voltage === voltage)) {
                    delete initialValues.productList.Kits[index];
                }
            });            

            initialValues.productList.Kits = initialValues.productList.Kits.filter(function () { return true });
            const items = initialValues.productList.Kits.map(o => parseInt(o.Coverage));
            const largest = Math.max(...items, 0);            

            $.each(initialValues.productList.Kits, function(index, item){
                if(parseInt(item.Coverage) !== largest) {
                    delete initialValues.productList.Kits[index];
                } else {
                    item.Quantity = 1;
                }
            });            

            if (initialValues.productList.Kits.length > 0) {
                $('.calculator__heading--kits, .calculator__results--kits, .js-kits-heading').removeClass('is-hidden');
                $('.js-nokits-heading').addClass('is-hidden');
        
                displayItems(initialValues.productList.Kits, $container.find('.js-calculator__results--kits'));            
            } else {
                $('.calculator__heading--kits, .calculator__results--kits, .js-kits-heading').addClass('is-hidden');
                $('.js-calculator-error--nokitarea, .js-nokits-heading').removeClass('is-hidden');
            }            
        }
    };
    const getProducts = function() {
        const voltage = $container.find('[name="voltages"]').val();
        const spacing = $container.find('[name="spacing"]').val();
        let area = initialValues.sqFeet;
        let category = '';
        let cleanSpacing;
        
        if (spacing) {
            cleanSpacing = spacing.replace(/\./g,'point');
        }
        
        $container.find('.calculator-form-two').data('tapemat') ? category = "TapeMat" : category = "WarmWire";

        const products = initialValues.productList[category];
        each(products, item => item.Coverage = parseInt(item.Coverage, 10));
        const orderedList = orderBy(products, 'Coverage', 'desc');
        
        $.each(orderedList, function(index, item) {
            if (item.Voltage == voltage && typeof item !== "undefined") {
                if (category === "WarmWire") {
                    const selector = `TotalSquareFootage${ cleanSpacing}inOnCenter`;
                    area = calculateItems(area, item, item[selector]);                    
                } else {
                    area = calculateItems(area, item, item.Coverage);                   
                }
            } else {
                item.Quantity = 0;
            }
        });

        trimProducts(orderedList);
        displayItems(orderedList, $container.find('.js-calculator__results--products'));
    };
    const getControls = function() {
        $.each(initialValues.productList.Controls, function(index, item){
            item.Quantity = 1;
            item.control = true;
        });
        displayItems(initialValues.productList.Controls, $container.find('.js-calculator__results--controls'));
    };
    const getAccessories = function() {
        // these items are always present with quantity = 1
        if (initialValues.productList.CableTrowel) {
            initialValues.productList.CableTrowel.Quantity = 1;            
        }
        if (initialValues.productList.LoudMouth) {
            initialValues.productList.LoudMouth.Quantity = 1;            
        }
        if (initialValues.productList.SunstatSensor) {
            initialValues.productList.SunstatSensor.Quantity = 1;
        }
        if (initialValues.productList.ConnectPlusSmartSensor) {
            initialValues.productList.ConnectPlusSmartSensor.Quantity = 1;
        }     
        if (initialValues.productList.RelayIII) {
            calculateRelay();
        }
        
        const context = [initialValues.productList.CableTrowel, initialValues.productList.LoudMouth, initialValues.productList.SunstatSensor, initialValues.productList.ConnectPlusSmartSensor, initialValues.productList.RelayIII]

        if (initialValues.productList.HeatMatrix) {
            calculateHeatMatrix();
            each(initialValues.productList.HeatMatrix, item => item.Coverage = parseInt(item.Coverage, 10));
            initialValues.productList.HeatMatrix = orderBy(initialValues.productList.HeatMatrix, 'Coverage', 'asc');

            $.each(initialValues.productList.HeatMatrix, function(index, item){
                context.unshift(item);
            });
        }
        if (initialValues.productList.DoubleSidedTape) {
            initialValues.productList.DoubleSidedTape.Quantity = Math.ceil(initialValues.sqFeet / 120); 
            context.push(initialValues.productList.DoubleSidedTape);           
        }
        if (initialValues.productList.CableStrap) {
            initialValues.productList.CableStrap.Quantity = Math.ceil(initialValues.sqFeet / 40);            
            context.push(initialValues.productList.CableStrap);           
        }

        trimProducts(context);
        displayItems(context, $container.find('.js-calculator__results--accessories'));
    };
    /* END each suntouch calculator*/
});