import { sanitize } from './_sanitize.js';
import { getUrlParameter } from './_get-url-parameter.js';
import { newLoc } from './_geolocation.js';
import * as hBars from 'handlebars/dist/handlebars';
import { mapColor } from './_map-color';
import './how-to-buy-international';
import { stringInject } from './_stringInject.js';

$.each($('.js-how-to-buy'), function (index, buyContainer) {

    $('.js-how-to-buy__target').addClass('is-hidden');

    /* To hide load more and hide map button when the page loads */
    $(function () {
        $('.js-load-more__container').addClass('is-hidden');
        $('.how-to-buy__hide').addClass('is-hidden');
        //$('.js-pagination_list').addClass('is-hidden');
    });

    const $target = $(buyContainer).find('.how-to-buy__template-wrapper');
    const $targetForm = $(buyContainer).find(".js-how-to-buy__selector");
    const htbSrc = $(buyContainer).find('.how-to-buy__template').html();
    const directionsText = $(buyContainer).find('.js-how-to-buy__directions-text').val();
    let getLocation = getUrlParameter('Location');
    if (getLocation) {
        getLocation = sanitize(getLocation);
        /* Give full state names for Indiana and Oregon states so that  google api service returns proper results.*/
        if (getLocation.toUpperCase() === "IN") getLocation = "Indiana";
        if (getLocation.toUpperCase() === "OR") getLocation = "Oregon";
    }
    const gotSubType = getUrlParameter('SubType');
    const gotCategory = getUrlParameter('Category');
    const gotBrand = getUrlParameter('Brand');
    const gotCountry = getUrlParameter('Country');
    const bounds = new google.maps.LatLngBounds();
    let agentArray = [];
    let infoWindow;
    let currentLocation;
    let getInput;

    /* Get the right base color for the map */
    const brandName = $(document.body).attr('class');
    const mapBackground = mapColor(brandName);

    // validateSuggest($(buyContainer).find('.js-how-to-buy__selector'));

    /* CHECK FOR DATA FROM THE URL----------------------------------------------*/

    if (gotSubType !== null && gotSubType !== '' && typeof gotSubType !== 'undefined') {
        $(buyContainer).find('input:radio').each(function (index, radio) {
            if ($(radio).attr('value') === gotSubType) {
                $(radio).attr('checked', 'checked');
            }
        });
    }

    if (gotCategory) {
        $(buyContainer).find('.how-to-buy__category option').each(function (index, element) {
            if ($(element).attr('value').toLowerCase() === gotCategory.toLowerCase()) {
                $(element).prop('selected', 'selected');
            }
        });
    }

    if (gotBrand) {
        $(buyContainer).find('.how-to-buy__brand option').each(function (index, element) {
            if ($(element).attr('value').toLowerCase() === gotBrand.toLowerCase() || $(element).text().toLowerCase().trim() === gotBrand.toLowerCase().trim()) {
                $(element).prop('selected', 'selected').parent('select').trigger('change');
            }
        });
    }

    if (gotCountry) {
        $(buyContainer).find('.how-to-buy__country option').each(function (index, element) {
            if ($(element).attr('value').toLowerCase() === gotCountry.toLowerCase()) {
                $(element).prop('selected', 'selected').parent('select').trigger('change');
            }
        });
    }

    if (getLocation) {
        $(buyContainer).find('.how-to-buy__location-input').each(function (index, element) {
            $(element).val(sanitize(getLocation));
        });
    }

    /* NO RESULTS---------------------------------------------------------------*/
    const noResults = function () {
        $(buyContainer).find('.js-how-to-buy__no-results').addClass('is-visible');
        $(buyContainer).find('.js-how-to-buy__target').addClass('is-hidden');
        $(buyContainer).find('.loader-wrapper').fadeOut('fast');
        $(buyContainer).find('.js-showing-results__container').addClass('is-hidden');
        $(buyContainer).find('.js-pagination-showing-results__container').addClass('is-hidden');
    };

    /* DEFAULT RESULTS----------------------------------------------------------*/
    const defaultResults = function () {
        $(buyContainer).find('.js-how-to-buy__default-results').addClass('is-visible');
    };

    /* MULTIPLE PAGES-----------------------------------------------------------*/
    const multiplePages = function () {
        $(buyContainer).find('.js-how-to-buy__multiple-pages').addClass('is-visible');
    };

    /* Create the map-----------------------------------------------------------//
    //--------------------------------------------------------------------------*/
    const initMap = function (jsonArray, mapCenter) {

        const image = {
            path: 'M24,12 c0-6.6-5.4-12-12-12S0,5.4,0,12c0,1.8,0.4,3.4,1.1,4.9h0L12,40l10.9-23.1h0C23.6,15.4,24,13.8,24,12z',
            fillColor: mapBackground,
            fillOpacity: .9,
            scale: 1,
            strokeColor: 'transparent',
            strokeWeight: 0,
            labelOrigin: new google.maps.Point(11, 12),
            anchor: new google.maps.Point(12, 40)
        };

        const markerArray = jsonArray;
        let windowTitle;
        let windowAgentType;
        let windowPosition;
        let newMarker;
        let markerId;
        let agentId;
        let agentType;
        let agentName;
        let agentSecondaryName;
        let position;
        let agentAddress;
        let agentCity;
        let agentState;
        let agentZip;
        let agentCountry;
        let agentPhoneNumber;
        let agentEmailAddress;
        let agentWebsiteAddress;
        let agentCertIcon;


        /* Draw the map---------------------------------------------------------//
        //                 Zoom, Center, and Styling required.                  //
        //----------------------------------------------------------------------*/
        const $mapClass = $(buyContainer).find('.js-how-to-buy__map-target');

        const map = new google.maps.Map($mapClass[0], {
            zoom: 8,
            center: mapCenter,
            styles: [{
                "stylers": [{
                    "hue": `${mapBackground}`
                }, {
                    "saturation": 89
                }]
            }, {
                "featureType": "water",
                "stylers": [{
                    "color": "#EDEDED"
                }]
            }, {
                "featureType": "administrative.country",
                "elementType": "labels",
                "stylers": [{
                    "visibility": "off"
                }]
            }]
        });

        /* RECENTER ON CLICK----------------------------------------------------//
        //        This will recenter the map to the link that is created.       //
        //----------------------------------------------------------------------*/
        $.each($(buyContainer).find('.js-how-to-buy__map-link'), function (index, link) {

            const mapPosition = $(link).attr('data-position');
            const mapWindow = $(link).attr('data-id').toString();

            $(link).click(function (event) {
                event.preventDefault();
                const temPosArray = mapPosition.split(',');
                const tempLat = parseFloat(temPosArray[0]);
                const tempLng = parseFloat(temPosArray[1]);
                const myLatLng = new google.maps.LatLng(tempLat, tempLng);
                map.panTo(myLatLng);
            });
        });

        let markerHolder = [];

        /* Loop throught the array, make the markers, and variables-------------//
        //----------------------------------------------------------------------*/
        for (let i = 0; i < markerArray.length; i++) {

            const mapLat = parseFloat(markerArray[i].Position.Lat);
            const mapLng = parseFloat(markerArray[i].Position.Lng);

            /* ---------(Create bounds to set proper zoom for available markers)*/
            const newBounds = new google.maps.LatLng(mapLat, mapLng);
            bounds.extend(newBounds);

            /* -----------------------------------------------(Make the Markers)*/
            agentId = markerArray[i].MarkerId.toString();
            const agentIds = new google.maps.Marker({
                markerId: markerArray[i].MarkerId.toString(),
                position: {
                    lat: mapLat,
                    lng: mapLng
                },
                label: {
                    color: 'white',
                    fontWeight: 'bold',
                    text: markerArray[i].MarkerId.toString()
                },
                title: markerArray[i].MarkerId.toString(),
                agentName: markerArray[i].AgentName,
                agentSecondaryName: markerArray[i].AgentName2,
                agentType: markerArray[i].AgentType,
                agentAddress: markerArray[i].AgentAddress1,
                agentCity: markerArray[i].AgentCity,
                agentState: markerArray[i].AgentState,
                agentZip: markerArray[i].AgentZip,
                agentCountry: markerArray[i].AgentCountry,
                agentPhoneNumber: markerArray[i].AgentPhoneNumber,
                agentEmailAddress: markerArray[i].AgentEmailAddress,
                agentWebsiteAddress: markerArray[i].AgentWebsiteAddress,
                agentCertIcon: markerArray[i].AgentMapIcon,
                map: map,
                optimized: false,
                icon: image
            });

            markerHolder.push(agentIds);

            /* --------------------------------------------(Add the Click Event)*/

            newMarker = agentIds;

            google.maps.event.addListener(newMarker, 'click', function () {
                $.each($(this), function (index, value) {
                    $.each(value, function (subIndex, subValue) {
                        /* ----------(Get the info for the in map "info window")*/
                        if (subIndex === 'agentName') {
                            windowTitle = subValue;
                        } else if (subIndex === 'position') {
                            windowPosition = subValue;
                        } else if (subIndex === 'agentType') {
                            windowAgentType = subValue;
                        } else if (subIndex === 'agentSecondaryName') {
                            agentSecondaryName = subValue;
                        } else if (subIndex === 'agentAddress') {
                            agentAddress = subValue;
                        } else if (subIndex === 'agentCity') {
                            agentCity = subValue;
                        } else if (subIndex === 'agentState') {
                            agentState = subValue;
                        } else if (subIndex === 'agentZip') {
                            agentZip = subValue;
                        } else if (subIndex === 'agentCountry') {
                            agentCountry = subValue;
                        } else if (subIndex === 'agentPhoneNumber') {
                            agentPhoneNumber = subValue;
                        } else if (subIndex === 'agentCertIcon') {
                            agentCertIcon = subValue;
                        }
                    }); // Inner each
                }); // Outer each

                /* it is now possible to submit the form without a "location", so we have no center...*/
                let markerLoc = this.getPosition();
                if (!mapCenter) {
                    mapCenter = {
                        'lat': markerLoc.lat(),
                        'lng': markerLoc.lng()
                    }
                }

                let secondaryName = '';
                if (agentSecondaryName) {
                    secondaryName = `<h5>${agentSecondaryName}</h5>`
                }

                let windowIcon = '';
                if (agentCertIcon) {
                    windowIcon = `<img class="infowindow-cert-icon" src="${agentCertIcon}" alt="">`
                }

                /* -----------------------------(Create the content string for the "info window")*/
                const mapContentString = `<div id="map-content">
                                        <div id="mapWindow">
                                        </div>
                                        <h3 id="firstHeading" class="firstHeading">${windowTitle}</h3>
                                        <div id="bodymap-content">
                                            <h4>${windowAgentType}${windowIcon}</h4>
                                            ${secondaryName}
                                            <p>${agentAddress}<br>
                                                ${agentCity}, ${agentState} ${agentZip}
                                            </p>
                                            <p>
                                                <a href="https://www.google.com/maps/dir/?api=1&origin=${mapCenter.lat},${mapCenter.lng}&destination=${windowPosition}" class="link--featured" target="_blank">
                                                    ${directionsText}
                                                </a>
                                            </p>
                                        </div>
                                    </div>`;

                /* --------------------------------(Close all open info windows)*/
                if (infoWindow) {
                    infoWindow.close();
                }

                /* ---------------------------(Create new info window at marker)*/
                map.panTo(markerLoc);

                infoWindow = new google.maps.InfoWindow({
                    content: mapContentString
                });

                infoWindow.open(map, this);

            }); // On Click
        } // For loop

        /* -----------------------------------------------(Make Marker Clusters)*/
        var mcOptions = {
            gridSize: 40,
            styles: [{
                url: `/Areas/Watts/Content/images/m1-${brandName}.png`,
                width: 53,
                height: 53,
                fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                textSize: 16,
                textColor: "#ffffff"
            }]
        };
        var mc = new MarkerClusterer(map, markerHolder, mcOptions);


        /* add user location (if we have one) to bounds to prevent super-close zoom-in
           when there is only a single marker  */
        if (mapCenter) {
            bounds.extend(mapCenter);
        }
        /* -------------------------------------(Zoom to fit all of the markers)*/
        map.fitBounds(bounds);

        /* -----------------------------------------(Hide the loading container)*/
        $(buyContainer).find('.js-how-to-buy__target').removeClass('is-hidden');
        $(buyContainer).find('.loader-wrapper').fadeOut('slow');

    }; // Init map


    /* MAKE THE MARKERS---------------------------------------------------------//
    //--------------------------------------------------------------------------*/
    const makeMarkers = function (pNum) {

        $(buyContainer).find('.horizontal-separator-small').show();

        const $mapLocations = $(buyContainer).attr('data-endpoint');

        const isRestrictedMode = $(buyContainer).attr('data-isrestricted');
        const limitResults = $(buyContainer).attr('data-limitresults');
        const locale = $(buyContainer).find('.js-how-to-buy__locale').val();
        const type = $(buyContainer).find('.js-how-to-buy__type').val();

        getLocation = getUrlParameter('Location');
         /* Give full state names for Indiana and Oregon states so that  google api service returns proper results.*/
        if (getLocation.toUpperCase() === "IN") getLocation = "Indiana";
        if (getLocation.toUpperCase() === "OR") getLocation = "Oregon";
        const gotSubType = getUrlParameter('SubType');
        const gotCategory = getUrlParameter('Category');
        const gotBrand = getUrlParameter('Brand');
        const gotCountry = getUrlParameter('Country');

        const sentLocation = getLocation || '';
        const sentSubType = gotSubType || '';
        const sentCategory = gotCategory || '';
        const sentBrand = gotBrand || '';
        const sentCountry = gotCountry || $(buyContainer).find('.js-how-to-buy__country').val();

        let validatedState;
        let validatedCounty;
        let validatedPostalCode;

        /* I.E. Fix for finally used in a promise-------------------------------*/
        const fakeFinal = function () {
            /* ----------------------------------(Make the call to the endpoint)*/
            $.ajax({
                type: "GET",
                url: $mapLocations,
                data: {
                    SubType: sentSubType,
                    Category: sentCategory,
                    Brand: sentBrand,
                    Country: sentCountry,
                    State: validatedState,
                    County: validatedCounty,
                    PostalCode: validatedPostalCode,
                    Locale: locale,
                    Type: type,
                    IsRestrictedMode: isRestrictedMode.toLowerCase(),
                    LimitResults: limitResults, 
                    PageNumber: pNum
                },
                beforeSend: function () {
                    if (!$(buyContainer).find('.how-to-buy__target').children('.loader-wrapper').length) {
                        $(buyContainer).find('.how-to-buy__target').append('<div class="loader-wrapper"><div class="loader"></div></div>');
                    } else {
                        $(buyContainer).find('.how-to-buy__target').find('.loader-wrapper').fadeIn('fast');
                    }
                }
            }).done(function (data) {

                if (!data.Agents[0]) {
                    noResults();
                    return;
                }
                else if (data.Pagination.TotalResultsCount > 0) {
                    $(buyContainer).find('.js-showing-results__container').removeClass('is-hidden');
                    $(buyContainer).find('.js-pagination-showing-results__container').removeClass('is-hidden');
                    $(buyContainer).find('.how-to-buy__hide').removeClass('is-hidden');
                }
                else {
                    $(buyContainer).find('.js-showing-results__container').addClass('is-hidden');
                    $(buyContainer).find('.js-pagination-showing-results__container').addClass('is-hidden');
                }

                // added this code to control the number of results for restricted mode
                //if (isRestrictedMode.toLowerCase() === "true" && type.toLowerCase() === "aerco technician") {
                //    if (data.Pagination.TotalResultsCount > 40) {
                //        data.Pagination.TotalResultsCount = 40;
                //        data.Pagination.TotalPages = 2;
                //    }
                //}

                /* Show message if result count is more than 1 page */
                if (data.Pagination.TotalPages > 1) {
                    multiplePages();
                    $(buyContainer).find('.js-load-more__container').removeClass('is-hidden');
                    
                }

                /* Show message if only default results have been returned */
                const defaultAgents = $.grep(data.Agents,
                    function (agent) {
                        return agent.AgentType === "";
                    }
                );
                if (defaultAgents.length) {
                    defaultResults();
                }

                const tempAgentArray = data.Agents;

                $.merge(agentArray, tempAgentArray);


                /* Create the centerpoint of the map and call init map--------------//
                //       The map will not render without a centerpoint,             //
                //      and we want to center at the users location.                //
                //------------------------------------------------------------------*/
                const locationField = sanitize($('.how-to-buy__location-input').val());
                const countryField = sanitize($(buyContainer).find('.js-how-to-buy__country').val());

                newLoc.zipCoord(`${locationField} ${countryField}`).then(function (position) {
                    currentLocation = position;

                    initMap(agentArray, currentLocation);
                }).catch(function () {
                    newLoc.zipCoord(`${countryField}`).then(function (position) {
                        currentLocation = position;

                        initMap(agentArray, currentLocation);
                    });
                });;

                /* Pagination-------------------------------------------------------*/

                const pageNumber = data.Pagination.CurrentPage;
                const pageCount = data.Pagination.TotalPages;
                const totalResultsCount = data.Pagination.TotalResultsCount;


                /* Write number of objects returned---------------------------------*/

                const resultsHtml = $(buyContainer).find('.js-how-to-buy__results-count').attr('data-results-count');

                $(buyContainer).find('.js-how-to-buy__results-count').text(`${totalResultsCount} ${resultsHtml} ${getLocation}`);

                var countText;
                var endCount;
                if (pageNumber * data.Pagination.ResultsPerPage > totalResultsCount)
                    endCount = totalResultsCount;
                else
                    endCount = pageNumber * data.Pagination.ResultsPerPage;
                //const $datcounttext = $(buyContainer).find('.js-showing-results__container');
                //var counttext = $(buyContainer).find('.js-showing__resultstext').val();
                //counttext = stringInject(counttext, { part: endCount });
                //counttext = stringInject(counttext, { totalresults: totalResultsCount });
                //if ($datcounttext.length) $datcounttext.empty();
                //$datcounttext.html(counttext);

                /* Make the in page items with handlebars-------(how-to-buy__result)*/
                data.RequestedBrand = gotBrand.replace('&','And');
                data.RequestedCategory = gotCategory.replace('&', 'And');
                const template = hBars.compile(htbSrc);
                const items = template(data);
                $target.html(items);
                //if (pageNumber > 1) {
                //    $target.append(items);
                //} else {
                //    $target.html(items);
                //}


                /* LOAD MORE--------------------------------------------------------//
                //------------------------------------------------------------------*/
                if (pageNumber < pageCount) {

                    const nextNumber = pageNumber + 1;
                    
                    const oldUrl = window.location.href;

                    const oldNumber = `PageNumber=${pageNumber}`;
                    const newNumber = `PageNumber=${nextNumber}`;

                    $(buyContainer).find('.js-load-more__container').show();

                    $(buyContainer).find('.js-load-more__button').off('click').click(function (event) {
                        event.preventDefault();
                        makeMarkers(nextNumber);

                        const updatedUrl = oldUrl.replace(oldNumber, newNumber);

                        history.replaceState(null, null, updatedUrl);
                    });



                } else {
                    $(buyContainer).find('.js-load-more__container').hide();
                 }


                /* PAGINATION ----------------------------------------------------//
          //--------------------------------------------------------------*/
                const $paginationContainer = $(buyContainer).find('.js-pagination_list');
                var pagenumbersPerPage = 5;
                var totalsetfivenumbers = Math.ceil(pageCount / pagenumbersPerPage);
                if (totalResultsCount > 0) {
                    $(buyContainer).find('.pagination__container').removeClass('is-hidden');
                }

                $(".js-pagination_list").children().remove();
                var $prev, $prevchild;
                $prev = $('<li></li>');
                $prevchild = $('<a class="previousarrow" href="#">&lt;</a>');

                if (pageNumber <= 1) {
                    $prevchild = $('<a class="previousarrow" href="#">&lt;</a>').css({ "color": "grey", "text-decoration": "none" }).addClass('disabled');

                }
                $prev.append($prevchild);
                $paginationContainer.append($prev);

                var exactpage;
                let w = 1;
                for (let y = 1; y <= totalsetfivenumbers; y++) {
                    var $setvalue = $('<li class="totalsetpages"></li>');
                    var $setchildvalue = $('<div class="setpages__container set' + y + '"' + '>' + '</div>');
                    let z;
                    $paginationContainer.append($setvalue);
                    for (z = w; z <= y * pagenumbersPerPage; z++) {
                        var $pagevalue = $('<a id="pagevalue' + z + '"' + ' href="#">' + z + '</a>');
                        if (z == pageNumber) {
                            $pagevalue.addClass('active disabled');
                            $setchildvalue.addClass('selected');
                            exactpage = y - 1;
                            }
                        if (z > pageCount) {
                            $pagevalue.css({ "color": "grey", "text-decoration": "none" }).addClass('disabled');
                        }
                        $setchildvalue.append($pagevalue);
                        $setvalue.append($setchildvalue);
                    }
                    w = z++;
                }

                var $next, $nextchild;
                $next = $('<li></li>');
                $nextchild = $('<a class="nextarrow" href="#">&gt;</a>');
                if ((pageNumber >= pageCount)) {
                    $nextchild = $('<a class="nextarrow" href="#">&gt;</a>').css({ "color": "grey", "text-decoration": "none" }).addClass('disabled');
                }
                $next.append($nextchild);
                $paginationContainer.append($next);
                var currentPage;
                $('.totalsetpages a').on('click', function (event) {
                    if ($(this).hasClass('disabled')) {
                        return false;
                    }
                    event.preventDefault();
                    currentPage = parseInt($(this).text());
                    makeMarkers(currentPage);
                    $("html,body").animate({ scrollTop: $('.how-to-buy__template-wrapper').offset().top - 20, scrollLeft: 0 }, 800);
                });

                $(".totalsetpages").each(function (e) {
                    if (e != exactpage)
                        $(this).hide();
                });
                $(".nextarrow").click(function (event) {
                    
                    event.preventDefault();
                    if ($(this).hasClass('disabled')) {
                        return false;
                    }

                    var $nextactivepage = $('ul.js-pagination_list').find('li>div>a.active').next();
                    if ($nextactivepage.parent().hasClass('selected')) {
                        makeMarkers($nextactivepage.text());
                        $("html,body").animate({ scrollTop: $('.how-to-buy__template-wrapper').offset().top - 20, scrollLeft: 0 }, 800);
                    }
                    else {
                        if ($(".js-pagination_list .totalsetpages:visible").next().length != 0) {
                            $(".js-pagination_list .totalsetpages:visible").next().show().prev().hide();
                            makeMarkers(((exactpage + 1) * pagenumbersPerPage) + 1);
                            $("html,body").animate({ scrollTop: $('.how-to-buy__template-wrapper').offset().top - 20, scrollLeft: 0 }, 800);
                        }
                        else {
                            $(".js-pagination_list .totalsetpages:visible").hide();
                            $(".js-pagination_list .totalsetpages:first").show();
                        }
                        return false;
                    }
                    
                    });
                $(".previousarrow").click(function (event) {
                    event.preventDefault();
                    if ($(this).hasClass('disabled')) {
                        return false;
                    }
                    var $prevactivepage = $('ul.js-pagination_list').find('li>div>a.active').prev();
                    if ($prevactivepage.parent().hasClass('selected')) {
                        makeMarkers($prevactivepage.text());
                        $("html,body").animate({ scrollTop: $('.how-to-buy__template-wrapper').offset().top - 20, scrollLeft: 0 }, 800);
                    }
                    else {
                        if ($(".js-pagination_list .totalsetpages:visible").prev().length != 0) {
                            $(".js-pagination_list .totalsetpages:visible").prev().show().next().hide();
                            makeMarkers(exactpage * pagenumbersPerPage);
                            $("html,body").animate({ scrollTop: $('.how-to-buy__template-wrapper').offset().top - 20, scrollLeft: 0 }, 800);
                        }
                        else {
                            $(".js-pagination_list .totalsetpages:visible").hide();
                            $(".js-pagination_list .totalsetpages:last").show();
                        }
                        return false;
                    }
                });

                const $datacounttext = $(buyContainer).find('.js-pagination-showing-results__container');
                var resultstext = $(buyContainer).find('.js-showing__resultstext').val();

                resultstext = stringInject(resultstext, { totalresults: totalResultsCount });
                if (endCount <= data.Pagination.ResultsPerPage) {
                    resultstext = stringInject(resultstext, { startcount: 1 });
                }
                else if (endCount >= totalResultsCount) {
                    var prevendcount = (Math.floor(endCount / data.Pagination.ResultsPerPage)) * data.Pagination.ResultsPerPage;
                    resultstext = stringInject(resultstext, { startcount: prevendcount + 1 });
                }
                else {
                    resultstext = stringInject(resultstext, { startcount: endCount - (data.Pagination.ResultsPerPage - 1) });
                }
                resultstext = stringInject(resultstext, { endcount: endCount });
                resultstext = stringInject(resultstext, { part: endCount });

                $datacounttext.html(resultstext)

            }).fail(function () {
                noResults();
            });
        };


        newLoc.validateLocation(`${sentLocation} ${sentCountry}`)
            .then(function (address) {
                const addressArr = JSON.parse(address);

                const stateObj = $.grep(addressArr, (obj) => obj["types"].indexOf("administrative_area_level_1") > -1);
                if (stateObj && stateObj[0]) {
                    validatedState = stateObj[0]["long_name"];
                }

                const countyObj = $.grep(addressArr, (obj) => obj["types"].indexOf("administrative_area_level_2") > -1);
                if (countyObj && countyObj[0]) {
                    validatedCounty = countyObj[0]["long_name"];
                }

                const postalCodeObj = $.grep(addressArr, (obj) => obj["types"].indexOf("postal_code") > -1);
                if (postalCodeObj && postalCodeObj[0]) {
                    validatedPostalCode = postalCodeObj[0]["long_name"];
                }
                fakeFinal();
            }).catch(function () {
                fakeFinal();
            });

    }; // Make markers


    /* Initial run of the function to build the map-----------------------------//
    //--------------------------------------------------------------------------*/

    $(window).bind("load", function () {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            if (getLocation) {
                makeMarkers(1);
            } else {
                /* Try to get zip from IP---------------------------------------*/
                const $ipEndpoint = $(buyContainer).attr('data-ip-endpoint');

                $.ajax({
                    type: "GET",
                    url: $ipEndpoint
                }).done(function (data) {
                    if (data.WhoIsInformation && data.WhoIsInformation.PostalCode && data.WhoIsInformation.Country) {
                        if (data.WhoIsInformation.Country === 'US') {
                            data.WhoIsInformation.Country = 'United States';
                        }

                        const selector = `.js-how-to-buy__country option[value='${data.WhoIsInformation.Country}']`
                        if ($(buyContainer).find(selector).length > 0) {
                            $(buyContainer).find(selector).prop('selected', true).parent('select').trigger('change');
                            $(buyContainer).find('.how-to-buy__location-input').val(sanitize(data.WhoIsInformation.PostalCode));
                        }
                    } else {
                        newLoc.getLatLng(newLoc.mapToZip);
                    }
                }).fail(function () {
                    newLoc.getLatLng(newLoc.mapToZip);
                });
            }
        }

        /* Default to hidden map on mobile--------------------------------------*/
        const mobileBreak = 640;
        const hideMap = $('.js-how-to-buy__hide-button').html();
        const showMap = $('.js-how-to-buy__hide-button').attr('data-text');

        if ($(window).width() <= mobileBreak) {
            $(buyContainer).find('.js-how-to-buy__map-target').addClass('is-hidden');
            $(buyContainer).find('.js-how-to-buy__hide-button').html(showMap);
        }


        /* Hide map link--------------------------------------------------------//
        //----------------------------------------------------------------------*/

        $(buyContainer).find('.js-how-to-buy__hide-button').click(function (e) {
            e.preventDefault();
            if ($(buyContainer).find('.js-how-to-buy__map-target').hasClass('is-hidden')) {
                $(buyContainer).find('.js-how-to-buy__map-target').removeClass('is-hidden');
                $(e.currentTarget).html(hideMap);
            } else {
                $(buyContainer).find('.js-how-to-buy__map-target').addClass('is-hidden');
                $(e.currentTarget).html(showMap);
            }
        });
    }); // Ready

    $targetForm.on("submit", function (e) {
        e.preventDefault();

        let formerror = false;
        $(buyContainer).find(':input[required]').each(function (index, element) {
            if ($(element).val() === "") {
                $(element).next('.js-form-required').removeClass('is-hidden');

                $(element).on('change', function () {
                    if ($(element).val() !== "") {
                        $(element).next('.js-form-required').addClass('is-hidden');
                    }
                });
                //commented code is for validating the zip code for Unied states and Canada.
                //if ($(element).hasClass('js-how-to-buy__location-input')) {
                //    var country = $(buyContainer).find('.js-how-to-buy__country').val();
                //    if (country === "United States" || country === "Canada") {
                //        formerror = true;
                //        return;
                //    }
                //    else {
                //        $(element).next('.js-form-required').addClass('is-hidden');
                //    }
                //}
                formerror = true;
            }
        });

        if (!formerror) {
            agentArray = [];

            $(buyContainer).find('.js-how-to-buy__no-results').removeClass('is-visible');
            $(buyContainer).find('.js-how-to-buy__no-results-state').removeClass('is-visible');
            $(buyContainer).find('.js-how-to-buy__target').removeClass('is-hidden');
            $(buyContainer).find('.how-to-buy__target').find('.loader-wrapper').fadeIn('fast');
            const subType = encodeURIComponent($(buyContainer).find(".js-how-to-buy__subtype input:checked").val());
            let subLocVal = $(buyContainer).find(".js-how-to-buy__location-input").val();
            if (subLocVal) {
                subLocVal = sanitize(subLocVal);
            }
            const subLoc = encodeURIComponent(subLocVal);
            const subCat = encodeURIComponent($(buyContainer).find(".js-how-to-buy__category").val());
            const subBrand = encodeURIComponent($(buyContainer).find(".js-how-to-buy__brand").val());
            const subCountry = encodeURIComponent($(buyContainer).find(".js-how-to-buy__country").val());
            const oldUrl = window.location.href.split("?")[0];
            $(buyContainer).find(".js-how-to-buy__location-input").val(subLocVal);
            const subUrl = `${oldUrl}?SubType=${subType}&Country=${subCountry}&Location=${subLoc}&Category=${subCat}&Brand=${subBrand}&PageNumber=1`;

            history.replaceState(null, null, subUrl);

            makeMarkers(1);
        }
    });
});