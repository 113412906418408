$('#dynamicModal').on('shown.bs.modal', function (event) {
    var button = $(event.relatedTarget);
    var modal = $(this);
    var dynamicContent = modal.find('#dynamicContent');



    // Get the content from the data-content attribute of the button
    var contentSelector = button.data('content');





    dynamicContent.html(document.querySelector(`${contentSelector}`).children[0]);
});

// pagination dots
/*var swiper = new Swiper(".mySwiper", {
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    keyboard: true,
    autoplay: {
        delay: 100000,
        disableOnInteraction: true,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

// pagination numbers
var swiper = new Swiper(".paginationNumbers", {
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    keyboard: true,
    autoplay: {
        delay: 100000,
        disableOnInteraction: true,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: "fraction"
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});*/