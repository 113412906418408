function calcChooser(formName){
    let selectedProduct;
    let TapeMat = 0;
    let WarmWire = 0;

    if ($(formName).find('.room-shape').val() === 'simple') {
        TapeMat = 5;
    } else {
        WarmWire = 5;
    }

    if ($(formName).find('.budget').val() === 'ease') {
        TapeMat += 5;
    } else {
        WarmWire += 5;
    }

    if ($(formName).find('.room-size').val() === 'small') {
        TapeMat += 5;
    } else {
        WarmWire += 5;
    }

    if (TapeMat > WarmWire) {
       selectedProduct = 'TapeMat'; 
    } else {
        selectedProduct = 'WarmWire';
    }

    return selectedProduct;
}
export {calcChooser};