import 'slick-carousel';

$.fn.randomize = function(selector) {
    const $elems = selector ? $(this).find(selector) : $(this).children();
    const $parents = $elems.parent();

    $parents.each(function(index, e) {
        $(e).children(selector).sort(function() {
            return Math.round(Math.random()) - 0.5;
        }).detach().appendTo(e);
    });

    return this;
};

// randomize the slides and then call slick
$('.main-container').find('.brands-carousel').randomize('.brands-carousel__slide');


$('.brands-carousel').each(function(index, e) {
    const $element = $(e);
    const previousText = $element.data('previous-text');
    const nextText = $element.data('next-text');

    $element.slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 5,
        prevArrow: `<button class="slick-prev" aria-label="${previousText}" type="button"></button>`,
        nextArrow: `<button class="slick-next" aria-label="${nextText}" type="button"></button>`,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});
$('.slick-prev').on('click', function () {
    $(".slick-slide").each(function () {
        if ($(this).hasClass('slick-active')) {
            $(this).find(".brands-carousel__slide").each(function () {
                $(this).attr('aria-hidden', 'false');
            });
        } else {
            $(this).find(".brands-carousel__slide").each(function () {
                $(this).attr('aria-hidden', 'true');
            });
        }
    });
})

$('.slick-next').on('click', function () {
    $(".slick-slide").each(function () {
        if ($(this).hasClass('slick-active')) {
            $(this).find(".brands-carousel__slide").each(function () {
                $(this).attr('aria-hidden', 'false');
            });
        } else {
            $(this).find(".brands-carousel__slide").each(function () {
                $(this).attr('aria-hidden', 'true');
            });
        }
    });
})

$('.brands-carousel .slick-next').click(function () {
    $('.brands-carousel .slick-prev').removeAttr('tabindex', '-1');
});