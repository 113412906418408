import * as hBars from 'handlebars/dist/handlebars';
const validateSuggest = function(formName) {
    const $suggestPath = $('.js-location-suggest__wrapper').attr('data-endpoint');
    const $suggestContainer = $('.js-location-suggest__wrapper');
    const $htmlSource = $("#location-suggest__template").html();
    const regRegion = [];

    let newTyping;
    let suggestTemplate;
    let regionData;
    let regRegionTest;


    /* GET SUGGEST AND VALIDATION INFORMATION-----------------------------------*/

    $.ajax({
        type: "GET",
        url: $suggestPath

    }).done(function(data) {
        regionData = data;

        for (let i = 0; i < regionData.Regions.length; i++) {
            regRegion.push(regionData.Regions[i].RegionId);
        }
        regRegionTest = regRegion.join("|");
    });


    /* AUTO SUGGEST-------------------------------------------------------------*/

    $('.how-to-buy__location-input').keyup(function(event) {

        newTyping = $(event.currentTarget).val();

        const $suggestTarget = $('.location-suggest__wrapper');
        let suggestItems;

        suggestTemplate = hBars.compile($htmlSource);

        /* ----------------------------------------------------(Submit on Enter)*/
        if (event.which === 13 || event.keyCode === 13) {

            $(event.currentTarget).parent('.how-to-buy__selector').submit();

        /* -------------------------------------------------------(clear on esc)*/
        } else if (event.which === 27 || event.keyCode === 27) {

            if ($('.location-suggest__wrapper').hasClass('is-open')) {
                $('.location-suggest__wrapper').removeClass('is-open');
            }

        /* ------------------------------------------------------(any other key)*/
        } else {

            /* ---------------------------------------------------(Auto suggest)*/
            newTyping = $(event.currentTarget).val();

            if (newTyping !== '') {

                const typedRX = new RegExp(`^(${newTyping}.*)$`, 'im');
                const regionArray = [];

                for (let i = 0; i < regionData.Regions.length; i++) {

                    if (typedRX.test(regionData.Regions[i].RegionId)) {

                        regionArray.push({"RegionName": regionData.Regions[i].RegionId});
                        suggestItems = suggestTemplate(regionArray);

                        $suggestTarget.html(suggestItems);
                        $suggestContainer.addClass('is-open');
                    }
                }

                /* -----------------------Add click event to the suggested items*/

                $('.js-location-suggest__link').off('click').click(function(e) {
                    e.preventDefault();
                    const locale = $(e.currentTarget).attr('data-location');
                    $('.how-to-buy__location-input').val(locale);
                    $suggestContainer.removeClass('is-open');
                });

            } else {
                $suggestContainer.removeClass('is-open');
            }// IF TYPING IS EMPTY
        }// ELSE
    });// ON KEYPRESS


    /* SUBMIT BUTTON------------------------------------------------------------*/
    formName.submit(function(event) {

        const $regExTest = $('.js-how-to-buy__regex').val();
        const stateZipMatch = new RegExp(`^(${regRegionTest}|${$regExTest})$`, 'gim');
        const $stateZip = $('.js-how-to-buy__location-input').val();

        formName.children('.js-error-message').remove();
        const $errorMessage = $('.js-how-to-buy__error-message').val();

        if ($stateZip !== null && $stateZip !== '') {
            if (! stateZipMatch.test($stateZip)) {
                event.preventDefault();
                formName.append(`<div class="js-error-message error-message">${$errorMessage}</div>`);
            }
        } else {
            event.preventDefault();
            formName.append(`<div class="js-error-message error-message">${$errorMessage}</div>`);
        }

    });

    formName.children('.js-submit').click = function() {
        formName.submit();
    };
};

export {validateSuggest};