$(document).ready(function () {
    $(".hiu-calc-input").each(function () {
        // Check if the input has a value
        if ($(this).val().trim() !== "") {
            // Add class active to the previous label of the input
            $(this).prev(".hiu-calc-label").addClass("active");
        }
    });


    /* tr radio selection*/
    $('.article-table-wrap tr.article-table-tr').click(function (event) {
        if (event.target.type !== 'radio') {
            $(':radio', this).trigger('click');
        }
    });

});
/*$(window).on('load', function () {
    // Disable #x
    $(".next").prop("disabled", true);

});*/

/*   function validateForm() {
       clearError();

       var isValid = true;

       // first name

       var firstName = $("#hiu__firstandlastname").val();
       if (!firstName) {
           displayError('first name requierd', 'firstNameError');
           isValid = false;
       }
       return isValid;
   }*/

/*function displayError(elementId) {
    //var errorElement = document.getElementById(elementId);
    //errorElement.textContent = errorMessage;
    $(elementId).next('.error').removeClass('is-hidden');
    console.log('error maesage');
}
function clearError() {
    var errorElement = document.querySelectorAll('.error');
    errorElement.forEach(function (element) {
        element.textContent = '';
    });
}*/
    //var isNoError = true;
    // button disabeled 
    //$('.formId0 .next').prop("disabled", true);
/*$('.formId0 .next').click(function (event) {
    console.log('first');
    var firstName = $("#hiu__firstandlastname").val();
    if (!firstName || firstName == '') {
        isNoError = true;
        console.log('inst');
        displayError('#hiu__firstandlastname', 'first name requierd');
    } else {
        isNoError = false;
    }
    var companyName = $("#hiu__company").val();
    if (!companyName || companyName == '') {
        isNoError = true;
        console.log('inst');
        displayError('#hiu__company', 'companyname requierd');
    } else {
        isNoError = false;
    }
    var emailReg = $("#hiu__email").val();
    if (!emailReg || emailReg == '') {
        isNoError = true;
        console.log('inst');
        displayError('#hiu__email', 'email requierd');
    } else {
        isNoError = false;
    }
    var informationaboutText = $("#hiu__informationabout").val();
    if (!informationaboutText || informationaboutText == '') {
        isNoError = true;
        console.log('inst');
        displayError('#hiu__informationabout', 'information requierd');
    } else {
        isNoError = false;
    }
});*/
