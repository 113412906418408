$(document).ready(function () {
    $('.button-div > button:first-child').addClass('active');
    $('.button-div > button:first-child span').addClass('all-product-span');
    $(document).on("click", ".item-tab", function () {
        if ($(".wattseu .item-tab").length) {
            var labelName = $(this).find(".title-name-label").text();
            $(".item-tab").removeClass("active");
            $(this).attr("aria-current", labelName).addClass("active");
            var itemClass = $(this).data("item-class");
            var detailsClass = ".item-details-" + itemClass;
            if (itemClass == 1) {
                $(".item-details-all").show();
            } else {
                $(".item-details").hide();
                $(detailsClass).show();
            }
        }
    });
    


});