/**
 * Created by cbol on 05/16/18.
 * Handles the back to top / scroll to top functionality.
 */

import throttle from 'lodash-es/throttle';
import $ from 'jquery';

$(() => {
    $('.scrollToTop').on('click', () => {
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
    });

    $(window).on('scroll', throttle(() => {
        if (window.pageYOffset > 200) {
            $('.scrollToTop').show();
        } else {
            $('.scrollToTop').hide();
        }
    }, 100));
});
