function setHeightOfCard() {
    // for equal height cards
    if ($(".our-services-cmp .card-content").length) {
        $(".our-services-cmp .card-content").each(function () {
            var highestBox = 0;

            $(".card", this).each(function () {
                if ($(this).height() > highestBox) {
                    highestBox = $(this).height();
                }
            });

            $(".card", this).height(highestBox);
        });
    }

}


$(window).on("load", function () {
    setTimeout(function () {
        setHeightOfCard();
    }, 1000);
});


//$(document).ready(function () {
//    setHeightOfCard();
//});

$(function () {

    setTimeout(function () {
        setHeightOfCard();
    }, 1000);

    //breadcrumbsInSeriesPage
    if ($(".eu-inpage-mobile-navigation").length) {
        $(".breadcrumbs").addClass("sticky-breadcrumb");
    } else {
        $(".breadcrumbs").removeClass("sticky-breadcrumb");
    }
});
