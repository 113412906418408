import $ from 'jquery';

// The Google reCAPTCHA api needs to be able to find the onRecaptchaLoad function
window.onRecaptchaLoad = function() {
    $('.google-recaptcha-render').each(function(index, e) {
        const $element = $(e);
        const $target = $element.siblings('.google-recaptcha-target');
        const $error = $element.siblings('.google-recaptcha-error');
        const siteKey = $element.data('site-key');

        grecaptcha.render(e,
            {
                'sitekey': siteKey,
                'callback': function(response) {
                    $target.val(response).removeClass('input-validation-error');
                    $error.empty().removeClass('field-validation-error').addClass('field-validation-valid');
                },
                'expired-callback': function() {
                    $target.val('');
                }
            });
    });
};