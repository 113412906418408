$('.js-accordion__toggle').on('click', (e) => {
    e.preventDefault();

    const $element = $(e.target);

    if ($element.next().hasClass('is-open')) {
        $element.next().removeClass('is-open');
        $element.next().slideUp(350);
        $element.next().attr('aria-expanded','false');
    } else {
        $element.parent().parent().find('.js-accordion__inner').removeClass('is-open');
        $element.parent().parent().find('.js-accordion__inner').slideUp(350);
        $element.next().toggleClass('is-open');
        $element.next().slideToggle(350);
        $element.parent().parent().find('.js-accordion__inner').attr('aria-expanded', 'true');
    }

    if ($element.hasClass('is-open')) {
        $element.removeClass('is-open');
        $(e.target).attr('aria-expanded', 'false');
    } else {
        $element.addClass('is-open');
        $element.parent().siblings().children('.js-accordion__toggle').removeClass('is-open');
        $(e.target).attr('aria-expanded', 'true');
    }
});

$(document).ready(function () {
    var accordionToggle = $('.js-accordion__toggle');
    if (accordionToggle.hasClass('is-open')) {
        accordionToggle.attr('aria-expanded', 'true');
    } else {
        accordionToggle.attr('aria-expanded', 'false');
    }
});