import $ from 'jquery';
import { fullScreen } from "./_fullscreen.js";

function initVideo(elements) {
    $(elements).each(function (index, value) {

        if (!$(value).find(">:first-child").hasClass('js-yt__container')) {

            /* ---------------------------------------variables for main objects*/
            const $videoContainer = $(value).children('.js-video__container');
            const $video = $videoContainer.find('.js-video');

            /* ----------------------------------function & events*/            
            function videoTimeUpdate(e) {
                $video[0].setAttribute("controls", "controls");
            }

            $video[0].addEventListener('timeupdate', videoTimeUpdate, false);
        } // END HTML 5 CHECK
    });// END EACH
}
$(window).bind("load", function () {
    initVideo($('.js-video__wrapper').not('.js-results .js-video__wrapper'));
}); // END WINDOW LOAD

export { initVideo };