
$(document).ready(function () {
    //breadcrumb
    var breadcrumbcounter = $(".counter-val").text();

    if (breadcrumbcounter != "") {
        $("#" + breadcrumbcounter).removeAttr("href");
        $("#" + breadcrumbcounter).addClass("eu-current-item");
    }

    var $breadcrumbList = $(".breadcrumb__list");
    if ($breadcrumbList.length > 0) {
        var $firstBreadcrumbItem = $breadcrumbList.find(".breadcrumb__item:first");

        if ($breadcrumbList.find(".breadcrumb__item").length > 2) {
            $firstBreadcrumbItem.css("padding-right", "3px");
        }
    }
    if ($("#spbreadcrumb").text().trim() == "*") {
        $("#spbreadcrumb").text($(".eu-mt-60").text());
    }
    //breadcrumb


    var selectBoxContainer = $(".eu-select-partner .selectric");

    selectBoxContainer.on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        var selectBox = $(this);
        var selectBoxItem = selectBox.siblings('.selectric-items');

        if (selectBoxItem.hasClass("show")) {
            selectBoxItem.removeClass("show");
            selectBox.removeClass("active");
        } else {
            selectBoxItem.addClass("show");
            selectBox.addClass("active");
            selectBoxContainer.not(selectBox).siblings('.selectric-items').removeClass("show");
            selectBoxContainer.not(selectBox).removeClass("active");
        }
    });

    if ($(".related-products-item").text().trim() == '') {
        $(".related-product-title").hide();
        $(".related-products").hide();
    }
    else {
        $(".related-product-title").show();
        $(".related-products").show();
    }
    $(document).on("click", function (e) {
        if (selectBoxContainer.hasClass("active")) {
            selectBoxContainer.each(function () {
                var selectBox = $(this);
                var selectBoxItem = selectBox.siblings('.selectric-items');
                if (!selectBox.is(e.target) && selectBox.has(e.target).length === 0 && !selectBoxItem.is(e.target) && selectBoxItem.has(e.target).length === 0) {
                    selectBox.removeClass("active");
                    selectBoxItem.removeClass("show");
                }
            });
        }
    });

    $(document).on("click", ".selectric-items ul li", function (e) {
        if ($(".selectric-items").length) {
            var selectricParent = $(this).parent().parent().parent().siblings(".selectric");
            selectricParent.siblings(".selectric-items").find("li").removeClass("selected");
            $(this).addClass("selected");

            console.log(selectricParent);
            $(this).siblings().removeClass("active");
            $(this).addClass("active");

            e.preventDefault();

            var li_text = $(this).text();
            selectricParent.children(".label").html(li_text);
            selectricParent.siblings(".selectric-items").removeClass("show");
            selectricParent.find("b").removeClass("button2");
        }
    });


    $(".selectric").on("click", function (e) {
        $(this).find("b").toggleClass("button2");
    });

    //Header:::MouseHoverHeader-ProductsMenuHover
    $(".menu-navigation li.dropdown .nav-link-inside").on("mouseover", function (e) {
        $(this).addClass("link-hover");
        $(".menu-navigation li.dropdown .nav-link-inside").not(this).addClass("link-no-hover");
        // Get the data-src attribute value
        var newSrc = $(this).attr('data-src');

        // Update the src attribute of .dynamic-img-src
        $('.dynamic-images img').attr('src', newSrc);
    });


    var count;

    var dfltFirmaSelectedText = $(".dflt-firma-selectedtext").text();
    var dfltmestoSelectedText = $(".dflt-mesto-selectedtext").text();
    count = $(".accordian-count").val();
    var i = 0;
    while (i < count) {

        var lblemailText = $("#collapse-" + i).find(".lblemail").text();
        var lblwebText = $("#collapse-" + i).find(".lblweb").text();
        if (lblemailText == "") {
            $(".emailbox-" + i).hide();
            console.log(lblemailText);
        }
        else {
            $(".emailbox-" + i).show();
        }
        if (lblwebText == "") {
            $(".webbox-" + i).hide();
        }
        else {
            $(".webbox-" + i).show();
        }
        i = i + 1;
    }

    $("#firmaList").closest('ul').children(':first').before('<li  class="selected active">' + dfltFirmaSelectedText + '</li>');
    $("#mestoList").closest('ul').children(':first').before('<li  class="selected active">' + dfltmestoSelectedText + '</li>');


    //if ($(".eu-e-paper").length) {
    //    oxomi_ready();
    //}

    if ($(".slick-slide").length) {
        $(".slick-slide, .slick-slide a, .slick-slide button").removeAttr('tabindex');
    }

    $('.eu-select-partner:last').css('margin-right', '0');

    if ($(".related-products-item").length) {
        if ($(".related-products-item").text().trim() == '') {
            $(".related-product-title").hide();
            $(".related-products").hide();
        }
        else {
            $(".related-product-title").show();
            $(".related-products").show();
        }
    }


    if ($(".eu-certificate-cmp").length) {
        $(".eu-certificate-cmp .eu-acc-header").on("click", function (e) {
            e.preventDefault();
        });
    }

    //socialIcons
    if ($("body.wattseu").length) {
        var postUrl = encodeURI(document.location.href);
        var postTitle = $(document).attr('title');
        const facebookBtn = document.querySelector(".facebook-share");
        const linkedinBtn = document.querySelector(".linkdin-share");
        if (facebookBtn != null) facebookBtn.setAttribute("href", "https://www.facebook.com/sharer.php?u=" + postUrl);
        if (linkedinBtn != null) linkedinBtn.setAttribute("href", "https://www.linkedin.com/shareArticle?url=" + postUrl + "&text=" + postTitle);
    }

    if ($("body").hasClass("wattseu")) {
        if (window.location.href.indexOf("technical-support") !== -1) {
            var lastBreadcrumb = $('.breadcrumbs .breadcrumb__list li:last-child').text();

            // Set the last breadcrumb value as the document title
            document.title = lastBreadcrumb;
        }
    }
    

});
$("#validationCustom02").on("keypress click input", function () {
    var val = $(this).val();

    if (val.length) {
        $(".accordion-item").hide().filter(function () {
            return $('.lblPsc', this).text().toLowerCase().indexOf(val.toLowerCase()) > -1;
        }).show();
    } else {
        $(".accordion-item").show();
    }
});
$(document).on("click", "#firmaList li", function () {
    if ($("#firmaList li").length) {
        $("#accordion").children().show();
        var liText = $(this).text();
        var count;
        count = $(".accordian-count").val();
        var i = 0;
        while (i <= count) {

            var lblFirmaText = $("#collapse-" + i).find(".lblFirma").text();
            const result = liText.toUpperCase().trim() === lblFirmaText.toUpperCase().trim();

            if (result) {

                console.log('The strings are similar.');
            } else {
                if (liText.toUpperCase().trim() != $(".dflt-firma-selectedtext").text().toUpperCase().trim()) {

                    $("#collapse-" + i).parent().hide();
                }

            }
            i = i + 1;
        }
    }

});




$(document).on("click", "#mestoList li", function () {
    if ($("#mestoList li").length) {
        $("#accordion").children().show();
        var liText = $(this).text();
        var count;
        count = $(".accordian-count").val();
        var i = 0;
        while (i <= count) {

            var lblMestoText = $("#collapse-" + i).find(".lblMesto").text();
            const result = liText.toUpperCase().trim() === lblMestoText.toUpperCase().trim();

            if (result) {

                console.log('The strings are similar.');
            } else {
                console.log('The strings are not similar.');
                if (liText.toUpperCase().trim() != $(".dflt-mesto-selectedtext").text().toUpperCase().trim()) {
                    $("#collapse-" + i).parent().hide();
                }
            }
            i = i + 1;
        }
    }

});





//OxomiScriptFor--E-Paper-Page

// Wir warten bis die OXOMI JavaScript Bibliothek geladen ist...
function oxomi_ready() {
    // Initialisierung
    oxomi.init({
        "portal": "3000314",
        "accessToken": "",
        "user": "_showcase"
    });
    // Funktionsaufruf, um Portaloberflche anzuzeigen
    oxomi.portalSearch({
        "target": "#universal-search-output",
        "input": "#universal-search-input",
        "showDetails": true,
        "filterBox": "#universal-search-filter",
        "filterBoxGroups": "types",
        "showAnchorLinks": true,
        "singlePage": true

    });
};

//nu contact us double form 
$(document).ready(function () {
    $(".nu-contactus-form.nu-contactus-customer-form").hide();
    $('.eu-blue-btn:first').addClass('active');
    $(".eu-blue-btn").click(function () {
        $(this).addClass("active").siblings("button").removeClass("active");
    });
    $(".eu-blue-btn:first").click(function () {
        $(".nu-contactus-form:first").css("display", "block");
        $(".nu-contactus-form:first").next().css("display", "none");
    });
    $(".eu-blue-btn:first").next().click(function () {
        $(".nu-contactus-form:first").css("display", "none");
        $(".nu-contactus-form:first").next().css("display", "block");
    });
});

//PartnersPageAccordion
$('.eu-partner .accordion-button').on('click', function () {
    var targetCollapse = $($(this).data('bs-target'));
    targetCollapse.collapse('toggle');
});

$(document).on('click', function (e) {
    if ($('.eu-partner .selectric').length) {
        if (!$(e.target).closest('.eu-partner .selectric').length) {
            $('.button').removeClass('button2');
        }
    }
});

function hidePaginationIfEmpty() {
    //Remove pagination prev and next
    if ($('#searchResultsList #eu-pagination-controls .page-numbers').is(':empty')) {
        if ($(window).width() <= 991) {
            $('.pagination-container').remove();
        }
    }
}

$(window).on("load", function () {
    setTimeout(function () {
        hidePaginationIfEmpty();
    }, 2000);

});

$(function () {
    setTimeout(function () {
        hidePaginationIfEmpty();
    }, 2000);

});
