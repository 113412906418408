
$(document).ready(function () {
    function visible(partial) {
        var $t = partial,
            $w = jQuery(window),
            viewTop = $w.scrollTop(),
            viewBottom = viewTop + $w.height(),
            _top = $t.offset().top,
            _bottom = _top + $t.height(),
            compareTop = partial === true ? _bottom : _top,
            compareBottom = partial === true ? _top : _bottom;
        return ((compareBottom <= viewBottom) && (compareTop >= viewTop) && $t.is(':visible'));
    }
    //$(window).scroll(function () {
    function counterFunc() {
        //console.log("counterFunc called");
        if ($('.spin-inc').length) {
            if (visible($('.spin-inc'))) {
                if ($('.spin-inc').hasClass('counter-loaded')) return;
                $('.spin-inc').addClass('counter-loaded');
                $('.spin-inc').each(function () {
                    var $this = $(this);
                    jQuery({
                        Counter: 0
                    }).animate({
                        Counter: $this.text()
                    }, {
                        duration: 2200,
                        easing: 'swing',
                        step: function () {
                            $this.text(Math.ceil(this.Counter));
                        }
                    });
                });
            }
        }
    }
        
    //});

    var $couterExists = $('.spin-inc');
    $(window).on('scroll resize', function () {
       if ($couterExists.length) {
            counterFunc();
        }
    });

    counterFunc();
});
