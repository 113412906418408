$(document).ready(function () {
    if ($(".slider-cmp").length > 0) {
        $(".slider-cmp").each(function (index, element) {
            var $slider = $(element).find(".slider-wrapper");
            var $progressBar = $(element).find(".slider-pagination");
            var $prevButton = $(element).find(".slider-button-prev");
            var $nextButton = $(element).find(".slider-button-next");

            var isDragged = false;

            function updateSliderSettings() {
                var windowWidth = $(window).width();
                var slidesToShow, breakpointSettings;

                if (windowWidth >= 1200) {
                    slidesToShow = 4;
                    breakpointSettings = {
                        mobileFirst: false // Set mobileFirst to false
                    };
                } else if (windowWidth >= 992 && windowWidth <= 1199) {
                    slidesToShow = 3;
                    breakpointSettings = {};
                } else if (windowWidth >= 577 && windowWidth <= 991) {
                    slidesToShow = 2.5;
                    breakpointSettings = {};

                    // Check if the slider has the class ".slider-projects"
                    if ($slider.closest('.slider-projects').length > 0) {
                        // Add autoplay settings for ".slider-projects" sliders
                        breakpointSettings.autoplay = isDragged;
                        breakpointSettings.autoplaySpeed = 3000;
                        breakpointSettings.focusOnSelect = true;
                    }
                } else if (windowWidth <= 576) {
                    slidesToShow = 1.5;
                    breakpointSettings = {};

                    // Check if the slider has the class ".slider-projects"
                    if ($slider.closest('.slider-projects').length > 0) {
                        // Add autoplay settings for ".slider-projects" sliders
                        breakpointSettings.autoplay = true;
                        breakpointSettings.autoplaySpeed = 3000;
                        breakpointSettings.focusOnSelect = true;
                    }
                }

                // Common settings for all breakpoints
                breakpointSettings.centerMode = false;
                breakpointSettings.variableWidth = false;
                breakpointSettings.arrows = false;
                breakpointSettings.infinite = false;
                breakpointSettings.draggable = true;
                breakpointSettings.swipe = true;
                breakpointSettings.touchMove = true;
                breakpointSettings.slidesToShow = slidesToShow;

                if (isDragged) {
                    breakpointSettings.ltr = true; // Enable right-to-left for autoplay
                }

                // Check if the slider has been initialized before setting options
                if ($slider.hasClass('slick-initialized')) {
                    $slider.slick('slickSetOption', breakpointSettings, true);
                } else {
                    // Initialize Slick Slider
                    $slider.slick({
                        // your slider options
                    });
                }
            }

            $slider.on("init", function (event, slick) {
                var $btnWrapper = $(element).find(".slider-btn-wrapper");
                if (slick.slideCount < 4) {
                    $btnWrapper.css("display", "none");
                } else {
                    if ((slick.currentSlide) == (slick.slideCount - 4)) {
                        $btnWrapper.css("display", "none");
                    } else {
                        var calc = (((slick.currentSlide + 1) / (slick.slideCount)) * 100) + 10;
                        updateProgressBar(calc, $progressBar);
                        disableButtons(slick.currentSlide + 1, slick.slideCount, $prevButton, $nextButton);
                        $btnWrapper.css("display", "flex");
                    }
                }
            });

            // Initialize Slick Slider
            $slider.slick({
                onReInit: function () { }
            });

            // Initial settings update
            updateSliderSettings();

            $slider.on("beforeChange", function (event, slick, currentSlide, nextSlide) {
                var calc = (((nextSlide + 1) / (slick.slideCount)) * 100) + 10;
                if (slick.options.slidesToShow === 4) {
                    if ((nextSlide) == (slick.slideCount - 4)) {
                        const calcProgressBar = 100;
                        updateProgressBar(calcProgressBar, $progressBar);
                        disableButtons(nextSlide + 1, slick.slideCount, $prevButton, $nextButton, calcProgressBar);
                    } else {
                        updateProgressBar(calc, $progressBar);
                        disableButtons(nextSlide + 1, slick.slideCount, $prevButton, $nextButton);
                    }

                    
                }
                if (slick.options.slidesToShow === 3 || slick.options.slidesToShow === 2.5) {
                    if ((nextSlide) == (slick.slideCount - 3) || (nextSlide) > (slick.slideCount - 3)) {
                        const calcProgressBar = 100;
                        updateProgressBar(calcProgressBar, $progressBar);
                        disableButtons(nextSlide + 1, slick.slideCount, $prevButton, $nextButton, calcProgressBar);
                    } else if ((nextSlide) < (slick.slideCount - 3)) {
                        updateProgressBar(calc, $progressBar);
                        disableButtons(nextSlide + 1, slick.slideCount, $prevButton, $nextButton);
                    }
                }
            });

            $slider.on("swipe", function () {
                isDragged = true;

                // Check if the slider has the class ".slider-projects"
                if ($slider.closest('.slider-projects').length > 0) {
                    // Enable autoplay after the slider is dragged
                    updateSliderSettings();

                    // Toggle classes only for the current slick list
                    $currentSlickList.addClass("show-before").removeClass("show-after");
                }
            });

            $slider.on("afterChange", function () {
                // isDragged = false;
            });

            function disableButtons(currentSlide, slideCount, $prevButton, $nextButton, calcProgressBar) {
                var $currentSlickList = $slider.find(".slick-list");
                if (currentSlide === 1 && slideCount === 4) {
                    $prevButton.addClass("slider-button-disabled");
                    $nextButton.addClass("slider-button-disabled");
                    $currentSlickList.removeClass("show-before show-after");
                } else if (calcProgressBar === 100) {
                    $prevButton.removeClass("slider-button-disabled");
                    $nextButton.addClass("slider-button-disabled");
                    $currentSlickList.addClass("show-before").removeClass("show-after");
                } else if (currentSlide === 1) {
                    $prevButton.addClass("slider-button-disabled");
                    $nextButton.removeClass("slider-button-disabled");
                    $currentSlickList.removeClass("show-before").addClass("show-after");
                } else {
                    $prevButton.removeClass("slider-button-disabled");
                    $nextButton.removeClass("slider-button-disabled");
                    $currentSlickList.addClass("show-before").addClass("show-after");
                }
            }

            $prevButton.on("click", function () {
                $slider.slick("slickSetOption", "autoplay", false, true);
                $slider.slick("slickPrev");
            });

            $nextButton.on("click", function () {
                $slider.slick("slickSetOption", "autoplay", false, true);
                $slider.slick("slickNext");
            });

            // Update settings on window resize
            $(window).on('resize', function () {
                updateSliderSettings();
            });

            //$(window).on('resize', function () {
            //    updateSliderSettings();
            //});



        });
    }

    function updateProgressBar(calc, $progressBar) {
        $progressBar.css("background-size", calc + "% 100%").attr("aria-valuenow", calc);
    }

});

$(document).on("click", ".our-services-cmp .arrow-content-container a", function (e) {
    var hrefValue = $(this).attr('href');

    // Check if the href attribute is set to '#'
    if (hrefValue === '#') {
        e.preventDefault(); // Prevent the default action
    }
});

document.addEventListener("DOMContentLoaded", function () {
    var productCompElements = document.querySelectorAll(".new-product-comp");
    productCompElements.forEach(function (productComp) {
        var sourceTags = productComp.querySelectorAll("source");
        sourceTags.forEach(function (sourceTag) {
            sourceTag.parentNode.removeChild(sourceTag);
        });
    });
});
