const brands = [
    {
        name: 'blucher',
        color: '#132261'
    },
    {
        name: 'aerco',
        color: '#62AD3D'
    },
    {
        name: 'mueller',
        color: '#005DAA'
    },
    {
        name: 'dormont',
        color: '#005493'
    },
    {
        name: 'pvi',
        color: '#18375C'
    },
    {
        name: 'sunTouch',
        color: '#F78D1E'
    },
    {
        name: 'watts',
        color: '#005db9'
    }
];

const mapColor = function(brandName) {
    let newColor;
    $.each(brands, function(index, value) {
        if (value.name === brandName) {
            newColor = value.color;
        }
    });
    return newColor;
};

export {mapColor};