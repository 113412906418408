import {intersection} from 'lodash-es';

const constrainOptions = function($container, $select) {
    const options = {
        'brand': [],
        'category': [],
        'country': []
    };

    $container.find('option:selected').each(function(index, element) {
        if ($(element).val() !== '') {
            $.each(options, function(key) {
                if ($(element).data(key)) {
                    const dataArray = $(element).data(key).split(',');
                    if (options[key].length > 0) {
                        options[key] = intersection(options[key], dataArray);
                    } else {
                        options[key] = dataArray;
                    }
                }
            });
        }
    });
    $.each(options, function(key, value) {
        const selector = `.js-how-to-buy__${key} option`;

        $(selector).each(function(index, element) {
            if (value.length && $(element).val()) {
                $(element).addClass('is-hidden').attr('disabled', true);
                if (value.includes($(element).val())) {
                    $(element).removeClass('is-hidden').attr('disabled', false);
                }
            } else {
                $(element).removeClass('is-hidden').attr('disabled', false);
            }
        });
    });
};

export {constrainOptions};