$(document).ready(function () {
    var originalContents = {};

    $('#dynamicModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var modal = $(this);
        var dynamicContent = modal.find('#dynamicContent');

        var contentSelector = button.data('content');
        var contentElement = $(contentSelector);

        if (!originalContents[contentSelector]) {
            // If content is not already loaded, store it
            originalContents[contentSelector] = contentElement.html();
        }

        // Set the modal content
        dynamicContent.html(originalContents[contentSelector]);
    });
});

// Watch Video cmp function
$(".heat-pumps__video-block").on("click", function () {
    $("#heat-pump-videoModaol").modal("show");
});