import 'slick-carousel';

$('.hero-carousel').each(function(index, e) {
    const $element = $(e);
    const previousText = $element.data('previous-text');
    const nextText = $element.data('next-text');
    const autoplaySpeed = $element.data('autoplay-speed');
    const max = $element.find('.hero-carousel__slide').length;
    const min = 0;
    const initialSlide = Math.floor(Math.random() * (max - min)) + min;

    $element.slick({
        autoplay: true,
        autoplaySpeed: autoplaySpeed || 7000,
        dots: true,
        fade: true,
        initialSlide: initialSlide,
        prevArrow: `<button class="slick-prev" aria-label="${previousText}" type="button"></button>`,
        nextArrow: `<button class="slick-next" aria-label="${nextText}" type="button"></button>`,
        row: 0,
        customPaging: function() {
            return $('<button type="button" />').text("");
        }
    });
});

$('.home-carousel__nav span').on('click', function(e) {
    $('.home-carousel').slick($(e.target).attr('class'));
});

$(".hero-carousel").append("<div class='buttons new-buttons'><button class='cprevious btn-left'><img src='/Areas/Watts/Content/images/angle-left.svg' alt='Previous' border='0' /></button><button class='cpause btn-pause'><img src='/Areas/Watts/Content/images/circle-pause.svg' alt='Pause' border='0' /></button><button class='cplay btn-play'><img src='/Areas/Watts/Content/images/circle-play.svg' alt='Play' border='0' /></button><button class='cnext btn-right'><img src='/Areas/Watts/Content/images/angle-right.svg' alt='Next' border='0' /></button></div>");

$('.cpause').on('click', function () {
    $('.hero-carousel').slick('slickPause');
});
$('.cplay').on('click', function () {
    $('.hero-carousel').slick('slickPlay');
});
$('.cprevious').on('click', function () {
    $('.hero-carousel .slick-prev').click();
});
$('.cnext').on('click', function () {
    $('.hero-carousel .slick-next').click();
});
if ($(".hero-carousel .slick-track").children(".hero-carousel .slick-slide").length <= 1) {
    $(".hero-carousel .buttons.new-buttons").css('display', 'none');
}