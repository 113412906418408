function getUrlParameter(sParam) {
    const sURLVariables = window.location.search.substring(1).split('&');
    let sParameterName;

    for (let i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (decodeURIComponent(sParameterName[0]) === sParam) {
            let paramOne = sParameterName[1];

            let isfilterfix = "no";
            isfilterfix = document.getElementsByTagName("body")[0].getAttribute("data-filterfix");

            if (isfilterfix === "yes" && (paramOne.toLowerCase().indexOf("name") >= 0 || paramOne.toLowerCase().indexOf("values") >= 0)) {
                if (paramOne.toLowerCase().indexOf("name%3a") >= 0) {
                    paramOne = paramOne.replace(new RegExp('Name%3A', 'gi'), "%22Name%22%3A");
                }
                else if (paramOne.toLowerCase().indexOf("name:") >= 0) {
                    paramOne = paramOne.replace(new RegExp('Name:', 'gi'), "%22Name%22:");
                }

                if (paramOne.toLowerCase().indexOf("values%3a") >= 0) {
                    paramOne = paramOne.replace(new RegExp('Values%3A', 'gi'), "%22Values%22%3A");
                }
                else if (paramOne.toLowerCase().indexOf("values:") >= 0) {
                    paramOne = paramOne.replace(new RegExp('Values:', 'gi'), "%22Values%22:");
                }
            }

            const parameterValue = decodeURIComponent(paramOne);
            return parameterValue === 'undefined' ? false : parameterValue;
        }
    }
}

export { getUrlParameter };