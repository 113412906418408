import {validateSuggest} from './_how-to-buy-validate-suggest.js';
import {sanitize} from './_sanitize.js';

$.each($('.js-how-to-buy-cta'), function(index, buyContainer) {

    const $targetForm = $(buyContainer).find(".js-how-to-buy-cta__form");
    const formAction = $targetForm.attr("action");

    validateSuggest($targetForm);

    $(buyContainer).find('input:radio').each(function(index, radio) {
        if ($(radio).attr('value') === 'Wholesaler/Distributor') {
            $(radio).attr('checked', 'checked');
        }
    });
    
    $targetForm.on("submit", function(e) {
        e.preventDefault();
        const subServ = encodeURIComponent($("input[name=ServiceType]:checked").val());
        let subZip = $(".how-to-buy__location-input").val();
        if (subZip) {
            subZip = encodeURIComponent(sanitize(subZip));
        }
        const newUrl = `${formAction}?ServiceType=${subServ}&Zip=${subZip}`;
        location.href = newUrl;
    });
});
