$(document).ready(function () {
    $(".grid-cta.add-hover-effect").on("click", function (event) {
        event.preventDefault();
        let anchorLink = $(this).find('a');
        let anchorHrefVal = anchorLink.attr('href');
        let anchorTargetVal = anchorLink.attr('target');
        
        if (anchorHrefVal) {
            if (anchorTargetVal === '_blank') {
                var newTab = window.open(anchorHrefVal, '_blank');

                if (!newTab || newTab.closed || typeof newTab.closed == 'undefined') {
                    window.location.href = anchorHrefVal;
                }
            } else {
                window.location.href = anchorHrefVal;
            }
        }
    });

});