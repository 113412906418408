$(window).on('load', function () {
    
    $(".hiu-calc-input").each(function () {
        // Check if the input has a value
        if ($(this).val().trim() !== "") {
            // Add class active to the previous label of the input
            $(this).prev(".hiu-calc-label").addClass("active");
        }
    });

});


// Feedback form input fields focus
$(document).on('focus', ".hiu-calc-input", function () {
    if ($(".hiu-calc-input").length) {
        $(".hiu-calc-input").each(function () {
            if ($(this).val().trim() !== "") {
                $(this).prev(".hiu-calc-label").addClass("active");
            }
        });
    }
});

// Feedback form input fields blur
$(document).on('blur', ".hiu-calc-input", function () {
    if ($(".hiu-calc-input").length) {
        if (!$(this).val()) {
            $(this).prev(".hiu-calc-label").removeClass("active");
        }
    }
});

$(document).on('click', ".hiu-calc-input", function () {
    if ($(".hiu-calc-input").length) {
        $(this).attr('autocomplete', 'off');
        $(this).prev(".hiu-calc-label").addClass("active");
    }
});

$(document).on('input', '.hiu-calc-input', function () {
    if ($(".hiu-calc-input").length) {
        if ($(this).val()) {
            $(this).prev(".hiu-calc-label").addClass("active");
        } else {
            $(this).prev(".hiu-calc-label").removeClass("active");
        }
    }
});

const { eq } = require("lodash-es");

$(window).on('load', function () {
    clearStorage();
    $(hiuContainer).find('.heatingCircuits').hide();
  
});

var userSelections = new Object();
var formNumber = 0;
var numberOfCircuits = 0;
var hiuContainer = $('.js-hiu');
var list = $(hiuContainer).find('[data-form]');



var firstformid = $(list).first().attr('data-form')
$(list).first().attr('data-active', "1")

displayCurrentform();

restartButtondisplay();
 if (formNumber <= 2) {
                    $(hiuContainer).find('.hiu_selection').hide();
                }
                else {
                    $(hiuContainer).find('.hiu_selection').show();
                }




$('.next').click(function (event) {
    event.preventDefault();
    var currentform = $(hiuContainer).find('[data-active="1"]');
    var currentformid = $(currentform).attr('data-form');


    //if (formNumber == 0) {
    //    var firstpagevalidation = validateFirstPage();
    //    if (firstpagevalidation) {
    //               return;
    //    }
       
      
    //}

    var isItHiuConfigurationform = $(currentform).find('.hiunameconfiugration');
 
    if ($(isItHiuConfigurationform).length>0)
    {
     
        var confiugrationpagevalidation = validateConfiguraionPage()
        if (confiugrationpagevalidation) { return;}
    }
   

    if (currentformid != firstformid) {

        if ($(currentform).find(':input').is(':checked')) {
            $(currentform).find('.next').attr('disabled', 'false');
        }
       

    }

    formNumber = formNumber + 1;
    restartButtondisplay();
    
    var projectcontinue = $(currentform).find('[data-continue]').attr('data-continue');
    var hiuobject = sessionStorage.getItem("hiuobject");
    const parsedhiuobject = JSON.parse(hiuobject);
    if (projectcontinue) {

        var exceptfirsdiv = $(list).not(':eq(0)');
        $(exceptfirsdiv).each(function ()
        {
            $(this).find('input[type=text],input[type=number]').val('');
            $(this).find(':input').prop('checked', false)
        });



        if (!$.isEmptyObject(hiuobject)) {

            var hiudata = sessionStorage.getItem("hiuobjects")
            var hiuobjects = JSON.parse(hiudata);

            if ($.isEmptyObject(hiuobjects))
            {
                var projects = [parsedhiuobject];
                console.log(hiuobject.number);
             
               // console.log(projectnumber.toString())
                sessionStorage.setItem("hiuobjects", JSON.stringify(projects));

                setInitialValues(parsedhiuobject, projects);
                hiuobject = sessionStorage.getItem("hiuobject");

            }
            else
            {
                hiuobjects.push(parsedhiuobject)
                sessionStorage.setItem("hiuobjects", JSON.stringify(hiuobjects));
              
                setInitialValues(parsedhiuobject, hiuobjects);
            }

            $(hiuContainer).find('.hiu_selection').find('div:gt(0)').remove();
            formNumber = 1;
            numberOfCircuits = 0;
            userSelections = new Object();
        }
    }


    if ($.isEmptyObject(hiuobject))
    {
        var name = $('#hiu__firstandlastname').val();
        var namelabel = $('#hiu__firstandlastname_label').text()
        var company = $('#hiu__company').val();
        var companylable = $('#hiu__company_label').text();
        var areaofactivity = $('#hiu__areaofactivity :selected').text();
        var areaofactivitylabel = $('#hiu__areaofactivity_label').text();
        var email = $('#hiu__email').val();
        var emaillable = $('#hiu__email_label').text();
        var phone = $('#hiu__phone').val();
        var phonelabel = $('#hiu__phone_label').text();
        var informationabout = $('#hiu__informationabout').val();
        var projectlabel = $('#hiu__project_label').text();
        var heading = $('h2').text();
        var subheading = $('h3').first().text();
        var currenturl = window.location.href;
        var emailText = $('#hiu_email_text').val();
        var emailSubject = $('#hiu_email_subject').val();
        var configurationlabel = $('#hiu__configurationtext').val();
        var pdfselectedlabel = $('#hiu_pdfselected_text').val();
        var pdfnumberofconfiugrationslabel = $('#hif_pdfnumberofconfigurations_text').val();
        const hiuobject = {
            number: "1",
            heading: heading,
            subheading: subheading,
            name: name,
            namelabel: namelabel,
            company: company,
            companylabel: companylable,
            areaofactivity: areaofactivity,
            areaofactivitylabel: areaofactivitylabel,
            email: email,
            emaillabel: emaillable,
            phone: phone,
            phonelabel: phonelabel,
            project: informationabout,
            projectlabel: projectlabel,
            url: currenturl,
            emailInfo: emailText,
            emailSubject: emailSubject,
            configurationlabel: configurationlabel,
            pdfselectedlabel: pdfselectedlabel,
            pdfnumberofconfiugrationslabel: pdfnumberofconfiugrationslabel

        }
        
        sessionStorage.setItem("hiuobject", JSON.stringify(hiuobject));
       

        var finishproject = $(hiuContainer).find('.hiu-form-finishproject');//.first();

        $(finishproject).each(function () {

            var eachtext = $(this).text();
            var finishprojecttext = eachtext.replace("{0}", email);
            $(finishproject).text(finishprojecttext);

        })
        //$(finishproject).each(eachProject => {

        //    var eachtext = $(eachProject).text();
        //    var  finishprojecttext = $(eachtext).replace("{0}", email);
        //    $(finishproject).text(finishprojecttext);
        //})
       

    }

    $(currentform).attr('data-active', "0");

    var inputname = $(currentform).attr('data-name');
    var nextformid;

    var configurationLabel = $(currentform).find('#hiu__configurationtext').val();
    if (configurationLabel) { parsedhiuobject["configurationlabel"] = configurationLabel;}
    if ($(currentform).find('input[name="' + inputname + '"]').length > 0)
    {
        var selectedInput = $(currentform).find('input[name="' + inputname + '"]:checked');


        nextformid = $(selectedInput).attr('data-next');
        var selectedText = $(selectedInput).attr('data-selection');
       // var additonalSelctionText = $(selectedInput).attr('data-additonalselection');
        var selectedModel = $(selectedInput).attr('data-modelno');
        var selectedValue = $(currentform).find('input[name="' + inputname + '"]:checked').val();

        var selectedatanumber = $(selectedInput).attr('data-number');

        var selectedOptionNumber = $(selectedInput).attr('data-optionnumber');
        if (selectedValue)
        {
           
            if (selectedOptionNumber != "2")
            {

                var selectedimage;
                if (selectedatanumber)
                {

                    if (selectedatanumber == "1") {
                        selectedimage = $(currentform).find('.hiu-calcu-radio-wrap-1').find('img').clone();

                        var heatingcircuit1 = $(currentform).find('.selectDivHC1');
                        if (heatingcircuit1.length>0)
                        {
                           var selectedModelAndText = $(heatingcircuit1).find('select').find(":selected").val();
                            selectedModel = selectedModelAndText.split('|')[0];
                            selectedText = selectedModelAndText.split('|')[1];
                            numberOfCircuits = $(heatingcircuit1).find('select').find(":selected").text();
                           
                        }
                       

                    }
                    else if (selectedatanumber == "2") {
                        selectedimage = $(currentform).find('.hiu-calcu-radio-wrap-2').find('img').clone();
                        var heatingcircuit2 = $(currentform).find('.selectDivHC2');
                        if (heatingcircuit2.length>0) {
                          var  selectedModelAndText = $(heatingcircuit2).find('select').find(":selected").val();
                            selectedModel = selectedModelAndText.split('|')[0];
                            selectedText = selectedModelAndText.split('|')[1];
                            numberOfCircuits = $(heatingcircuit2).find('select').find(":selected").text();
                        }

                    }

                }
                console.log(numberOfCircuits);

                if (numberOfCircuits > 6) {

                    var modelForAbove6Ciruits = $(selectedInput).attr('data-modelno-6');
                   var selectedTextforAbove6Circuits = $(selectedInput).attr('data-selection-6')
                    if (modelForAbove6Ciruits)
                    {
                        selectedModel = modelForAbove6Ciruits;
                    }

                    if (selectedTextforAbove6Circuits) {
                        selectedText = selectedTextforAbove6Circuits;
                    }
                }

                var allselections;
                var diplayselections;
                if (selectedModel) {
                    allselections = selectedModel;
                    diplayselections = selectedModel;
                }
                if ((selectedText) && (allselections))
                {
                    allselections = allselections + '|' + selectedText;
                    diplayselections = "<span class='selected-num'>" + diplayselections + "</span><span class='selected-text'>" + selectedText + "</span>";
                }
                else
                {
                    allselections = selectedText;
                }


                if (inputname == "HKM Actuator") {
                    allselections = allselections + '|' + numberOfCircuits;
                }

                parsedhiuobject[inputname] = allselections;


                if (!selectedimage) {
                    selectedimage = $(currentform).find('.hiu-form-image-wrap').find('img').clone();

                }

                if (formNumber > 2) {

                    var divforuserselection;
                    if (selectedimage === null) {
                   
                        divforuserselection = '<div id="hiuSelections">' + '<span class="selectedtextwrap">' + diplayselections + '</span>' + '</div >';

                    }
                    else
                    {
                        var html = $(selectedimage).addClass('hiu_selected_image').prop('outerHTML');
                      
                        divforuserselection = '<div id="hiuSelections">' + html + '' + '<span class="selectedtextwrap">' + diplayselections + '</span>' + '</div >';

                    }

                    if (!(userSelections.hasOwnProperty(formNumber))) {
                        userSelections[formNumber] = divforuserselection;
                        $(hiuContainer).find('.hiu_selection').append(divforuserselection);
                    }

                }
                if (formNumber <= 2) {
                    $(hiuContainer).find('.hiu_selection').hide();
                }
                else {
                    $(hiuContainer).find('.hiu_selection').show();
                }
                sessionStorage.setItem("hiuobject", JSON.stringify(parsedhiuobject));
            }            
        }
       
       

        console.log($(currentform).find('input[name="' + inputname + '"]:checked').val());
        console.log(parsedhiuobject);
      
    }
    else if (($(currentform).find('input[type=text]').length > 0) && (firstformid != currentformid))
    {

      
        $(currentform).find('input[type=text],input[type=number]').each(function (input) {


            parsedhiuobject[$(this).attr('id')] = $(this).val();
           
            console.log($(currentform).find('input[name="' + inputname + '"]:checked').val());
            console.log(parsedhiuobject);
        });

        sessionStorage.setItem("hiuobject", JSON.stringify(parsedhiuobject));
    }



    if (!nextformid) nextformid = $(currentform).find('[data-next]').attr('data-next');

    var nextformdata = 'data-form="' + nextformid + '"';
    var nextform = $(hiuContainer).find('[' + nextformdata + ']');
    $(nextform).attr('data-active', "1");
    var isitFinishProjectform = $(nextform).find('.hiu-form-finishproject');
    if (isitFinishProjectform.length > 0)
    {
        $(nextform).find('.hiu-calc-subheading-text').hide();
        $(nextform).find('.description').hide();
        $(isitFinishProjectform).show();
        var mainHeading = $(nextform).find('.hiu-calc-heading-text');
        var headingText = $(mainHeading).text()
        $(nextform).find('.hiu-form-finishheading').text(headingText);
        $(mainHeading).hide();
    }
    displayCurrentform();
    $('.hiu-configurationcxplanation-text').hide();

    if ($(hiuContainer).find('[data-active="1"]').find('input').is(':checked')) {
        $(hiuContainer).find('[data-active="1"]').find('.next').removeAttr('disabled');
    }
})

$("[type=radio]").click(function () {

    var currentform = $(hiuContainer).find('[data-active="1"]');

    var heatingCircurits = $(currentform).find('.heatingCircuits');

    $('div.heatingCircuits').each(function () {
        if ($(this).css('display') != 'none') {
            $(this).css("display", "none");
        }
    });

    if ($(heatingCircurits).length > 0) {
        var strid = "div.selectDivHC" + $(this).attr("data-number");
        $(strid).css("display", "block");
    }


    $(currentform).find('.next').removeAttr('disabled')
  

});





$('.previous').click(function (event) {
    event.preventDefault();
  
    delete userSelections[formNumber];
    formNumber = formNumber - 1;

    $(hiuContainer).find('.hiu_selection').find('div:gt(0)').remove();

    if (formNumber > 2) {

        Object.keys(userSelections).forEach(function (key) {
            $(hiuContainer).find('.hiu_selection').append('<div id="hiuSelections">' + userSelections[key] + '</div > ');
        });
       
    }

    if (formNumber > 2) {
        $(hiuContainer).find('.hiu_selection').show();
    
    }
    else {
          $(hiuContainer).find('.hiu_selection').hide();
    }



    $('.hiu-configurationcxplanation-text').hide();
    var currentform = $(hiuContainer).find('[data-active="1"]');
    $(currentform).attr('data-active', "0");

    var previousformid = $(currentform).find('[data-previous]').attr('data-previous');

    var previousformdata = 'data-form="' + previousformid + '"';

    var previousform = $(hiuContainer).find('[' + previousformdata + ']');
    $(previousform).attr('data-active', "1");

    $(hiuContainer).find('[data-active="0"]').hide();
    $(hiuContainer).find('[data-active="1"]').show();

    if ($(hiuContainer).find('[data-active="1"]').find('input').is(':checked')) {
        $(hiuContainer).find('[data-active="1"]').find('.next').removeAttr('disabled');
    }
    
});


$('.finishproject').click(function (event) {
    const $dataPath = $('.js-hiu').data('endpoint');
    const hiuobjects = sessionStorage.getItem("hiuobjects");
    const parsedhiuobjects = JSON.parse(hiuobjects);
    const hiuobject = sessionStorage.getItem("hiuobject");
    const parsedhiuobject = JSON.parse(hiuobject);
    var stringfyobject;
    if (parsedhiuobjects) {

        parsedhiuobjects.push(parsedhiuobject)
        sessionStorage.removeItem("hiuobject");
        stringfyobject = JSON.stringify(parsedhiuobjects)
    }
    else {


        var data = [parsedhiuobject];
        //var projects = [parsedhiuobject];
        stringfyobject = JSON.stringify(data);
    }

    // var stringfyobject = JSON.stringify(parsedhiuobjects)
    console.log(stringfyobject);
    $.ajax({
        type: "POST",
        url: $dataPath,
        data: stringfyobject,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        beforeSend: function ()
                    {
                       $(".loader-wrapper").show();
                    },
        cache: false
    }).done(function (data) {
        $(".loader-wrapper").hide();
        //alert(data);
        console.log(data);

        var currentform = $(hiuContainer).find('[data-active="1"]');
        $(currentform).find('.hiu-calc-heading-text').hide();
        var subheading = $(currentform).find('.hiu-calc-subheading-text');
        $(subheading).css('font-weight', 'Bold');
        $(subheading).show();
        $(currentform).find('.description').show();
        $(currentform).find('.previous').hide();
        $(currentform).find('.next').hide();
        $(currentform).find('.finishproject').hide();
        $(currentform).find('.hiu-form-finishheading').hide();
    });

});



function setInitialValues(parsedhiuobject, projects) {
    var projectnumber = parseInt(parsedhiuobject.number) + 1;
    sessionStorage.removeItem("hiuobject");

    const firstselection = {
        number: projectnumber.toString(),
        name: projects[0].name,
        company: projects[0].company,
        areaofactivity: projects[0].areaofactivity,
        email: projects[0].email,
        phone: projects[0].phone,
        project: projects[0].project
    };

    sessionStorage.setItem("hiuobject", JSON.stringify(firstselection));
}

function displayCurrentform() {
    $(hiuContainer).find('[data-active="0"]').hide();
    $(hiuContainer).find('[data-active="1"]').show();
}

function clearStorage() {
    const hiuobject = sessionStorage.getItem("hiuobject");
    const parsedhiuobject = JSON.parse(hiuobject);
    if (!$.isEmptyObject(parsedhiuobject)) {
        sessionStorage.removeItem("hiuobject");
    }

    const hiuobjects = sessionStorage.getItem("hiuobjects");
    const parsedhiuobjects = JSON.parse(hiuobjects);
    if (!$.isEmptyObject(parsedhiuobjects)) {
        sessionStorage.removeItem("hiuobjects");
    }
}

function restartButtondisplay() {


    if (formNumber == 0) {

        $(hiuContainer).find('.hiu-form-Restart-button').hide();
    }
    else {
        $(hiuContainer).find('.hiu-form-Restart-button').show();

    }
}


$('.restart').click(function (event) {
    $(hiuContainer).find('.hiu_restart_confirmation').show();
   
});

$('.confirm').click(function (event) {

    //$(hiuContainer).find('.hiu_restart_confirmation').hide();
    //clearStorage();
    //$(hiuContainer).find('[data-active="1"]').attr('data-active', "0");
    //$(hiuContainer).find('.formId0').attr('data-active', "1");
    //displayCurrentform();
    //formNumber = 0;
    //restartButtondisplay();
    //resetValidationErrors();
    location.reload();
  
});

$('.cancel').click(function (event) {
    $(hiuContainer).find('.hiu_restart_confirmation').hide();
});



function validateConfiguraionPage()
{
    var iserror = false;
    var currentform = $(hiuContainer).find('[data-active="1"]');
   
    $(currentform).find(':input[type=text],:input[type=number]').each(function () {
        var data = $(this).val();
        if (!data || data == "") {
            $(this).next().removeClass('is-hidden');
         
            iserror = true;
         
        }
        else {
           
            $(this).next().addClass('is-hidden');
        }
        // check for input type=number
        if ($(this).attr('type') === 'number' && data > 500) {
            $(this).next().removeClass('is-hidden');
            iserror = true;
        } else if ($(this).attr('type') === 'number') {
            $(this).next().addClass('is-hidden');
        }

    });


    return iserror;
  


}

function checkValidity() {
    const $activeForm = $(hiuContainer).find('[data-active="1"]');
    const $requiredFields = $activeForm.find('[required]');
    let allValid = true;

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    $requiredFields.each(function () {
        const $field = $(this);

        if ($field.is(':checkbox')) {
            if (!$field.is(':checked')) {
                allValid = false;
                return false;
            }
        } else if ($field.is('select')) {
            if (!$field.val()) {
                allValid = false;
                return false;
            }
        } else {
            if (!$field.val().trim()) {
                allValid = false;
                return false;
            }

            // check for email fields
            if ($field.attr('type') === 'email') {
               if (!emailRegex.test($field.val().trim())) {
                    $field.addClass('invalid-email');
                    allValid = false;
                    return false;
               } else {
                    $field.removeClass('invalid-email');
               }
            }
        }
    });

    return allValid;
}


function toggleNextButton(currentForm) {
    const $nextBtn = currentForm.find('.next');
    const allValid = checkValidity();
    $nextBtn.prop('disabled', !allValid);
}

$('[required]').on('input change click', function () {
    const currentForm = $(this).closest('div[data-active="1"]');
    toggleNextButton(currentForm);
    if ($(this).hasClass('hiu-reqhomestations')) {
        if ($(this).val() < 500) {
            $('.hiu-reqhomestations').next().addClass('is-hidden');
        } else {
            $('.hiu-reqhomestations').next().removeClass('is-hidden');
        }
    }
});

$('div[data-active="1"]').each(function () {
    toggleNextButton($(this));
});
$(document).ready(function () {
    if ($(".hiu-select-radio-wrap").length) {
        $(".hiu-select-radio-wrap input").each(function () {
            $(this).on('click', function () {
                if ($(this).is(':checked')) {
                    $(".hiu-select-radio-wrap").removeClass('selected')
                    $(this).closest(".hiu-select-radio-wrap").addClass('selected');
                } else {
                    $(this).closest(".hiu-select-radio-wrap").removeClass('selected');
                }
            });
        });
    }
});



