$(document).ready(function () {
    $(document).on('click', ".countries-tabs-item button", function () {
        if ($(".countries-tabs-item").length) {
            $(".countries-tabs-item").removeClass("active");
            $(this).parent().addClass("active");
            var region = $(this).parent().data("region");
            var titleElement = $("#" + region);
            $(".countries-list").animate({
                scrollTop: titleElement.offset().top - $(".countries-list").offset().top + $(".countries-list").scrollTop()
            }, 500);
        }
    });
    //$("#search").on("keypress", function () {
    //    var inputValue = $(this).val();
    //    $(this).attr("autocomplete", "off");
    //    if (inputValue.length !== 0) {
    //        $(".close-country-search").css("display", "block");
    //    } else {
    //        $(".close-country-search").css("display", "none");
    //    }
    //});

});


