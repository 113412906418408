import * as hb from "handlebars/dist/handlebars";

const createSeriesJumpLinks = function () {
    const $jumpLinks = $(".js-series-jump-links");
    $jumpLinks.filter()
    if ($jumpLinks.length <= 0) {
        return;
    }

    const jumpLinksData = [];
    $(".js-series-jump-links-target").each(function (index, target) {
        const classN = target.className;
        if (!classN.includes("is-hidden")) {
            const id = "jl_" + generateUUID();
            target.id = id;
            var dataVal = $(this).attr("data-val");
            jumpLinksData.push({
                title: target.innerText,
                href: id,
                isLiterature: dataVal == "Literature",
                isModelOptions: dataVal == "SelectAModel",
                isRepairKits: dataVal == "RepairKits",
            });
        }
    });

    $jumpLinks.each(function (index, jumpLink) {
        const templateSource = jumpLink.getElementsByClassName("js-series-jump-link-items-template")[0];
        if (typeof templateSource !== "undefined") {
            const template = hb.compile(templateSource.innerHTML);
            const markup = template(jumpLinksData);
            jumpLink.innerHTML = markup;
        }
    });

    // https://gist.github.com/jed/982883
    function generateUUID(a) {
        return a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, generateUUID);
    }
};

export { createSeriesJumpLinks };